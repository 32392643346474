(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('HfBillController', HfBillController);

    HfBillController.$inject = ['$rootScope', '$scope', '$state', '$uibModal', '$translate', 
    'HfBill', 'HfBillSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'OpsFiles',
    'DataUtils'];

    function HfBillController ($rootScope, $scope, $state, $uibModal, $translate, 
        HfBill, HfBillSearch, ParseLinks, AlertService, pagingParams, paginationConstants, OpsFiles,
        DataUtils) {
        var vm = this;
        
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.isAdvanceSearch = false;
        vm.advancedSearch = advancedSearch;
        vm.exportBalanceReport = exportBalanceReport;

        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                HfBillSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                HfBill.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.hfBills = data;
                vm.page = pagingParams.page;
                vm.isAdvanceSearch = false;
            }
            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            if (vm.isAdvanceSearch) {
                vm.itemsPerPage = paginationConstants.itemsPerPage;
                vm.isAdvanceSearch = false;
                loadAll();
            } else {
                vm.transition();
            }
        }
        $scope.rechargeCopy = function(hfBill) {
            var billCopy = angular.copy(hfBill);
            billCopy.id = null;
            billCopy.time = new Date();
            billCopy.total = null;
            billCopy.remarks = null;
            billCopy.price = null;
            billCopy.total = null;
            billCopy.balance = null;
            billCopy.amount = null;
            billCopy.checkDate = null;
            billCopy.rateId = null;
            billCopy.exchangeRate = null;
            billCopy.operator = null;
            billCopy.rateRemarks = null;
            $scope.recharge(billCopy);
        }
        $scope.recharge = function(hfBill) {
            //子系统充值入口不做权限
            if ($rootScope.account.companyId !== null) {
                if ($rootScope.HAVENOAUTH('AU_RECHARGE_MGMT')) {
                    $rootScope.FORBIDDEN_BY_ALERT('AU_RECHARGE_MGMT');
                    return;
                }
            }

            $uibModal.open({
                templateUrl: 'app/entities/hf-bill/hf-bill-dialog.html',
                controller: 'HfBillDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        if(hfBill){
                            if(hfBill.id){
                                return HfBill.get({id : hfBill.id}).$promise;
                            }
                            else {
                                return hfBill;
                            }
                        }
                        return {
                            companyId: $rootScope.account.companyId,
                            currency: $rootScope.account.baseCurrency,
                            type: "RECHARGE",
                            time: new Date()
                        }                        
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('hfBill');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                var newOne = true;
                angular.forEach(vm.hfBills, function(data){
                    if(data.id == result.id){
                        angular.copy(result, data);
                        newOne = false;
                    }
                })
                if(newOne){
                    vm.hfBills.splice(0, 0, result);
                }                
                layer.msg($translate.instant('global.save_success'));
                loadAll();
            }, function() {

            });
        }

        vm.totelAmt = 0;
        function updateTtlAmt () {
            vm.totelAmt = 0;
            angular.forEach(vm.hfBills, function(data){
                if(data.total){
                    vm.totelAmt += data.total;
                }
            })
            vm.totelAmt = DataUtils.round(vm.totelAmt, 2);

        }
        function advancedSearch () {
            var etdPeriodDTO = {};
            etdPeriodDTO.companyId = $rootScope.account.companyId;
            $uibModal.open({
                templateUrl: 'app/entities/hf-bill/hf-bill-search-dialog.html',
                controller: 'HfBillSearchDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return etdPeriodDTO;
                    }]
                }
            }).result.then(function(results) {
                vm.hfBills = results;
                if (!vm.hfBills || vm.hfBills.length == 0) {
                    layer.msg($translate.instant('global.messages.resultNull'));
                    return;
                }
                vm.currentSearch = true;
                vm.totalItems = vm.hfBills.length;
                vm.itemsPerPage = vm.hfBills.length;
                vm.queryCount = vm.totalItems;
                vm.page = 1;
                vm.isAdvanceSearch = true; 
                updateTtlAmt();
            }, function() {

            });
        }
        function exportBalanceReport () {
            OpsFiles.balanceReport(function(result) {
                $rootScope.DOWNLOADEXCEL(result, "balanceReport.xlsx");                
            })
        }
        $scope.exportRechargeReport = function(type) {
            if(!vm.hfBills || vm.hfBills.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            OpsFiles.exportRechargeReport({
                timezoneOffset: new Date().getTimezoneOffset()/60
            }, vm.hfBills, function(result) {
                $rootScope.DOWNLOADEXCEL(result, "rechargeList.xlsx");
            })
        }
    }
})();
