(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CommodityCountryDialogController', CommodityCountryDialogController);

    CommodityCountryDialogController.$inject = ['$timeout', '$rootScope', '$scope', '$translate', '$uibModalInstance',
    'entity', 'OpsCompany', 'Dictionary', 'DataUtils'];

    function CommodityCountryDialogController ($timeout, $rootScope, $scope, $translate, $uibModalInstance,
        entity, OpsCompany, Dictionary, DataUtils) {
        var vm = this;

        vm.isOpen = entity;

        vm.clear = clear;
        vm.save = save;
        vm.delOne = delOne;
        vm.addOne = addOne;
        vm.isOpenChange = isOpenChange;
        vm.setFdArray = setFdArray;

        vm.fmsFdArray = [];
        vm.airFdArray = [];
        vm.fbaFdArray = [];
        vm.fbArray = null;
        vm.fbType = 'fmsFdArray';

        vm.setRqArray = setRqArray;
        vm.fmsRqArray = [];
        vm.airRqArray = [];
        vm.fbaRqArray = [];
        vm.rqArray = null;

        vm.rqType = 'fmsRqArray';
        function setFdArray(fbArray) {
            vm.fbArray = vm[fbArray];
            vm.fbType = fbArray;
        }
        function setRqArray(rqArray) {
            vm.rqArray = vm[rqArray];
            vm.rqType = rqArray;
        }

        vm.refreshType = 'fmsMaster';
        vm.autoRefreshArray = null;
        vm.setRefArray = setRefArray;
        function setRefArray(masterType) {
            vm.autoRefreshArray = vm[masterType];
            vm.refreshType = masterType;
        }


        function isOpenChange(fd) {
            vm.isOpen = {};
            vm.isOpen[fd] = true;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
            OpsCompany.getOpsCompanyJson({id : $rootScope.account.companyId}, function(result){
                vm.opsCompanyJson = result;
                initResp();
            })
            if(vm.isOpen.refreshAuto && entity.activeBtn){
                setRefArray(entity.activeBtn);
            }
        });

        vm.productNames = [];
        vm.whNames = [];
        vm.branchs = [];
        vm.departments = [];
        vm.fmsFds = Dictionary.fmsFds;
        vm.fmsRqs = Dictionary.fmsRqs;
        vm.fbaFds = Dictionary.fbaFds;
        vm.fbaRqs = Dictionary.fbaRqs;
        vm.airFds = Dictionary.airFds;
        vm.airRqs = Dictionary.airRqs;
        vm.refs = Dictionary.refs;
        vm.refsPc = Dictionary.refsPc;
        function initRequest () {
            if(vm.opsCompanyJson.configurationObj){
                var initFailed = false;
                angular.forEach(vm.opsCompanyJson.configurationObj.BIZTYPES, function(data){
                    if(!initFailed && !data.value){
                        vm.isOpen = {};
                        vm.isOpen.BIZTYPES = true;
                        initFailed = true;
                    }
                })
                angular.forEach(vm.opsCompanyJson.configurationObj.INTERNALTYPES, function(data){
                    if(!initFailed && !data.value){
                        vm.isOpen = {};
                        vm.isOpen.INTERNALTYPES = true;
                        initFailed = true;
                    }
                })
                angular.forEach(vm.opsCompanyJson.configurationObj.CARGOTYPES, function(data){
                    if(!initFailed && !data.value){
                        vm.isOpen = {};
                        vm.isOpen.CARGOTYPES = true;
                        initFailed = true;
                    }
                })
                angular.forEach(vm.opsCompanyJson.configurationObj.OATYPES, function(data){
                    if(!initFailed && !data.value){
                        vm.isOpen = {};
                        vm.isOpen.OATYPES = true;
                        initFailed = true;
                    }
                })
                angular.forEach(vm.opsCompanyJson.configurationObj.CLIENTTYPES, function(data){
                    if(!initFailed && !data.value){
                        vm.isOpen = {};
                        vm.isOpen.CLIENTTYPES = true;
                        initFailed = true;
                    }
                })
                angular.forEach(vm.opsCompanyJson.configurationObj.CLIENTSOURCES, function(data){
                    if(!initFailed && !data.value){
                        vm.isOpen = {};
                        vm.isOpen.CLIENTSOURCES = true;
                        initFailed = true;
                    }
                })
                angular.forEach(vm.opsCompanyJson.configurationObj.CUSTOMSTYPES, function(data){
                    if(!initFailed && !data.value){
                        vm.isOpen = {};
                        vm.isOpen.CUSTOMSTYPES = true;
                        initFailed = true;
                    }
                })
                angular.forEach(vm.opsCompanyJson.configurationObj.CUSFIELDS, function(data){
                    if(!initFailed && !data.value){
                        vm.isOpen = {};
                        vm.isOpen.CUSFIELDS = true;
                        initFailed = true;
                    }
                })


                if(initFailed){
                    $rootScope.OPS_ALERT($translate.instant('global.blueNullAlert'));
                    return false;
                }
                vm.opsCompanyJson.configurationObj.PRODUCTNAMES = [];
                angular.forEach(vm.productNames, function(data){
                    if(data.name){
                        vm.opsCompanyJson.configurationObj.PRODUCTNAMES.push(data.name);
                    }
                })
                vm.opsCompanyJson.configurationObj.WHNAMES = [];
                angular.forEach(vm.whNames, function(data){
                    if(data.name){
                        vm.opsCompanyJson.configurationObj.WHNAMES.push(data.name);
                    }
                })
                vm.opsCompanyJson.configurationObj.BRANCHS = [];
                angular.forEach(vm.branchs, function(data){
                    if(data.name){
                        vm.opsCompanyJson.configurationObj.BRANCHS.push(data.name);
                    }
                })
                vm.opsCompanyJson.configurationObj.DEPARTMENTS = [];
                angular.forEach(vm.departments, function(data){
                    if(data.name){
                        vm.opsCompanyJson.configurationObj.DEPARTMENTS.push(data.name);
                    }
                })
                vm.opsCompanyJson.configurationObj.FMSHIDEN = [];
                angular.forEach(vm.fmsFdArray, function(data){
                    if(data.hide){
                        vm.opsCompanyJson.configurationObj.FMSHIDEN.push(data.name);
                    }
                })
                vm.opsCompanyJson.configurationObj.AIRHIDEN = [];
                angular.forEach(vm.airFdArray, function(data){
                    if(data.hide){
                        vm.opsCompanyJson.configurationObj.AIRHIDEN.push(data.name);
                    }
                })
                vm.opsCompanyJson.configurationObj.FBAHIDEN = [];
                angular.forEach(vm.fbaFdArray, function(data){
                    if(data.hide){
                        vm.opsCompanyJson.configurationObj.FBAHIDEN.push(data.name);
                    }
                })

                vm.opsCompanyJson.configurationObj.FMSRQ = [];
                angular.forEach(vm.fmsRqArray, function(data){
                    if(data.hide){
                        vm.opsCompanyJson.configurationObj.FMSRQ.push(data.name);
                    }
                })

                vm.opsCompanyJson.configurationObj.AUTOREFRESH = [];
                angular.forEach(vm.fmsMaster, function(data){
                    if(data.hide){
                        vm.opsCompanyJson.configurationObj.AUTOREFRESH.push(data.name);
                    }
                })

                vm.opsCompanyJson.configurationObj.AUTOREFRESHPC = [];
                angular.forEach(vm.parcelMaster, function(data){
                    if(data.hide){
                        vm.opsCompanyJson.configurationObj.AUTOREFRESHPC.push(data.name);
                    }
                })


                vm.opsCompanyJson.configurationObj.AIRRQ = [];
                angular.forEach(vm.airRqArray, function(data){
                    if(data.hide){
                        vm.opsCompanyJson.configurationObj.AIRRQ.push(data.name);
                    }
                })
                vm.opsCompanyJson.configurationObj.FBARQ = [];
                angular.forEach(vm.fbaRqArray, function(data){
                    if(data.hide){
                        vm.opsCompanyJson.configurationObj.FBARQ.push(data.name);
                    }
                })
                vm.opsCompanyJson.configurationJson = angular.toJson(vm.opsCompanyJson.configurationObj, true);
            }
            return true;
        }

        function initResp () {
            if(vm.opsCompanyJson.configurationJson){
                vm.opsCompanyJson.configurationObj = angular.fromJson(vm.opsCompanyJson.configurationJson);
                if(!vm.opsCompanyJson.configurationObj.CLIENTTYPES){
                    vm.opsCompanyJson.configurationObj.CLIENTTYPES = [];
                }
                if(!vm.opsCompanyJson.configurationObj.HANDLETYPETITLES){
                    vm.opsCompanyJson.configurationObj.HANDLETYPETITLES = [];
                }
                vm.productNames = [];
                angular.forEach(vm.opsCompanyJson.configurationObj.PRODUCTNAMES, function(data){
                    if(data){
                        vm.productNames.push({name: data});
                    }
                })
                vm.whNames = [];
                angular.forEach(vm.opsCompanyJson.configurationObj.WHNAMES, function(data){
                    if(data){
                        vm.whNames.push({name: data});
                    }
                })
                vm.branchs = [];
                angular.forEach(vm.opsCompanyJson.configurationObj.BRANCHS, function(data){
                    if(data){
                        vm.branchs.push({name: data});
                    }
                })
                vm.departments = [];
                angular.forEach(vm.opsCompanyJson.configurationObj.DEPARTMENTS, function(data){
                    if(data){
                        vm.departments.push({name: data});
                    }
                })

                initArray();
                setFdArray(vm.fbType);
                setRqArray(vm.rqType);
                setRefArray(vm.refreshType);
            }
        }

        function initArray() {
            vm.fmsFdArray = [];
            if(!vm.opsCompanyJson.configurationObj.FMSHIDEN){
                vm.opsCompanyJson.configurationObj.FMSHIDEN = [];
            }
            if(!vm.opsCompanyJson.configurationObj.CUSFIELDS){
                vm.opsCompanyJson.configurationObj.CUSFIELDS = [];
            }
            angular.forEach(vm.fmsFds, function(fd){
                vm.fmsFdArray.push({
                    name: fd,
                    hide: vm.opsCompanyJson.configurationObj.FMSHIDEN.indexOf(fd) != -1
                });
            })
            angular.forEach(vm.fmsFdArray, function(data){
                DataUtils.getLangNameFms(data);
            })

            vm.fbaFdArray = [];
            if(!vm.opsCompanyJson.configurationObj.FBAHIDEN){
                vm.opsCompanyJson.configurationObj.FBAHIDEN = [];
            }
            angular.forEach(vm.fbaFds, function(fd){
                vm.fbaFdArray.push({
                    name: fd,
                    hide: vm.opsCompanyJson.configurationObj.FBAHIDEN.indexOf(fd) != -1
                });
            })
            angular.forEach(vm.fbaFdArray, function(data){
                DataUtils.getLangNameFba(data);
            })

            vm.airFdArray = [];
            if(!vm.opsCompanyJson.configurationObj.AIRHIDEN){
                vm.opsCompanyJson.configurationObj.AIRHIDEN = [];
            }
            angular.forEach(vm.airFds, function(fd){
                vm.airFdArray.push({
                    name: fd,
                    hide: vm.opsCompanyJson.configurationObj.AIRHIDEN.indexOf(fd) != -1
                });
            })
            angular.forEach(vm.airFdArray, function(data){
                DataUtils.getLangNameAir(data);
            })
            vm.fmsRqArray = [];
            if(!vm.opsCompanyJson.configurationObj.FMSRQ){
                vm.opsCompanyJson.configurationObj.FMSRQ = [];
            }
            angular.forEach(vm.fmsRqs, function(fd){
                vm.fmsRqArray.push({
                    name: fd,
                    hide: vm.opsCompanyJson.configurationObj.FMSRQ.indexOf(fd) != -1
                });
            })
            angular.forEach(vm.fmsRqArray, function(data){
                DataUtils.getLangNameFms(data);
            })

            vm.fmsMaster = [];
            if(!vm.opsCompanyJson.configurationObj.AUTOREFRESH){
                vm.opsCompanyJson.configurationObj.AUTOREFRESH = [];
            }
            angular.forEach(vm.refs, function(fd){
                vm.fmsMaster.push({
                    name: fd,
                    hide: vm.opsCompanyJson.configurationObj.AUTOREFRESH.indexOf(fd) != -1
                });
            })
            angular.forEach(vm.fmsMaster, function(data){
                DataUtils.getLangNameRef(data);
            })

            vm.parcelMaster = [];
            if(!vm.opsCompanyJson.configurationObj.AUTOREFRESHPC){
                vm.opsCompanyJson.configurationObj.AUTOREFRESHPC = [];
            }
            angular.forEach(vm.refsPc, function(fd){
                vm.parcelMaster.push({
                    name: fd,
                    hide: vm.opsCompanyJson.configurationObj.AUTOREFRESHPC.indexOf(fd) != -1
                });
            })
            angular.forEach(vm.parcelMaster, function(data){
                DataUtils.getLangNameRef(data);
            })


            vm.fbaRqArray = [];
            if(!vm.opsCompanyJson.configurationObj.FBARQ){
                vm.opsCompanyJson.configurationObj.FBARQ = [];
            }
            angular.forEach(vm.fbaRqs, function(fd){
                vm.fbaRqArray.push({
                    name: fd,
                    hide: vm.opsCompanyJson.configurationObj.FBARQ.indexOf(fd) != -1
                });
            })
            angular.forEach(vm.fbaRqArray, function(data){
                DataUtils.getLangNameFba(data);
            })

            vm.airRqArray = [];
            if(!vm.opsCompanyJson.configurationObj.AIRRQ){
                vm.opsCompanyJson.configurationObj.AIRRQ = [];
            }
            angular.forEach(vm.airRqs, function(fd){
                vm.airRqArray.push({
                    name: fd,
                    hide: vm.opsCompanyJson.configurationObj.AIRRQ.indexOf(fd) != -1
                });
            })
            angular.forEach(vm.airRqArray, function(data){
                DataUtils.getLangNameAir(data);
            })
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            if(initRequest()){
                vm.isSaving = true;
                OpsCompany.saveOpsCompanyJson(vm.opsCompanyJson, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.opsCompanyJson = result;
            initResp();
            $rootScope.LOAD_CONFIGURATION();
            layer.msg($translate.instant('global.save_success'));
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
            layer.msg($translate.instant('global.save_fail'));
        }
        function delOne (arr, idx) {
            arr.splice(idx, 1);
        }

        function addOne (arr, value) {
            arr.splice(0, 0, value);
        }
    }
})();
