(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsCargoPickingController', WmsCargoPickingController);

    WmsCargoPickingController.$inject = ['$scope', '$rootScope', '$timeout', '$uibModal', '$translate', '$uibModalInstance',
        'ExpressParcel', 'Principal', 'WmsCargo', 'entity', 'DateUtils', 'WmsGoods'];

    function WmsCargoPickingController($scope, $rootScope, $timeout, $uibModal, $translate, $uibModalInstance,
        ExpressParcel, Principal, WmsCargo, entity, DateUtils, WmsGoods) {
        var vm = this;
        vm.pickingJobNum = entity.pickingNum;        
        vm.wmsInList = entity.wmsInList;
        vm.getPickingList = getPickingList;
        vm.updateTtl = updateTtl;
        vm.confirmOut = confirmOut;
        vm.printPickingTicket = printPickingTicket;
        vm.scanCountChange = scanCountChange;
        vm.clear = clear;
        vm.clearDatum = clearDatum;
        vm.removeWrongSku = removeWrongSku;
        vm.skuCount = skuCount;
        vm.organizePL = organizePL;

        vm.isSaving = false;
        vm.scanCount = true;
        vm.scanedCode = null;
        vm.removeFromPicking = removeFromPicking;
        vm.dismissPickingTicket = dismissPickingTicket;
        vm.printLabels = printLabels;
        vm.generateLabels = generateLabels;
        vm.printPickingTicketAndLabel = printPickingTicketAndLabel;
        vm.zipOssFiles = zipOssFiles;
        vm.allOutSelect = false;
        vm.allOutSelectChange = allOutSelectChange;
        vm.byBarcode = false;
        vm.byPoNum = false;
        vm.ignorePicking = false;
        vm.files = [];

        var entityCopy;
        $timeout(function() {
            $("#field_pickingNum").focus();
            if(entity.pickingNum){
                entityCopy = angular.copy(entity);
            }
            initCargoList();
        });

        function allOutSelectChange (allOutSelect) {
            angular.forEach(getHandleObjects(), function(wmsIn){
                angular.forEach(wmsIn.cargoList, function(data){
                    if(!data.allOut){
                        if(allOutSelect){
                            data.count = data.pkgs;
                        }
                        else {
                            data.count = 0;
                        }
                    }
                })
            })
            updateTtl();
        }

        function removeFromPicking(wmsIn) {
            ExpressParcel.removeFromPicking({
                expressParcelId: wmsIn.id,
                pickingNum: vm.pickingJobNum
            }, function(data){
                layer.msg($translate.instant('global.delete_success'));
                var index = vm.wmsInList.indexOf(wmsIn);
                if(index > -1){
                    vm.wmsInList.splice(index, 1);
                }
                index = vm.matchedList.indexOf(wmsIn);
                if(index > -1){
                    vm.matchedList.splice(index, 1);
                }
                updateTtl();
            }, function(data){
                layer.msg($translate.instant('global.delete_fail'));
            })            
        }
        function dismissPickingTicket() {
            if(!vm.pickingJobNum){
                return;
            }
            layer.confirm($translate.instant('autopsApp.expressParcel.dismissPickingTicketAlert'), {
                icon: 3,
                title: vm.pickingJobNum,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);                 
                var loadIndex = layer.load(1, {shade: 0.3});     
                ExpressParcel.dismissPickingTicket({
                    pickingNum: vm.pickingJobNum,
                    companyId: $rootScope.account.companyId
                }, function(data){
                    layer.close(loadIndex);  
                    layer.msg($translate.instant('global.delete_success'));
                    getPickingList();
                }, function(data){
                    layer.msg($translate.instant('global.delete_fail'));
                })    
            }, function() {
                return;
            }); 
        }

        function removeWrongSku (index) {
            vm.wrongPicked.splice(index, 1);
        }

        function clearDatum () {
            entityCopy = null;
            vm.wmsInList = null;
            vm.pickingJobNum = null;
            vm.matchedList = [];
            vm.pkgsTtl = vm.countTtl = null;
            vm.allOutSelect = false;
            $("#field_pickingNum").focus();
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        }

        $scope.generateOneLabel = function(expressParcelId) {
            layer.msg("Generating label...", {
              shade: 0.3,
              time: 200000000
            });
            vm.isSaving = true;
            ExpressParcel.generateOneLabel({id: expressParcelId}, function(result) {
                layer.closeAll();
                vm.isSaving = false;
                $rootScope.PRINT_PDF(result.labelUrl, "label");
            }, function(error){
                layer.closeAll();
                vm.isSaving = false;
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                }  
            });
        }

        $scope.isDHL = function(obj) {
            return obj.returnChannel && obj.returnChannel.indexOf("DHL") != -1;
        }

        $scope.getCloseouts = function (wmsIn){
            var expressParcels = [];
            var expressParcel = {};
            expressParcel.id = wmsIn.id;
            expressParcel.carrier = wmsIn.returnChannel;
            expressParcels.push(expressParcel);      
            vm.isSaving = true;      
            ExpressParcel.getCloseouts(expressParcels, function(result){
                vm.isSaving = false;
                $rootScope.PRINTBYTES(result.data);
            }, function(error){
                vm.isSaving = false;
                layer.msg($translate.instant('global.noRecord'));
            })
        }

        function getPickingTicketData () {
            var areaSet = new Set();
            var wmsInList = [];

            var voidList = [];
            var pkgs = 0;
            angular.forEach(vm.wmsInList, function(wmsIn){
                if(!wmsIn.cargoList || wmsIn.cargoList.length == 0){
                    var cargo = {};
                    cargo.location = "Empty/Void";
                    cargo.pkgs = 0;
                    cargo.cargoName = "JOB#: " + wmsIn.warehousingNumber;
                    cargo.sku = "PO#: " + wmsIn.poNum;                    
                    voidList.push(cargo);
                    pkgs++;
                }
            })
            if(pkgs > 0){
                var voidOrders = {};
                voidOrders.count = 0;
                voidOrders.clientName = "Empty/Void Orders";  
                voidOrders.mark = entityCopy.pickingNum;
                voidOrders.cargoList = voidList;
                voidOrders.applicationNum = pkgs;
                wmsInList.push(voidOrders);
            }

            angular.forEach(vm.wmsInList, function(wmsIn){
                angular.forEach(wmsIn.cargoList, function(cargo){
                    if(!cargo.code){
                        cargo.code = "";
                    }
                    areaSet.add(cargo.code);
                })
            })
            areaSet.forEach(function(area){      
                var wmsIn = {};
                wmsIn.count = 0;
                wmsIn.clientName = area;  
                wmsIn.mark = entityCopy.pickingNum;
                var cargoList = [];
                pkgs = 0;
                angular.forEach(vm.wmsInList, function(wmsIn){
                    angular.forEach(wmsIn.cargoList, function(cargo){
                        if(cargo.code == area){
                            cargo.count = 0;
                            if(!cargo.location){
                                cargo.location = "";
                            }
                            cargoList.push(cargo);
                            if(cargo.pkgs){
                                pkgs += cargo.pkgs;
                            }
                        }
                    })
                })
                wmsIn.cargoList = cargoList;
                wmsIn.applicationNum = pkgs;
                wmsInList.push(wmsIn);
            });
            return wmsInList; 
        }

        function scanCountChange () {
            if(vm.scanCount){
                $timeout(function() {
                    $("#field_manualBarCode").focus();
                });
            }
        }

        function confirmOut () {
            if(!vm.allMatched){
                layer.msg("拣货未完成，可勾选全部出仓完成拣货！");
                return;
            }
            if(vm.wrongPicked.length > 0){
                layer.msg("请先处理错误拣货记录！");
                return;
            }
            vm.isSaving = true;
            var loadIndex = layer.load(1, {shade: 0.3});  
            ExpressParcel.confirmOut(getHandleObjects(), function() {
                layer.close(loadIndex); 
                vm.isSaving = false;
                getPickingList();
                layer.msg($translate.instant('global.save_success'));
            }, function (error) {
                vm.isSaving = false;           
                $rootScope.ALERT_ERROR(error);                
            })
        }

        function getPickingList () {
            if(!vm.pickingJobNum){
                return;
            }
            vm.isSaving = true;
            var loadIndex = layer.load(1, {shade: 0.3});    
            ExpressParcel.getPickingTicket({
                pickingNum: vm.pickingJobNum
            }, function(result) { 
                layer.close(loadIndex); 
                vm.isSaving = false;
                vm.wmsInList = result.wmsInList;
                entityCopy = angular.copy(result);
                initCargoList();
                updateTtl();
                $("#field_manualBarCode").focus();
            }, function(error) {
                vm.isSaving = false;
                layer.close(loadIndex); 
                vm.pickingJobNum = "";
                $("#field_pickingNum").focus();
            });
        }

        function initCargoList () {
            vm.matchedList = [];
            var cancelJobNums = "";
            angular.forEach(vm.wmsInList, function(wmsIn){
                angular.forEach(wmsIn.cargoList, function(cargo){
                    cargo.count = 0;
                    if(!cargo.pkgs){
                        cargo.pkgs = 0;
                    }                    
                })
                if(wmsIn.cancelShipment){
                    cancelJobNums = cancelJobNums + wmsIn.warehousingNumber + " ";
                }
            })
            vm.pickedAmt = 0;
            angular.forEach(vm.wmsInList, function(wmsIn){
                if(wmsIn.allOut){
                    vm.pickedAmt++;
                }
            })
            if(cancelJobNums.length > 3){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.expressParcel.canceledAlert', {jobNum: cancelJobNums}));
            }
        }

        vm.allMatched = false;
        vm.pickedAmt = 0;
        function updateTtl () {
            vm.allMatched = false;            
            var count = 0;
            vm.countTtl = 0;
            vm.pkgsTtl = 0;
            angular.forEach(getHandleObjects(), function(wmsIn){
                count = 0;
                angular.forEach(wmsIn.cargoList, function(cargo){
                    if(!cargo.allOut){
                        if(cargo.count){
                            count += cargo.count;
                            vm.countTtl += cargo.count;
                        }
                        if(cargo.pkgs){
                            vm.pkgsTtl += cargo.pkgs;
                        }
                    }
                    else{
                        vm.countTtl += cargo.pkgs;
                        vm.pkgsTtl += cargo.pkgs;
                    }

                })
                wmsIn.count = count;
            })
            if(vm.countTtl == vm.pkgsTtl){
                vm.allMatched = true;
            }
        }
        $scope.cameraScan = function () {
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-dialog.html',
                controller: 'WmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md'
            }).result.then(function(code) {      
                skuCount(code);
            }, function() {
            });            
        }

        function skuCount (sku) {
            if(!sku){
                return;
            }            
            if(vm.byPoNum){
                poMatch(sku);
            }
            else if(vm.byBarcode){
                WmsGoods.getSkuByCode({
                    companyId: $rootScope.account.companyId,
                    barcode: sku
                }, function(result){
                    if(result.sku){
                        skuMatch(result.sku);
                    }
                    else{
                        layer.msg($translate.instant('autopsApp.wmsIn.countExceedAlert', {sku: sku}));
                        vm.wrongPicked.push(sku);
                    }
                })
            }
            else {
                skuMatch(sku);
            }
        }
        function poMatch (sku) {       
            var matched = false;     
            if(vm.ignorePicking){
                angular.forEach(vm.matchedList, function(data){
                    if(data.poNum == sku || data.warehousingNumber == sku){
                        matched = true;
                    }
                })
                if(matched){
                    layer.msg($translate.instant('autopsApp.wmsIn.countExceedAlert', {sku: sku}));
                    vm.wrongPicked.push(sku);
                    return;
                }
                ExpressParcel.getPickingWmsIn({
                    companyId: $rootScope.account.companyId,
                    numStr: sku
                }, function(result, headers) { 
                    angular.forEach(result, function(data){
                        angular.forEach(data.cargoList, function(cargo){
                            cargo.count = cargo.pkgs;
                        })
                        vm.matchedList.splice(0, 0, data);                       
                        autoPrintLabel(data.id, null);
                    })
                    if(result.length == 0){
                        vm.wrongPicked.push(sku);
                    }
                    $rootScope.ALERT_HEADERS(headers);
                    updateTtl();
                    vm.scanedCode = "";    
                });
                return;
            }
            
            for(var i = 0; i < vm.wmsInList.length; i++){
                if(vm.matchedList.indexOf(vm.wmsInList[i]) == -1 
                    && (vm.wmsInList[i].poNum == sku || vm.wmsInList[i].warehousingNumber == sku)){
                    for(var j = 0; j < vm.wmsInList[i].cargoList.length; j++){
                        vm.wmsInList[i].cargoList[j].count = vm.wmsInList[i].cargoList.length == 1?1:0;
                        vm.matchedList.splice(0, 0, vm.wmsInList[i]);                       
                        matched = true;
                        autoPrintLabel(vm.wmsInList[i].id, null);
                        break;
                    }
                }
                if(matched){
                    break;
                }
            }
            if(!matched){
                layer.msg($translate.instant('autopsApp.wmsIn.countExceedAlert', {sku: sku}));
                vm.wrongPicked.push(sku);
            }
            updateTtl();
            vm.scanedCode = "";     
        }

        vm.wrongPicked = [];
        vm.matchedList = [];
        vm.ignoreCase = false;

        function codeMatch(code1, code2){
            if(vm.ignoreCase){
                return angular.uppercase(code1) == angular.uppercase(code2);
            }
            return code1 == code2;

        }

        function skuMatch (sku) {
            var matched = false;    
            var skuLabelCount = 0;    
            //匹配已经部分匹配的
            for(var i = 0; i < vm.matchedList.length; i++){
                skuLabelCount = 0;  
                for(var j = 0; j < vm.matchedList[i].cargoList.length; j++){
                    if(codeMatch(vm.matchedList[i].cargoList[j].sku, sku)
                        && !vm.matchedList[i].cargoList[j].allOut 
                        && vm.matchedList[i].cargoList[j].count < vm.matchedList[i].cargoList[j].pkgs){
                        autoPrintLabel(vm.matchedList[i].id, skuLabelCount + vm.matchedList[i].cargoList[j].count);   

                        vm.matchedList[i].cargoList[j].count++;
                        matched = true;
                        break;
                    }
                    else {
                        skuLabelCount += vm.matchedList[i].cargoList[j].pkgs;
                    }
                }
                if(matched){
                    break;
                }
            }    
            //优先匹配单个sku
            if(!matched){
                for(var i = 0; i < vm.wmsInList.length; i++){
                    if(vm.wmsInList[i].cargoList.length != 1){
                        continue;
                    }
                    skuLabelCount = 0;  
                    for(var j = 0; j < vm.wmsInList[i].cargoList.length; j++){
                        if(codeMatch(vm.wmsInList[i].cargoList[j].sku, sku)
                            && !vm.wmsInList[i].cargoList[j].allOut
                            && vm.wmsInList[i].cargoList[j].count < vm.wmsInList[i].cargoList[j].pkgs){
                            autoPrintLabel(vm.wmsInList[i].id, skuLabelCount + vm.wmsInList[i].cargoList[j].count); 

                            vm.wmsInList[i].cargoList[j].count++;
                            if(vm.matchedList.indexOf(vm.wmsInList[i]) == -1){
                                vm.matchedList.splice(0, 0, vm.wmsInList[i]);
                            }                        
                            matched = true;
                            break;
                        }
                        else {
                            skuLabelCount += vm.wmsInList[i].cargoList[j].pkgs;
                        }
                    }
                    if(matched){
                        break;
                    }
                }
            }
            if(!matched){
                for(var i = 0; i < vm.wmsInList.length; i++){
                    skuLabelCount = 0;  
                    for(var j = 0; j < vm.wmsInList[i].cargoList.length; j++){
                        if(codeMatch(vm.wmsInList[i].cargoList[j].sku, sku)
                            && !vm.wmsInList[i].cargoList[j].allOut
                            && vm.wmsInList[i].cargoList[j].count < vm.wmsInList[i].cargoList[j].pkgs){
                            autoPrintLabel(vm.wmsInList[i].id, skuLabelCount + vm.wmsInList[i].cargoList[j].count); 

                            vm.wmsInList[i].cargoList[j].count++;
                            if(vm.matchedList.indexOf(vm.wmsInList[i]) == -1){
                                vm.matchedList.splice(0, 0, vm.wmsInList[i]);
                            }                        
                            matched = true;
                            break;
                        }
                        else {
                            skuLabelCount += vm.wmsInList[i].cargoList[j].pkgs;
                        }
                    }
                    if(matched){
                        break;
                    }
                }
            }

            if(!matched){
                layer.msg($translate.instant('autopsApp.wmsIn.countExceedAlert', {sku: sku}));
                vm.wrongPicked.push(sku);
            }
            updateTtl();
            vm.scanedCode = "";     
        }

        $(document).keydown(function(event) {            
            if (event.keyCode == 13) {
                if(!vm.wmsInList || vm.wmsInList.length == 0){
                    if(vm.pickingJobNum && vm.pickingJobNum.length > 3){
                        getPickingList();
                    }
                    else {
                        $("#field_pickingNum").focus();
                    }                    
                    return;
                }
                if (vm.scanedCode) {
                    skuCount(vm.scanedCode);                    
                    $("#field_manualBarCode").focus();            
                }
                $timeout(function() {
                    $("#field_manualBarCode").focus();
                });
            } 
        });

        function printPickingTicket (type) {
            if(!vm.wmsInList || vm.wmsInList.length == 0){
                return;
            }
            var printEntity;
            if(type == "byArea"){
                printEntity = getPickingTicketData();
            }
            else {
                printEntity = angular.copy(vm.wmsInList);
            }
            vm.isSaving = true;
            ExpressParcel.printPickingTicket({
                companyId: $rootScope.account.companyId,
                timeStr: DateUtils.convertDateTimeToString(new Date),
                reportCode: getReportCode(type),
                pickingNum: entityCopy.pickingNum
            }, printEntity, function(result) {
                vm.isSaving = false;
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                vm.isSaving = false;
                layer.msg($translate.instant('global.noRecord'));
            });
        }

        function getReportCode (type) {
            if(type == 'byArea'){
                return 810;
            }
            if(type == 'byJob'){
                return 820;
            }
            if(type == 'byJobArea'){
                return 1170;
            }
        }

        function getHandleObjects () {
            var wmsInEntity;
            if(vm.scanCount){
                wmsInEntity = vm.matchedList;
            }
            else {
                wmsInEntity = vm.wmsInList;
            }
            return wmsInEntity;
        }

        function getExpressParcels () {
            var wmsInEntity = getHandleObjects();
            var expressParcels = [];
            angular.forEach(wmsInEntity, function(data){
                if(data.id){
                    expressParcels.push({id: data.id});
                }
            })
            return expressParcels;
        }

        function printLabels (sortType) {
            var parcels = getExpressParcels();
            if(parcels.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            layer.msg("Create and Combining label(s)...", {
              shade: 0.3,
              time: 200000000
            });
            $scope.isSaving = true;
            ExpressParcel.printLabels({
                sortType: sortType,
                pickingNum: entityCopy.pickingNum
            }, parcels, function(result) {
                layer.closeAll();
                $scope.isSaving = false;
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.closeAll();
                $scope.isSaving = false;
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }
        function printPickingTicketAndLabel () {
            var parcels = getHandleObjects();
            if(parcels.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            layer.msg("Create and Combining label(s)...", {
              shade: 0.3,
              time: 2000000000
            });
            $scope.isSaving = true;
            ExpressParcel.printPickingTicketAndLabel({
                companyId: $rootScope.account.companyId,
                timeStr: DateUtils.convertDateTimeToString(new Date),
                pickingNum: entityCopy.pickingNum
            }, parcels, function(result) {
                layer.closeAll();
                $scope.isSaving = false;
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.closeAll();
                $scope.isSaving = false;
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        function zipOssFiles () {
            var parcels = getHandleObjects();
            if(parcels.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            layer.load(1, {shade: 0.3});
            ExpressParcel.zipOssFiles({
                companyId: $rootScope.account.companyId
            }, parcels, function(result) {
                layer.closeAll();
                $rootScope.DOWNLOADEXCEL(result, entityCopy.pickingNum + ".zip");           
            }, function(error) {
                layer.closeAll();
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        function generateLabels () {
            var parcels = getExpressParcels();
            if(parcels.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            layer.msg("Generating label(s)...", {
              shade: 0.3,
              time: 2000000000
            });
            $scope.isSaving = true;
            ExpressParcel.generateLabels(parcels, function(result) {                
                layer.closeAll();
                $scope.isSaving = false;
                $rootScope.OPS_ALERT($translate.instant('autopsApp.expressParcel.generateLabelsAlert', {count: result.count, takeTime: result.takeTime}));
            }, function(error) {
                layer.closeAll();
                $scope.isSaving = false;
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }       

        function organizePL (wmsIn) {
            $uibModal.open({
                templateUrl: 'app/entities/express-cargo/express-cargo-organize-dialog.html',
                controller: 'ExpressCargoOrganizeDialogController',
                controllerAs: 'vm',
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return ExpressParcel.get({id : wmsIn.id}).$promise;;
                    }]
                }
            }).result.then(function(result) {
                wmsIn.remarks = result.labelUrl;
            }, function() {
            });            
        }     
        $scope.byBarcodeChange = function () {
            if(vm.byBarcode){
                vm.byPoNum = false;
            }
        }
        $scope.byPoNumChange = function () {
            if(vm.byPoNum){
                vm.byBarcode = false;
            }
        }
        $scope.autoPrint = false;
        $scope.setAutoPrint = function () {
            $scope.autoPrint = !$scope.autoPrint;
        }

        function autoPrintLabel (parcelId, pageIdx) {
            if(!$scope.autoPrint){
                return;
            }
            ExpressParcel.autoPrintLabel({
                id: parcelId,
                page: pageIdx
            }, function(result) {
                $rootScope.PRINT_BYTES_DIR(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

    }
})();
