(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpressTrackingDialogController', ExpressTrackingDialogController);

    ExpressTrackingDialogController.$inject = ['$scope', '$rootScope', '$translate', '$uibModal', '$http', '$timeout', '$uibModalInstance', 
    'OpsTrackStatus', 'DateUtils', 'entity', 'ExpressTracking', 'ExpressParcel', 'OpsFiles', 'SubTracking', 'DataUtils', 
    'uiGridConstants', 'GridState', 'AddressBook', 'OpsCost', 'Guide'];

    function ExpressTrackingDialogController ($scope, $rootScope, $translate, $uibModal, $http, $timeout, $uibModalInstance, 
        OpsTrackStatus, DateUtils, entity, ExpressTracking, ExpressParcel, OpsFiles, SubTracking, DataUtils,
        uiGridConstants, GridState, AddressBook, OpsCost, Guide) {
        var vm = this;

        vm.guide = entity.guide;
        vm.editModel = false;     
        vm.isExpress =  entity.isExpress;   
        vm.moveList = [];
        vm.tkResult = {};
        vm.deleteMove = deleteMove;
        vm.saveOne = saveOne;
        vm.getTime = getTime;
        vm.clear = clear;
        vm.getStatusList = getStatusList;
        vm.onstatusSelected = onstatusSelected;
        vm.uploadReceipt = uploadReceipt;
        vm.downLoadReceipt = downLoadReceipt;
        vm.thisMove = {};
        vm.recoveryData = recoveryData;
        vm.updateMove = updateMove;
        vm.updateService = updateService;
        vm.mailNotice = mailNotice;
        vm.changeSubsysHide = changeSubsysHide;
        vm.loadHGuides = loadHGuides;
        vm.getRelatedCount = getRelatedCount;
        vm.eventTimeChange = eventTimeChange;
        vm.isMasterView = false;
        vm.updateAll = true;
        vm.relatedCount;


        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 102
            }, function(result){
                gridStateObj = result;
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }   
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
            });
            getConsolShipmentDTOs();
            initThisMove();
        })

        var shipmentsCopy = null;
        function getConsolShipmentDTOs () {
            var requestEntity = {};
            if(vm.isExpress){
                requestEntity.expressParcelDTOs = entity.guides;
            }
            else {
                requestEntity.guideDTOs = entity.guides;
            }
            layer.load(1, {shade: 0.3}); 
            ExpressTracking.getConsolShipmentDTOs(requestEntity, function(result){  
                layer.closeAll();
                shipmentsCopy = result;
                $scope.gridOptions.data = angular.copy(shipmentsCopy);
                if(shipmentsCopy && shipmentsCopy.length > 0){
                    $timeout(function (){        
                        // changeGuide(shipmentsCopy[0]);  
                        $scope.gridApi.selection.selectAllRows();      
                    })                       
                }
            });
        }

        function recoveryData (){
            $scope.gridOptions.data = angular.copy(shipmentsCopy);
            $scope.gridApi.selection.clearSelectedRows();
            vm.isMasterView = false;
        }

        function initThisMove (){
            vm.thisMove = {};
            vm.thisMove.eventTime = new Date(); 
        }
        function uploadReceipt (file) {
            if (!file || !vm.guide.id) {
                return;
            }
            var fileName = "receipt";
            var fd = new FormData();            
            var filePath = "REPORTS/GUIDE/" + vm.guide.id + "/RC/";
            if(vm.isExpress){
                filePath = "/expressParcel/" + vm.guide.id + "/RC/";
            }
            $rootScope.UPLOAD_FILE_WITH_NAME(file, filePath, [], $rootScope.account.companyId, fileName);
        };

  
        function downLoadReceipt (){
            var filePath = "REPORTS/GUIDE/" + vm.guide.id + "/RC/";
            if(vm.isExpress){
                filePath = $rootScope.account.companyId + "/expressParcel/" + vm.guide.id + "/RC/";
            }
            OpsFiles.getFilesListWithFullPath({
                filepath: filePath
            },function (files){
                for(var i = 0 ; i < files.length; i++){
                    if (files[i]['filename'].indexOf("receipt") != -1) {
                        var aTag = document.createElement("a");
                        aTag.href = files[i]['fileurl'];
                        aTag.click();
                        break;
                    }
                }
            });
        }

        $scope.removeGuide = function (guide) {
            var index = $scope.gridOptions.data.indexOf(guide);
            if (index > -1) {
                $scope.gridOptions.data.splice(index, 1);
            };
        }


        function getStatusList () {
            if (!$rootScope.TRACKSTATUS || $rootScope.TRACKSTATUS.length == 0){
                OpsTrackStatus.getAll(function(result) {
                    $rootScope.TRACKSTATUS = result;
                });
            }
        }


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveError (result) {
            layer.msg($translate.instant('global.save_fail'));
        };
        function updateMove (move) {
            if(vm.thisMove.id == move.id){
                initThisMove();
            }
            else {
                vm.thisMove = move;
                vm.thisMove.eventTime = DateUtils.convertDateTimeFromServer(vm.thisMove.eventTime);
            }
            getRelatedCount(move);
        };
        function getRelatedCount (move) {
            vm.relatedCount = null;
            ExpressTracking.getRelatedCount({}, move, function(result){  
                vm.relatedCount = result.count;
                $translate.refresh();
            });
        }


        function saveOne (sendMail) {
            if (!vm.thisMove || !vm.thisMove.context){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var moveCopy = angular.copy(vm.thisMove);
            if(vm.isExpress){
                moveCopy.expressId = vm.guide.id;
            }
            else {
                moveCopy.guideId = vm.guide.id;
            }   
            moveCopy.time = DateUtils.convertDateTimeToString(vm.thisMove.eventTime);
            $scope.isSaving = true;
            var requestEntity = angular.copy(vm.tkResult);
            requestEntity.expressTracking = moveCopy;
            requestEntity.consolShipments = $scope.gridApi.selection.getSelectedRows();
            requestEntity.opsTrackStatus = moveCopy.opsTrackStatus;
            requestEntity.actTime = moveCopy.eventTime;
            requestEntity.updateAll = vm.updateAll;

            ExpressTracking.save(requestEntity, function(result) {
                $scope.isSaving = false;
                getHeadTracking();
                initThisMove();
                angular.forEach(requestEntity.consolShipments, function(data){
                    data.lastestTkStatus = result.context;
                })
                layer.msg($translate.instant('global.save_success'));
                if(sendMail){
                    mailNotice();
                }
            }, function(error) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.save_fail'));
            });
        };




        function deleteMove (move, delAll, delNode) {
            if (move.id != null) {
                ExpressTracking.delTracking({
                    id: move.id,
                    delAll: delAll,
                    delNode: delNode
                }, $scope.gridApi.selection.getSelectedRows(), function(result){
                    layer.msg($translate.instant('global.delete_success'));
                });
            };
            var index = vm.moveList.indexOf(move);
            if (index > -1) {
                vm.moveList.splice(index, 1);
            };
        }

        $scope.isSaving = false;

        function getTime (move) {
            if (!move.eventTime){
                move.eventTime = new Date();
            } 
        }

        function onstatusSelected (data, move) {
            if(!$scope.gridApi.selection.getSelectedCount()){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            move.context = data.status;
            if(data.delivered){
                vm.tkResult.state = "3";
            }    
            else {
                vm.tkResult.state = "0";
            }
            var requestEntity;
            if(vm.guide.jobNum){
                requestEntity = angular.copy(vm.guide);
            }
            else {
                requestEntity = angular.copy($scope.gridApi.selection.getSelectedRows()[0]);
            }
            requestEntity.memo = move.context;
            requestEntity.nodeTime = vm.thisMove.eventTime;
            move.context = "";
            move.opsTrackStatus = data;
            ExpressTracking.getTrackingContext(requestEntity, function(result){  
                if(data.binding && result.guide){
                    var fdName = DataUtils.getGuideNodeName(data.binding);
                    if(!result.guide[fdName]){
                        result.guide[fdName] = new Date();
                        var langName = $rootScope.RQ_CHECK(result.guide);
                        if(langName){
                            langName = result.guide.jobNum + " - " + langName;
                            $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.ndRequiredAlert', {param: langName}));
                            move = {};
                            $translate.refresh();
                            return;
                        }
                    }
                }
                move.context = result.memo;
                $translate.refresh();
            });
        }      

        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: false,      

            enableGridMenu : true,//表格 菜单
            showGridFooter: true,//表格的footer
            showColumnFooter: false,//每列footer

            enableFiltering: true,//高级过滤            

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            enableRowHeaderSelection : true,  //行选中
            enableRowSelection: true, //行选中
            enableSelectAll: true, //全部选中
            enableFullRowSelection : false, //行任意位置选中
            multiSelect: true,//多行选中

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: 'unchoosed.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'], 
            minimumColumnSize: 120,
            columnDefs: [
                { field: 'id', 
                    width: 50,
                    displayName: "", 
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    cellTemplate: '<button type="button"ng-click="grid.appScope.removeGuide(row.entity)"class="btn btn-danger btn-grid ml-5"><span class="glyphicon glyphicon-remove"></span></button>'},
                { field: 'jobNum', 
                    displayName: 'Job#',
                    width: 180,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<span ng-if="row.entity.isMaster"class="ml-5 text-danger">(M)</span><span ng-if="row.entity.isSub"class="ml-5 text-info">(H)</span><a href=""ng-click="grid.appScope.BIZPOP(row.entity.guideId)"class="ml-5"ng-if="row.entity.guideId">{{row.entity.jobNum}}</a><a href=""ng-click="grid.appScope.VIEWEXPRESS(row.entity.parcelId)"class="ml-5"ng-if="row.entity.parcelId">{{row.entity.jobNum}}</a><span class="glyphicon glyphicon-lock ml-5 text-danger"ng-if="row.entity.isClosed || row.entity.consolidated"data-toggle="tooltip"data-placement="top"title="{{\'autopsApp.guide.sts.isClosed\' | translate}}"></span>'},
                { field: 'soNum', 
                    displayName: 'SO#',
                    visible: false},
                { field: 'poNum', 
                    displayName: 'PO#',
                    visible: false},
                { field: 'mblNum',
                    visible: false,
                    cellTemplate: '<span class="ml-5">{{row.entity.mblNum}}<span class="glyphicon glyphicon-ok-circle ml-5 text-primary"data-toggle="tooltip"data-placement="top"title="MBL released: {{row.entity.mblReleasedTime | date:\'medium\'}}"ng-if="row.entity.mblReleasedTime"></span></span>',
                    displayName: 'MBL/Mawb#'},
                { field: 'hblNum',
                    visible: false,
                    cellTemplate: '<span class="ml-5">{{row.entity.hblNum}}<span class="glyphicon glyphicon-ok-circle ml-5 text-primary"data-toggle="tooltip"data-placement="top"title="HBL released: {{row.entity.hblReleasedTime | date:\'medium\'}}"ng-if="row.entity.hblReleasedTime"></span></span>',
                    displayName: 'HBL/Hawb#'},
                { field: 'lastestTkStatus',
                    width: 150,
                   displayName: $translate.instant('autopsApp.guide.lastestTkStatus')},
                { field: 'gw', 
                    width: 100,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'GW', 
                    cellTemplate: '<span class="ml-5">{{row.entity.gw}}<span class="ml-5" ng-if="row.entity.gwForecast">({{row.entity.gwForecast}})</span></span>',
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'vol', 
                    width: 100,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'VOL', 
                    cellTemplate: '<span class="ml-5">{{row.entity.vol}}<span class="ml-5" ng-if="row.entity.volForecast">({{row.entity.volForecast}})</span></span>',
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'pkgNum', 
                    width: 100,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'PKGS', 
                    cellTemplate: '<span class="ml-5">{{row.entity.pkgNum}}<span class="ml-5" ng-if="row.entity.pkgNumForecast">({{row.entity.pkgNumForecast}})</span></span>',
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'pallets', 
                    width: 100,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'Pallets', 
                    aggregationType: uiGridConstants.aggregationTypes.sum}, 
                { field: 'shipmentType', 
                    displayName: 'TYPE',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.FMS_PARCEL_TYPES
                    },  
                    cellTemplate: '<span ng-if="row.entity.shipmentType" class="label label-info ml-5" translate="global.{{row.entity.shipmentType}}"></span>'},
                { field: 'deliveryTo', 
                    displayName: $translate.instant('autopsApp.guide.deliveryTo')},
                { field: 'shiptoCode', 
                    displayName: $translate.instant('autopsApp.guide.shipToParty')},                    
                { field: 'destName', 
                    displayName: $translate.instant('autopsApp.guide.destName')},
                { field: 'shiptoCountryCode', 
                    displayName: $translate.instant('autopsApp.guide.destCountryCode')}, 
                { field: 'shiptoZipCode', 
                    displayName: $translate.instant('autopsApp.guide.destPostCode')}, 
                { field: 'polName', 
                    displayName: $translate.instant('autopsApp.guide.polName')}, 
                { field: 'cargoType', 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CARGOTYPES
                    },  
                    displayName: $translate.instant('autopsApp.guide.cargoType'),
                    cellTemplate: '<span class="label label-info ml-5">{{row.entity.cargoTypeLabel}}</span>'},
                { field: 'wmsInTime', 
                    displayName: $translate.instant('autopsApp.guide.wmsInTime'),
                    cellTemplate: '<span data-toggle="tooltip" data-placement="top" title="{{row.entity.wmsInTime | date:\'medium\'}}" class="ml-5">{{row.entity.wmsInTime | date:\'mediumDate\'}}</span>'},
                { field: 'etd', 
                    displayName: 'ETD'},
                { field: 'customerName', 
                    displayName: $translate.instant('autopsApp.guide.bookingPartyName')},
                { field: 'shipperName', 
                    displayName: $translate.instant('autopsApp.guide.shipperName')},
                { field: 'productName', 
                    displayName: $translate.instant('autopsApp.guide.productName')},
                { field: 'deliveryChannel', 
                    displayName: $translate.instant('autopsApp.guide.deliveryChannel')},
                { field: 'salesName', 
                    displayName: $translate.instant('autopsApp.guide.salesName')},
                { field: 'opName', 
                    displayName: $translate.instant('autopsApp.guide.opName')},
                { field: 'docName', 
                    displayName: $translate.instant('autopsApp.guide.docName')},
                { field: 'cusName', 
                    displayName: $translate.instant('autopsApp.guide.cusName')},
                { field: 'vsl', 
                    displayName: 'Vessel'},
                { field: 'voy', 
                    displayName: 'Voy/Flight'},
                { field: 'porName', 
                    displayName: $translate.instant('autopsApp.guide.porName')},
                { field: 'memo', 
                    displayName: $translate.instant('autopsApp.guide.table.memo')},
                { field: 'customsType', 
                    displayName: $translate.instant('autopsApp.guide.customsType')},
                { field: 'deliveryWay', 
                    displayName: $translate.instant('autopsApp.booking.deliveryWay'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.DELIVERYTYPES
                    },       
                    minWidth:100,
                    cellTemplate: '<span class="ml-5" translate="autopsApp.booking.deliveryType.{{row.entity.deliveryWay}}" ng-if="row.entity.deliveryWay" class="label-info label"></span>'}

            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 102;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }       
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);                   
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                gridApi.selection.on.rowSelectionChanged($scope, function(row){                          
                    if(row.isSelected && row.entity.isMaster && !vm.isMasterView){
                        loadHGuides(row.entity);
                    }
                    else{
                        rowSelectionChanged();
                    }
                    // if(row.isSelected){
                    //     changeGuide(row.entity);
                    //     // gridApi.selection.clearSelectedRows();   
                    //     // row.isSelected = true;
                    //     // alert($scope.gridApi.selection.getSelectedCount());
                    // }
                    // else if(!$scope.gridApi.selection.getSelectedCount() || $scope.gridApi.selection.getSelectedCount() > 1){      
                    //     vm.guide = {};                
                    //     vm.tkResult = {};
                    //     vm.moveList = [];
                    // }
                    
                    // $scope.selectedRows = $scope.gridApi.selection.getSelectedRows();
                });
                gridApi.selection.on.rowSelectionChangedBatch($scope,function(rows){
                    rowSelectionChanged();
                });               
            }
        }

        function selectedRowsCheck (move, delAll, delNode) {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if(selectedRows.length == 1 && selectedRows[0].isMaster){
                loadHGuides(selectedRows[0]);
                return;
            }
            var includeMaster = false;
            angular.forEach(selectedRows, function(row){
                if(row.isMaster){
                    $scope.gridApi.selection.unSelectRow(row);
                    includeMaster = true;
                }                
            });
            if(includeMaster && $scope.gridApi.selection.getSelectedCount() != 1){
                layer.msg($translate.instant('autopsApp.expressTracking.includeMasterAlert'));
            }
        }
        
 
        function rowSelectionChanged () {
            if(!vm.isMasterView){
                selectedRowsCheck();      
                if(!$scope.gridApi.selection.getSelectedCount() || $scope.gridApi.selection.getSelectedCount() > 1){      
                    vm.guide = {};                
                    vm.tkResult = {};
                    vm.moveList = [];
                    return;
                }
            }
            
            vm.guide = $scope.gridApi.selection.getSelectedRows()[0];
            if(vm.guide.agentGuideId){
                vm.guide.id = vm.guide.agentGuideId;
            }
            if(vm.guide.parcelId){
                vm.isExpress = true;
            }
            if(vm.guide.guideId){
                vm.isExpress = false;
            }
            getHeadTracking();
            initThisMove();
        }      

        $scope.isLoading= false;
        function getHeadTracking () {
            layer.load(1, {shade: 0.3});  
            ExpressTracking.getHeadTracking({
                guideId: vm.isExpress?null:vm.guide.id,
                expressId: vm.isExpress?vm.guide.id:null
            }, function (result) {
                layer.closeAll();  
                vm.tkResult = result;
                vm.moveList = result.trackings;
                angular.forEach(vm.moveList, function(data){
                    data.files = [];
                })
            }, function (result) {
                layer.closeAll();  
            })
        }
        function loadHGuides (masterRow) {
            if(masterRow.parcelId){
                vm.isExpress = true;
            }
            if(masterRow.guideId){
                vm.isExpress = false;
            }
            layer.load(1, {shade: 0.3});
            ExpressParcel.getSubShipments({
                companyId: masterRow.companyId,
                isFms: !vm.isExpress,
                masterId: masterRow.id
            }, function(result){
                layer.closeAll();           
                vm.isMasterView = true;
                result.splice(0, 0, masterRow);
                $scope.gridOptions.data = result;
                $timeout(function (){
                    $scope.gridApi.selection.clearSelectedRows();
                    $scope.gridApi.selection.setMultiSelect(true);
                    $scope.gridApi.selection.selectAllRows();
                    // $scope.gridOptions.isRowSelectable = function(row) {
                    //     if (row.entity.id == vm.guide.id) {
                    //         return false;
                    //     } else {
                    //         return true;
                    //     }
                    // };
                    $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.OPTIONS);                     
                })
            }, function (result) {
                layer.closeAll();  
            });
        }
        $scope.toggleMultiSelect = function() {
            $scope.gridApi.selection.setMultiSelect(!$scope.gridApi.grid.options.multiSelect);
            $scope.gridApi.selection.clearSelectedRows();
        };

        $scope.bindedAccounts = [];
        $scope.getAllAccounts = function() {
            if($rootScope.account.companyId && $rootScope.HAVENOAUTH('AU_LABEL_ACCOUNT')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_LABEL_ACCOUNT');
                return;            
            }
            if ($scope.bindedAccounts.length == 0 && !$scope.isSaving) {
                $scope.isSaving = true;
                AddressBook.getAllAccounts(function(result) {
                    $scope.bindedAccounts = result;
                    $scope.isSaving = false;
                });

            };
        };
        $scope._onAddressSelected = function(data) {
            vm.updateServiceEntity.shipFromId = data.id;
            vm.updateServiceEntity.addressBook = data;
        }

        $scope.shipServiceSelected = function(data) {
            vm.updateServiceEntity.serviceType = data.type;
        }
        $scope.clearService = function() {
            if(!vm.updateServiceEntity.serviceDescription){
                vm.updateServiceEntity.serviceType = null;
            }
        }

        $scope.opsCosts = [];
        $scope.opsSellings = [];
        $scope.findPrices = function(isSelling) {
            if(!isSelling && $scope.opsCosts.length > 0){return;}
            if(isSelling && $scope.opsSellings.length > 0){return;}
            OpsCost.findPrices({isSelling: isSelling}, function(result){
                if(isSelling){
                    $scope.opsSellings = result;
                }
                else {
                    $scope.opsCosts = result;
                }
            })
        }


        $scope._onSellingSelected = function(data) {
            vm.updateServiceEntity.opsSelling = data;
        }
        $scope._onCostSelected = function(data) {
            vm.updateServiceEntity.opsCost = data;
        }

        $scope.clearList = function() {
            $scope.gridOptions.data = [];
        }

        $scope.searchType = null;

        $scope.setSearchType = function(type) {
            $scope.searchType = type;
        }

        $scope.searchStr = "";
        $scope.searchGuide = function(loadFmsBiz) {            
            if(loadFmsBiz){
                if($rootScope.HAVENOAUTH('FMS') && $rootScope.HAVENOAUTH('FBA') && $rootScope.HAVENOAUTH('WMS')){
                    $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                    return;
                }
                searchGuides();                
            }
            else {
                if($rootScope.HAVENOAUTH('PARCEL') && $rootScope.HAVENOAUTH('TMS')){
                    $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                    return;
                }
                searchParcels();
            }
        };

        function searchGuides() {
            if($scope.searchType){
                Guide.searchByNums({
                    jobType: "batchUpdate",
                    type: $scope.searchType,
                    shipmentType: "all",
                    companyId: $rootScope.account.companyId,
                }, $scope.searchStr, function(result, headers) {
                    $rootScope.ALERT_RESULT_MSG(headers);
                    addAllToGuides(result);
                }, function(response) {
                });
                return;
            }
            if(!$scope.searchType && $scope.searchStr){
                $scope.searchStr = $scope.searchStr.replace(/\s+/g,"");
            }       
            if (!$scope.searchStr){
                return;
            }    
            Guide.searchGuide({
                isExport: true,
                searchType: $scope.searchType,
                jobType: "batchUpdate",
                virtualWms: false,
                companyId: $rootScope.account.companyId,
                inputStr: $scope.searchStr
            }, function(result) {
                addAllToGuides(result);
                $scope.searchStr = null;
            }, function(response) {
            });  
        }

        function searchParcels() {
            if($scope.searchType){
                ExpressParcel.searchByNums({
                    type: $scope.searchType,
                    shipmentType: "ALL",
                    searchType: "batchUpdate",
                    companyId: $rootScope.account.companyId
                }, $scope.searchStr, function(result, headers) {
                    $rootScope.ALERT_RESULT_MSG(headers);
                    addAllToGuides(result);
                }, function(response) {
                });
                return;
            }
            $scope.searchStr = $scope.searchStr.replace(/\s+/g,"");
            if (!$scope.searchStr){
                return;
            }
            ExpressParcel.searchByNum({
                type: "batchUpdate",
                searchType: $scope.searchType,
                companyId: $rootScope.account.companyId,
                inputStr: $scope.searchStr,
                isTms: $scope.isTms
            }, function(result) {
                addAllToGuides(result);
                $scope.searchStr = null;
            }, function(response) {
            });  
        }
        $scope.searchParcelsPop = function(shipmentType) {    
            if($rootScope.HAVENOAUTH('TMS') && shipmentType == "TMS"){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            if($rootScope.HAVENOAUTH('PARCEL') && shipmentType == "PARCEL"){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            var searchParams = {
                shipmentType: shipmentType,
                forBatchUpdate: true,
                companyId: $rootScope.account.companyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/express-parcel/express-parcel-detail.html',
                controller: 'ExpressParcelDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(result) {
                addAllToGuides(result);
            }, function() {
            });
        }

        $scope.searchGuidePop = function() {            
            if($rootScope.HAVENOAUTH('FMS') && $rootScope.HAVENOAUTH('FBA') && $rootScope.HAVENOAUTH('WMS')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }            
            var searchParams = {
                isExport: true,
                forBatchUpdate: true,
                companyId: $rootScope.account.companyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/guide/guide-search-dialog.html',
                controller: 'GuideSearchDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(result) {
                addAllToGuides(result);
            }, function() {
            });
        }
        function addAllToGuides(results){
            if (!results || results.length == 0){
                layer.msg($translate.instant('global.messages.resultNull'));
            }
            else {
                angular.forEach(results, function(data){
                    if(data.isClosed){
                        layer.msg($translate.instant('autopsApp.guide.sts.isClosed'));                           
                    }
                    else {
                        addToGuides(data);
                    }   
                })
            }
        }
        var addToGuides = function(guide) {
            var isRepeated = false;
            for(var j = $scope.gridOptions.data.length -1; j >=0; j--){
                if (guide.parcelId && guide.parcelId == $scope.gridOptions.data[j].parcelId){
                    isRepeated = true;
                    break;
                }
                if (guide.guideId && guide.guideId == $scope.gridOptions.data[j].guideId){
                    isRepeated = true;
                    break;
                }

            }
            if (!isRepeated){
                $scope.gridOptions.data.push(guide);
            }       
        }
        function mailNotice() {
            if(vm.thisMove.context){
                saveOne(true);     
                return; 
            }
            ExpressTracking.sendTrackingEmail($scope.gridApi.selection.getSelectedRows(), function(result){
                layer.msg($translate.instant('global.email_send_success'));                
            }, function(error){
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                } 
                else {
                    layer.msg($translate.instant('global.send_mail_fail'));    
                }              
            })
        }        
        function changeSubsysHide(expressTracking, subsysHide, changeAll) {
            if(!expressTracking.id){
                return;
            }
            ExpressTracking.changeSubsysHide({
                id: expressTracking.id,
                subsysHide: subsysHide,
                changeAll: changeAll
            }, function(result){
                expressTracking.subsysHide = result.subsysHide;
                $translate.refresh();
                layer.msg($translate.instant('global.save_success'));         
            }, $rootScope.ALERT_ERROR)

        }

        vm.updateServiceEntity = {};
        function updateService () {
            $scope.isSaving = true;  
            vm.updateServiceEntity.consolShipments = $scope.gridApi.selection.getSelectedRows();
            ExpressTracking.updateService(vm.updateServiceEntity, function(result) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.save_success'));
            }, function(error) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.save_fail'));
            });
        };
        function eventTimeChange() {
            if(!vm.thisMove.id){
                vm.thisMove.context = null;
            }            
        };
        



    }
})();
