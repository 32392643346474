(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('HfBillDialogController', HfBillDialogController);

    HfBillDialogController.$inject = ['$timeout', '$rootScope', '$scope', '$stateParams', '$uibModalInstance', '$location',
    'entity', 'HfBill', 'OpsExchangeRate','OpsFiles','$translate'];

    function HfBillDialogController ($timeout, $rootScope, $scope, $stateParams, $uibModalInstance, $location,
        entity, HfBill, OpsExchangeRate,OpsFiles,$translate) {
        var vm = this;

        vm.hfBill = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.payerSelected = payerSelected;
        vm.reloadHfBill =reloadHfBill;
        vm.audit =audit;

        vm.isVenderCharge = $location.path().indexOf("opsCompanys") == -1;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
            OpsExchangeRate.getAllCurrencys(function(result) {
                $rootScope.OPSCURRENCYS = result;
                if(!vm.hfBill.currency){
                    vm.hfBill.currency = $rootScope.account.baseCurrency;
                }
            })
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            if(!vm.hfBill.total && vm.hfBill.total != 0){return};
            vm.isSaving = true;
            vm.hfBill.operator = $rootScope.account.firstName + " "+ $rootScope.account.lastName;
            if(vm.hfBill.id){
                HfBill.update(vm.hfBill, onSaveSuccess, onSaveError);
            }
            else {
                HfBill.save(vm.hfBill, onSaveSuccess, onSaveError);
            }
        }

        function audit(auditResult){
            if(!vm.hfBill.total && vm.hfBill.total != 0){return};
            vm.isSaving = true;
            vm.hfBill.operator = vm.hfBill.operator +"/"+$rootScope.account.firstName + " "+ $rootScope.account.lastName;
            HfBill.audit({
                auditResult: auditResult
            }, vm.hfBill, onSaveSuccess, onSaveError);
        }


        function onSaveSuccess (result) {
            // $scope.$emit('autopsApp:hfBillUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.time = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function payerSelected (data) {
            vm.hfBill.venderId = data.id;
        }

        function reloadHfBill(id) {
            if(!id) return;
            $timeout(function() {
                HfBill.get({id: id}, function (result) {
                    vm.hfBill = result;
                }, $rootScope.ALERT_ERROR)
            }, 1000);
        }

        $scope.deleOssFile = function (hfBill) {
            if ($rootScope.account.companyId && $rootScope.HAVENOAUTH('AU_DEL_FILE')) {
                layer.msg($translate.instant('global.forbiddenDelFile'));
                return;
            }
            if (!$rootScope.account.companyId && $rootScope.HAVENOAUTH('AU_DEL_FILE')) {
                layer.msg($translate.instant('global.forbiddenDelFile'));
                return;
            }
            OpsFiles.delOssFile({
                filepath: hfBill.filepath
            }, function (result) {
                reloadHfBill(hfBill.id);
                layer.msg($translate.instant('global.delete_success'));
            }, function (reject) {
                layer.msg($translate.instant('global.delete_fail'));
            }, function (error) {
                console.log(error);
            });
        }


    }
})();
