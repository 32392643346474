(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('EdiInsuranceController', EdiInsuranceController);

    EdiInsuranceController.$inject = ['$scope', '$state', '$timeout', '$translate', '$rootScope',
    'EdiInsurance', 'EdiInsuranceSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'WinAES'];

    function EdiInsuranceController ($scope, $state, $timeout, $translate, $rootScope,
        EdiInsurance, EdiInsuranceSearch, ParseLinks, AlertService, pagingParams, paginationConstants, WinAES) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        if (pagingParams && pagingParams.searchType) {
            vm.searchType = pagingParams.searchType;
            if (vm.searchType == 'all') {
                vm.viewAll = true;
            }
        } else{
            vm.searchType = "self";
        }
        loadAll();

        $scope.viewAllChange = function() {
            if ($rootScope.HAVENOAUTH('AU_VIEW_ALL_INSUR')) {
                vm.viewAll = !vm.viewAll;
                $rootScope.FORBIDDEN_BY_ALERT('AU_VIEW_ALL_INSUR');
                return;
            }
            if(vm.viewAll){
                vm.searchType = "all";
            }
            else {
                vm.searchType = "self";
            }
            loadAll();
        }

        function loadAll () {
            if (vm.searchQuery) {
                EdiInsuranceSearch.query({
                    searchType: vm.searchType,
                    query: vm.searchQuery,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                EdiInsurance.query({
                    searchType: vm.searchType,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.ediInsurances = data;
                vm.page = pagingParams.page;
                angular.forEach(vm.ediInsurances, function(data){
                    data.encodeId = WinAES.encode(data.id.toString());
                    data.planInfoList = angular.fromJson(data.planInfoList);
                })
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                searchType: vm.searchType,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
        vm.linghangDataMap = {};
        $timeout(function (){
            EdiInsurance.getLinghangDataMap(function(result){
                result['comCode' + 'Obj'] = {};
                angular.forEach(result.comCode, function(data){
                    result['comCode' + 'Obj'][data.code] = data.name;
                })
                result['transportId' + 'Obj'] = {};
                angular.forEach(result.transportId, function(data){
                    result['transportId' + 'Obj'][data.code] = data.name;
                })
                result['applicantCertificateType' + 'Obj'] = {};
                angular.forEach(result.applicantCertificateType, function(data){
                    result['applicantCertificateType' + 'Obj'][data.code] = data.name;
                })
                result['goodsType' + 'Obj'] = {};
                angular.forEach(result.goodsType, function(data){
                    result['goodsType' + 'Obj'][data.code] = data.name;
                })
                result['pack' + 'Obj'] = {};
                angular.forEach(result.pack, function(data){
                    result['pack' + 'Obj'][data.smCode] = data.name;
                })
                result['jzx' + 'Obj'] = {};
                angular.forEach(result.jzx, function(data){
                    result['jzx' + 'Obj'][data.code] = data.name;
                })
                result['currency' + 'Obj'] = {};
                angular.forEach(result.currency, function(data){
                    result['currency' + 'Obj'][data.code] = data.enName;
                })
                vm.linghangDataMap = result;
                $translate.refresh();
            })
            $rootScope.RESIZE();
        });
        var onUpdateSuccess = function(result){
            angular.forEach(vm.ediInsurances, function(data){
                if(data.id == result.id){
                    angular.copy(result, data);
                    data.encodeId = WinAES.encode(data.id.toString());
                    data.planInfoList = angular.fromJson(data.planInfoList);
                }
            })
        }
        $scope.apiOrderinfo = function(ediInsurance) {
            EdiInsurance.apiOrderinfo(ediInsurance, function(result){
                onUpdateSuccess(result);
                layer.msg($translate.instant('global.update_success'));
            }, $rootScope.ALERT_ERROR)
        }
        $scope.apiChedan = function(ediInsurance) {
            EdiInsurance.apiChedan(ediInsurance, function(result){
                onUpdateSuccess(result);
                layer.msg($translate.instant('global.messages.submitted'));
            }, $rootScope.ALERT_ERROR)
        }

        $scope.audit = function (data) {
            EdiInsurance.audit({ediInsuranceId: data.id}, '', function () {
                layer.msg($translate.instant("autopsApp.ediInsurance.auditSuccess"));
                loadAll();
            }, $rootScope.ALERT_ERROR);
        }
    }
})();
