(function () {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WarehousingDataPdaController', WarehousingDataPdaController);

    WarehousingDataPdaController.$inject = ['$scope', '$rootScope', '$timeout', '$http', '$translate',
        'ManageEnterprise', 'WmsIn', 'DataUtils', 'OpsVender', 'OpsCarrier', 'WmsWarehouse', 'DateUtils',
        'Guide', 'AddressBook', 'ExpressParcel', 'WarehousingData', 'OpsFiles', 'OpsCost'
    ];

    function WarehousingDataPdaController($scope, $rootScope, $timeout, $http, $translate,
        ManageEnterprise, WmsIn, DataUtils, OpsVender, OpsCarrier, WmsWarehouse, DateUtils,
        Guide, AddressBook, ExpressParcel, WarehousingData, OpsFiles, OpsCost) {
        var vm = this;
        vm.bizType = "";
        vm.addCargo = addCargo;
        vm.delCargo = delCargo;
        vm.submitIn = submitIn;
        vm.updateTtl = updateTtl;
        vm.updateVol = updateVol;
        vm.focusCargo = focusCargo;
        vm.getWarehouses = getWarehouses;
        vm.warehouseSelected = warehouseSelected;
        vm._onLocationSelected = _onLocationSelected;
        vm.countrySelected = countrySelected;
        vm.getLocations = getLocations;
        vm.getShipment = getShipment;
        vm.clearGuide = clearGuide;
        vm.deleteByGuideId = deleteByGuideId;
        vm.autoRemoteCheck = autoRemoteCheck;
        vm.websockSubscribe = websockSubscribe;
        vm.loadGuide = loadGuide;
        vm.fucusInput = fucusInput;

        vm.locations = [];
        $scope.guide = {};
        vm.thisCargo = {};
        vm.isSaving = false;

        var initGuidePl = function () {
            if (!$scope.guide.warehousingDatas || $scope.guide.warehousingDatas.length == 0) {
                $scope.guide.warehousingDatas = [];
                vm.thisCargo = angular.copy(newCargo);
                // vm.thisCargo.pkgNum = $scope.guide.booking.pkgNum;
                // vm.thisCargo.gwTtl = $scope.guide.booking.gw;
                // vm.thisCargo.volTtl = $scope.guide.booking.vol;
                // if(vm.thisCargo.pkgNum){
                //     if(vm.thisCargo.gwTtl){
                //         vm.thisCargo.gw = DataUtils.round(vm.thisCargo.gwTtl/vm.thisCargo.pkgNum, 2);
                //     }
                //     if(vm.thisCargo.volTtl){
                //         vm.thisCargo.vol = DataUtils.round(vm.thisCargo.volTtl/vm.thisCargo.pkgNum, 4);
                //     }
                // }
                // if(vm.locations && vm.locations.length > 0){
                //     _onLocationSelected (vm.locations[0], vm.thisCargo);
                // }
                $scope.guide.warehousingDatas.push(vm.thisCargo);
            }
            if (vm.locations && vm.locations.length > 0) {
                angular.forEach($scope.guide.warehousingDatas, function (data) {
                    if (!data.id) {
                        _onLocationSelected(vm.locations[0], data);
                    } else if (data.location) {
                        _onLocationSelected(data.location, data);
                    }
                })
            }

            if (!$scope.guide.cargoType) {
                $scope.guide.cargoType = "generalCargo";
            }
            if (!$scope.guide.destCountryCode) {
                $scope.guide.destCountryCode = "US";
                $scope.guide.destCountryName = "美国";
            }
            else {
                $scope.guide.destCountryName = $rootScope.getCountryName($scope.guide.destCountryCode);
            }
            if (!$scope.guide.shipmentType) {
                $scope.guide.shipmentType = vm.bizType == "WMS" ? "WMS" : "FBASEA";
            }
            vm.pkgsTtl = $scope.guide.booking.pkgNum;
            getWarehouses();
        };
        function clearGuide() {
            if ($scope.guide.id) {
                $scope.guide = {};
            }
            vm.jobNum = '';
        }

        function getShipment() {
            if (!$scope.guide.jobNum) {
                return;
            }
            vm.isSaving = true;
            Guide.getFmsRapidInGuide({
                jobNum: $scope.guide.jobNum
            }, function (result) {
                $scope.guide = result;
                initGuidePl();
                vm.isSaving = false;
                layer.msg($translate.instant('entity.action.loadSuccess'));
            }, function (result) {
                vm.isSaving = false;
                if (result.status == 406) {
                    layer.msg("It's FCL shipment!");
                }
                else if (result.status == 403) {
                    layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                }
                else if (result.status == 404) {
                    layer.msg($translate.instant('global.noRecord'));
                }
                else {
                    layer.msg($translate.instant('global.loadFailed'));
                }
            })
        }


        function websockSubscribe() {
            if (!$rootScope.account.companyId || $rootScope.HAVENOAUTH('GOODSCAN')) {
                layer.msg("No binded equipment..")
                return;
            }
            WarehousingData.websockSubscribe({
                userId: $rootScope.account.id,
                companyId: $rootScope.account.companyId
            }, function (argument) {
                layer.msg("Connected with equipment..")
            })
        }


        function loadGuide(id) {
            var loadIndex = layer.load(1, { shade: 0.3 });
            var req = {};
            if (id) {
                req.id = id;
            } else if (vm.jobNum) {
                req.jobNum = vm.jobNum;
            }
            Guide.getFmsRapidInGuide(req, function (result) {
                layer.close(loadIndex);
                $scope.guide = result;
                initGuidePl();
            }, function (error) {
                layer.close(loadIndex);
                if (error.status == 403) {
                    layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                }
                else if (error.status == 404) {
                    layer.msg($translate.instant('global.noRecord'));
                }
                else {
                    layer.msg($translate.instant("global.loadFailed"));
                }
            });
        }


        function _onLocationSelected(item, cargo) {
            if (typeof item === "object" && item !== null) {
                cargo.location = item.code;
                cargo.locationId = item.id;
                angular.forEach($scope.guide.warehousingDatas, function (data) {
                    if (!data.location) {
                        data.location = item.code;
                        data.locationId = item.id;
                    }
                })
            } else {
                var matched = false;
                angular.forEach(vm.locations, function (data) {
                    if (data.code == item) {
                        cargo.location = data.code;
                        cargo.locationId = data.id;
                        matched = true;
                    }
                })
                angular.forEach($scope.guide.warehousingDatas, function (data) {
                    if (!data.location && !data.id) {
                        data.location = cargo.location;
                        data.locationId = cargo.locationId;
                    }
                })
                if (!matched) {
                    cargo.location = "";
                    cargo.locationId = "";
                    layer.msg("Location is not exist");
                    // fucusInput("field_location");
                    return;
                }
            }
            // layer.msg("Matched");
            // fucusInput("field_pkgNum");
        }
        function countrySelected(item) {
            $scope.guide.destCountryCode = item.code;
        }

        function getLocations() {
            if (!$scope.guide.whId) {
                layer.msg($translate.instant('autopsApp.wmsIn.chooseWarehouseAlert'));
                return;
            }
            if (vm.isSaving) {
                return;
            }
            if (!vm.locations || vm.locations.length == 0) {
                vm.isSaving = true;
                WmsWarehouse.getLocations({ warehouseId: $scope.guide.whId }, function (data) {
                    vm.isSaving = false;
                    vm.locations = data;
                    chooseLocation();
                });
            }
        }

        var chooseLocation = function () {
            if (vm.locations
                && vm.locations.length > 0
                && $scope.guide.warehousingDatas
                && $scope.guide.warehousingDatas.length > 0) {
                angular.forEach($scope.guide.warehousingDatas, function (cargo) {
                    _onLocationSelected(vm.locations[0], cargo);
                })
            }
        }

        var newCargo = { pkgType: "CTNS", pkgNum: 1, companyId: $rootScope.account.companyId };

        function addCargo() {
            if ($scope.guide.plConfirmed) {
                layer.msg($translate.instant('autopsApp.guide.sts.plConfirmed'));
                return;
            }
            if (!$scope.guide.warehousingDatas) {
                $scope.guide.warehousingDatas = [];
            }
            updateTtl();
            if (vm.pkgsTtl && $scope.guide.pkgNum >= vm.pkgsTtl) {
                var audio = document.getElementById("audioPlay");
                audio.play();
                $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.amtAlert', { actAmt: $scope.guide.pkgNum, limit: vm.pkgsTtl }), { icon: 0, shade: 0.3 })
                // return;
            }
            vm.thisCargo = angular.copy(newCargo);
            if (vm.locations && vm.locations.length > 0) {
                _onLocationSelected(vm.locations[0], vm.thisCargo);
            }
            $scope.guide.warehousingDatas.splice(0, 0, vm.thisCargo);
            $timeout(function () {
                $("#gw_field").focus();
            });
        }

        function focusCargo(cargo) {
            vm.thisCargo = cargo;
        }
        function delCargo(cargo) {
            if (cargo.id) {
                WarehousingData.delete({
                    id: cargo.id
                }, function () {
                    loadGuide($scope.guide.id);
                }, function (result) {
                });
                return;
            }
            var index = $scope.guide.warehousingDatas.indexOf(cargo);
            if (index > -1) {
                $scope.guide.warehousingDatas.splice(index, 1);
            };
        }
        function deleteByGuideId(cargo) {
            if (!$scope.guide.id) {
                return;
            }
            if ($rootScope.HAVENOAUTH('AU_WAREHOUSING_DATA')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_WAREHOUSING_DATA');
                return;
            }
            layer.confirm($translate.instant('global.delAllAlert'), {
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function (index) {
                WarehousingData.deleteByGuideId({
                    guideId: $scope.guide.id
                }, function () {
                    loadGuide($scope.guide.id);
                }, function (result) {
                });
                layer.close(index);
            }, function () {
            });
        }

        function getWarehouses() {
            if ($rootScope.account.authorities.indexOf('WMS') === -1) {
                return;
            }
            if (vm.warehouses && vm.warehouses.length > 0) {
                if (!$scope.guide.whId) {
                    warehouseSelected(vm.warehouses[0]);
                }
                return;
            }
            WmsWarehouse.getAll({
                includeOwn: true,
                includeVirtual: false,
                includeGroup: false
            }, function (data) {
                vm.warehouses = data;
                if (vm.warehouses.length > 0 && !$scope.guide.whId) {
                    warehouseSelected(vm.warehouses[0]);
                    getLocations();
                }
            });
        }

        function warehouseSelected(warehouse) {
            $scope.guide.whName = warehouse.name;
            $scope.guide.companyId = warehouse.companyId;

            if ($scope.guide.whId != warehouse.id) {
                $scope.guide.whId = warehouse.id;
                vm.locations = [];
                getLocations();
            }
        }

        function submitIn() {
            if ($scope.guide.plConfirmed) {
                layer.msg($translate.instant('autopsApp.guide.sts.plConfirmed'));
                return;
            }
            // if(!$scope.guide.shipperId){
            //     layer.msg($translate.instant('autopsApp.wmsIn.clientNullAlert'));
            //     return;
            // }
            for (var i = $scope.guide.warehousingDatas.length - 1; i >= 0; i--) {
                if (!$scope.guide.warehousingDatas[i].id) {
                    if (!$scope.guide.warehousingDatas[i].pkgNum) {
                        $scope.guide.warehousingDatas.splice(i, 1);
                    }
                    else if (!$scope.guide.warehousingDatas[i].cargoName
                        && !$scope.guide.warehousingDatas[i].gw
                        && !$scope.guide.warehousingDatas[i].length
                        && !$scope.guide.warehousingDatas[i].width
                        && !$scope.guide.warehousingDatas[i].height
                        && !$scope.guide.warehousingDatas[i].vol) {
                        $scope.guide.warehousingDatas.splice(i, 1);
                    }
                }
            }
            if ($rootScope.HAVEAUTH('WMS')) {
                if (!$scope.guide.whId) {
                    layer.msg($translate.instant('autopsApp.wmsIn.chooseWarehouseAlert'));
                    return;
                }
                // for(var i = 0; i < $scope.guide.warehousingDatas.length; i++){
                //     if ($scope.guide.warehousingDatas[i].pkgNum
                //         && !$scope.guide.warehousingDatas[i].location){
                //         var msg = $scope.guide.warehousingDatas[i].cargoName + ": " + $translate.instant('global.choose_location_alert');
                //         $rootScope.OPS_ALERT(msg);
                //         return;
                //     }
                // }
            }
            vm.isSaving = true;
            Guide.fmsRegistIn($scope.guide, onSaveSuccess, onSaveError);
        }
        function onSaveSuccess(result) {
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submitted'));
            loadGuide(result.id);
        }

        function onSaveError(result) {
            vm.isSaving = false;
            if (result.status == 409) {
                layer.msg($translate.instant('autopsApp.wmsIn.jobNumConflictAlert'));
            }
            else if (result.status == 406) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.overdue'));
            }
            else if (result.status == 403) {
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    icon: 3,
                    title: $translate.instant('global.messageInfo'),
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function (index) {
                    loadGuide();
                    layer.close(index);
                }, function () { });
            }
            else {
                layer.msg($translate.instant('global.save_fail'));
            }
        }


        function updateTtl(isPkg) {
            $scope.guide.palletNum = 0;
            $scope.guide.pkgNum = 0;
            $scope.guide.vol = 0;
            $scope.guide.gw = 0;

            angular.forEach($scope.guide.warehousingDatas, function (data) {
                var ttlCount;
                if (data.pkgNum) {
                    $scope.guide.pkgNum += data.pkgNum;
                    ttlCount = data.pkgNum;
                }
                if (data.pallets) {
                    $scope.guide.palletNum += data.pallets;
                    ttlCount = data.pallets;
                }
                if (ttlCount) {
                    if (data.vol) {
                        data.volTtl = DataUtils.round(data.vol * ttlCount, 4);
                        $scope.guide.vol += data.volTtl;
                    }
                    if (data.gw) {
                        data.gwTtl = DataUtils.round(data.gw * ttlCount, 2);
                        $scope.guide.gw += data.gwTtl;
                    }
                }
            })
            $scope.guide.vol = DataUtils.round($scope.guide.vol, 4);
            $scope.guide.gw = DataUtils.round($scope.guide.gw, 2);
            if (isPkg && vm.pkgsTtl && $scope.guide.pkgNum >= vm.pkgsTtl) {
                var audio = document.getElementById("audioPlay");
                audio.play();
                $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.amtAlert', { actAmt: $scope.guide.pkgNum, limit: vm.pkgsTtl }), { icon: 0, shade: 0.3 })
            }
        }

        function updateVol(cargo) {
            if (cargo.length && cargo.width && cargo.height) {
                cargo.vol = cargo.length * cargo.width * cargo.height / 1000000;
                cargo.vol = DataUtils.round(cargo.vol, 4);
                updateTtl();
            }

        }

        $(document).keydown(function (event) {
            if (event.keyCode == 13) {
                if ("gw_field" == document.activeElement.id) {
                    $("#field_length").focus();
                }
                else if ("field_length" == document.activeElement.id) {
                    $("#field_width").focus();
                }
                else if ("field_width" == document.activeElement.id) {
                    $("#field_height").focus();
                }
                else if ("field_height" == document.activeElement.id) {
                    $timeout(function () {
                        $("#button_addCargo").focus();
                    });
                }
            }
        });
        $(document).keydown(function (event) {
            if (event.keyCode == 83 && event.ctrlKey) {
                event.preventDefault();
                submitIn();
            }
            else if (event.ctrlKey && (event.keyCode == 13 || event.keyCode == 107)) {
                event.preventDefault();
                addCargo();
            }
        });

        vm.pkgsTtl = null;
        $scope.wmsLabels = function (reportType) {
            if (!$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            var guide = angular.copy($scope.guide);
            guide.pkgNum = vm.pkgsTtl;
            WmsIn.wmsRapidLabels({
                reportType: reportType,
                fileType: 1
            }, guide, function (result) {
                $rootScope.PRINTBYTES(result.data);
            }, function (error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        $scope._selected = function ($item) {
            $scope.guide.booking.deliverytoId = $item.code;
            $scope.guide.shiptoPostcode = $item.postCode;
            $scope.guide.destCountryCode = $item.countryCode;
            $scope.guide.shiptoPostcode = $item.postCode;
            $scope.guide.destCountryName = $rootScope.getCountryName($scope.guide.destCountryCode);
            AddressBook.getFbaAddress({ code: $item.code, countryCode: $item.countryCode}, function (result) {
                if (result) {
                    DataUtils.onGuideAddressChoosed($scope.guide, result);
                    $scope.guide.destCountryName = $rootScope.getCountryName($scope.guide.destCountryCode);

                }
            });
            autoRemoteCheck();
        }

        $scope.onChannelSelected = function (carrier) {
            $scope.guide.booking.carrierCode = carrier.code;
            $scope.guide.booking.carrierFullName = carrier.name;
            if (carrier.volCoe || carrier.volumeShare) {
                if (carrier.volCoe) {
                    $scope.guide.booking.divVolBy = carrier.volCoe;
                }
                if (carrier.volumeShare) {
                    $scope.guide.booking.volumeShare = carrier.volumeShare;
                }
            }
            autoRemoteCheck();
        }
        $scope.opsSellings = [];
        $scope.findPrices = function (isSelling) {
            if ($scope.opsSellings.length > 0) { return; }
            OpsCost.findPrices({
                isSelling: true,
                shipmentType: "FBA"
            }, function (result) {
                $scope.opsSellings = result;
            })
        }
        $scope.onProductChoosed = function (data) {
            DataUtils.updateGuideByProduct($scope.guide, data, true);
        }
        $scope.deliveryWayChange = function () {
            if ($scope.guide.deliveryWay == "express" || $scope.guide.shipmentType == "FBAAIR" || $scope.guide.shipmentType == "EXPRESS") {
                $scope.guide.chargeByCbm = false;
                $scope.guide.booking.divVolBy = 6000;
            }
            else {
                $scope.guide.chargeByCbm = true;
                $scope.guide.booking.divVolBy = 1000;
            }
        };

        function autoRemoteCheck() {
            if (!$scope.guide.destCountryCode
                || !$scope.guide.shiptoPostcode || $scope.guide.shiptoPostcode.length < 5
                || !$scope.guide.booking.carrierFullName) {
                return;
            }
            ExpressParcel.remoteCheck({
                country: $scope.guide.destCountryCode,
                postcode: $scope.guide.shiptoPostcode,
                carrier: $scope.guide.booking.carrierFullName
            }, function (results) {
                if (results && results.length > 0) {
                    $scope.guide.isRemote = true;
                    $rootScope.OPS_ALERT($scope.guide.booking.carrierFullName + ": is Remote!");
                }
                else {
                    $scope.guide.isRemote = false;
                    layer.msg($scope.guide.booking.carrierFullName + ": Not Remote!");
                }
            }, function () {
            });
        }


        $scope.gwTtlChange = function (data) {
            var ttlCount = data.pkgNum;
            if (data.pallets) {
                ttlCount = data.pallets;
            }
            if (ttlCount) {
                data.gw = DataUtils.round(data.gwTtl / ttlCount, 2);
            }
            updateByTtl();
        };

        $scope.volTtlChange = function (data) {
            var ttlCount = data.pkgNum;
            if (data.pallets) {
                ttlCount = data.pallets;
            }
            if (ttlCount) {
                data.vol = DataUtils.round(data.volTtl / ttlCount, 2);
            }
            updateByTtl();
        };
        function updateByTtl() {
            $scope.guide.vol = 0;
            $scope.guide.gw = 0;
            angular.forEach($scope.guide.warehousingDatas, function (data) {
                if (data.volTtl) {
                    $scope.guide.vol += data.volTtl;
                }
                if (data.gwTtl) {
                    $scope.guide.gw += data.gwTtl;
                }
            })
            $scope.guide.vol = DataUtils.round($scope.guide.vol, 4);
            $scope.guide.gw = DataUtils.round($scope.guide.gw, 2);
        }

        $scope.customRequiredChange = function () {
            $scope.guide.customRequired = !$scope.guide.customRequired;
        }
        $scope.isRemoteChange = function () {
            $scope.guide.isRemote = !$scope.guide.isRemote;
        }

        var unsubscribe = $rootScope.$on('autopsApp:warehousingDataReceived', function (event, result) {
            if (!$scope.guide.id || $scope.guide.id != result.guideId) {
                return;
            }
            var isNew = true;
            angular.forEach($scope.guide.warehousingDatas, function (data) {
                if (data.id == result.id) {
                    isNew = false;
                }
            })
            if (isNew) {
                $scope.guide.warehousingDatas.splice(0, 0, result);
                updateTtl(true);
            }
        });
        $scope.$on('$destroy', unsubscribe);

        function fucusInput(fdId) {
            $timeout(function () {
                $("#" + fdId).focus();
            });
        }
    }
})();
