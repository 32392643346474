(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AddressBookDialogController', AddressBookDialogController);

    AddressBookDialogController.$inject = ['$timeout', "$rootScope", '$scope', '$location', '$uibModalInstance', '$translate', '$uibModal',
    'entity', 'AddressBook', 'AccountAuth', 'WmsWarehouse', 'OpsCompany', 'JsExpression'];

    function AddressBookDialogController ($timeout, $rootScope, $scope, $location, $uibModalInstance, $translate, $uibModal,
        entity, AddressBook, AccountAuth, WmsWarehouse, OpsCompany, JsExpression) {
        var vm = this;

        vm.addressBook = entity;

        vm.clear = clear;
        vm.save = save;
        vm.accountAuth = accountAuth;
        vm.bindChange = bindChange;
        vm.clientSelected = clientSelected;
        vm.getWarehouses = getWarehouses;
        vm.warehouseSelected = warehouseSelected;
        vm.FROMJOB = true;
        vm.getServiceTypes = getServiceTypes;
        vm.weightRules = weightRules;
        vm.fillAddresssBook = fillAddresssBook;
        vm.serviceTypes = [];
        vm.marketplaces = [
            { id: "US", name: "美国" }
        ];
        vm.showtxt = {};
        $timeout(function (){
            if($location.absUrl().indexOf("UPSAuth") !== -1){
                layer.load(1, {shade: 0.3});
                var stateStr = $location.search().state;
                var idx = stateStr.lastIndexOf("_");
                stateStr = stateStr.substring(idx + 1);
                AddressBook.get({id : stateStr/69849842}, function(result){
                    vm.addressBook = result;
                    vm.addressBook.accessKey = $location.search().code;
                    var addressBookCopy = angular.copy(vm.addressBook);
                    if(vm.addressBook && vm.addressBook.servicesJson){
                        vm.addressBook.servicesJson = angular.fromJson(vm.addressBook.servicesJson, true);
                    }
                    var redirectUri = "https://" + $location.host();
                    if ($location.port() && $location.port() != '80' && $location.port() != '443') {
                        redirectUri = redirectUri + ":" + $location.port();
                    }
                    AddressBook.getUPSToken({redirectUri: redirectUri}, addressBookCopy, function(result){
                        layer.closeAll();
                        vm.addressBook = result;
                        if(vm.addressBook && vm.addressBook.servicesJson){
                            vm.addressBook.servicesJson = angular.fromJson(vm.addressBook.servicesJson, true);
                        }
                        layer.msg("授权成功");
                    }, function(error){
                        layer.closeAll();
                        if(error && error.data && error.data.description){
                            $rootScope.OPS_ALERT(error.data.description);
                        }
                        else {
                            layer.msg($translate.instant('global.messages.submittedfailed'));
                        }
                    })
                });
            } else if($location.absUrl().indexOf("spapi_oauth_code") !== -1){
                layer.load(1, {shade: 0.3});
                var stateStr = $location.search().state;
                var idx = stateStr.lastIndexOf("_");
                stateStr = stateStr.substring(idx + 1);
                AddressBook.get({id : stateStr/8157468}, function(result){
                    vm.addressBook = result;
                    vm.addressBook.password = $location.search().selling_partner_id;
                    vm.addressBook.token = $location.search().spapi_oauth_code;
                    // vm.accountBind.appPassword = $location.search().mws_auth_token;
                    var addressBookCopy = angular.copy(vm.addressBook);
                    if(vm.addressBook && vm.addressBook.servicesJson){
                        vm.addressBook.servicesJson = angular.fromJson(vm.addressBook.servicesJson, true);
                    }
                    var redirectUri = "https://" + $location.host();
                    if ($location.port() && $location.port() != '80' && $location.port() != '443') {
                        redirectUri = redirectUri + ":" + $location.port();
                    }
                    AddressBook.getAmazonRefreshToken(addressBookCopy, function(result){
                        layer.closeAll();
                        vm.addressBook = result;
                        if(vm.addressBook && vm.addressBook.servicesJson){
                            vm.addressBook.servicesJson = angular.fromJson(vm.addressBook.servicesJson, true);
                        }
                        layer.msg("授权成功");
                    }, function(error){
                        layer.closeAll();
                        if(error && error.data && error.data.description){
                            $rootScope.OPS_ALERT(error.data.description);
                        }
                        else {
                            layer.msg($translate.instant('global.messages.submittedfailed'));
                        }
                    })
                })
            }
            vm.FROMJOB = $rootScope.FROMJOB;
            angular.element('.form-group:eq(1)>input').focus();
            if(vm.addressBook && vm.addressBook.servicesJson){
                vm.addressBook.servicesJson = angular.fromJson(vm.addressBook.servicesJson, true);
            }
            if ($location.path().indexOf("copy") >= 0) {
                vm.addressBook.id = null;
                vm.addressBook.code = null;
                vm.addressBook.creater = null;
                vm.addressBook.createrId = null;
                vm.addressBook.servicesJson = null;
                vm.addressBook.enabled = false;
            }
            loadHostCompanyId();
        });
        /********************BEGIN*************************/
        $scope.disableAccountNum = function() {
            return vm.addressBook.bindType == 'DPD' || vm.addressBook.bindType == 'DELIVERR'
                || vm.addressBook.bindType == 'HLT' || vm.addressBook.bindType == 'KINGKONG'
                || vm.addressBook.bindType == 'AID' || vm.addressBook.bindType == 'SPBR'
                || vm.addressBook.bindType == 'HTK' || vm.addressBook.bindType == 'POSTPONY'
                || vm.addressBook.bindType == 'POSTLABEL' || vm.addressBook.bindType == 'DEFT'
                || vm.addressBook.bindType == 'HF' || vm.addressBook.bindType == 'EZE'
                || vm.addressBook.bindType == 'LSTM' || vm.addressBook.bindType == 'SPRT'
                || vm.addressBook.bindType == 'KSPD' || vm.addressBook.bindType == 'OPZA'
                || vm.addressBook.bindType == 'GBLS' || vm.addressBook.bindType == 'VTDRCT'
                || vm.addressBook.bindType == 'FRAYUN' || vm.addressBook.bindType == 'PACC'
                || vm.addressBook.bindType == 'ECPRT' || vm.addressBook.bindType == 'ECTMS'
                || vm.addressBook.bindType == 'AUOPS' || vm.addressBook.bindType == 'SHPSTT'
                || vm.addressBook.bindType == 'RPDL' || vm.addressBook.bindType == 'HUBEZ'
                || vm.addressBook.bindType == 'VITEUPS' || vm.addressBook.bindType == 'VITEFEDEX'
                || vm.addressBook.bindType == "ITDIDA" || vm.addressBook.bindType == "SIXSIXPOST"
                || vm.addressBook.bindType == "HYEUS" || vm.addressBook.bindType == "HUALEI"
                || vm.addressBook.bindType == "YUEMA" || vm.addressBook.bindType == 'PRIORITYONE'
                || vm.addressBook.bindType == "YUNWUJ" || vm.addressBook.bindType == 'HTY'
                || vm.addressBook.bindType == 'MAPLE' || vm.addressBook.bindType == 'FURTHERTRY'
                || vm.addressBook.bindType == 'APLUS' || vm.addressBook.bindType == 'BAOCHEN'
                || vm.addressBook.bindType == 'MFYC' || vm.addressBook.bindType == 'MAILAMERICAS'
                || vm.addressBook.bindType == 'SHIPILOT'
                || vm.addressBook.bindType == 'OIS' || vm.addressBook.bindType == 'UNI'
                || vm.addressBook.bindType == 'AUOPSNEW' || vm.addressBook.bindType == 'AMAZONSHIPPING'
                || vm.addressBook.bindType == 'SQUAREMART' || vm.addressBook.bindType == 'DTOP'
                || vm.addressBook.bindType == 'EXPRESS.EC' || vm.addressBook.bindType == 'WallTech'
                || vm.addressBook.bindType == 'AwesungNew';
        }

        $scope.showUserName = function() {
            return vm.addressBook.bindType && vm.addressBook.bindType != 'DELIVERR'
                && vm.addressBook.bindType != 'AID' && vm.addressBook.bindType != 'SPBR'
                && vm.addressBook.bindType != 'HTK' && vm.addressBook.bindType != 'POSTLABEL'
                && vm.addressBook.bindType != 'HF' && vm.addressBook.bindType != 'ADB'
                && vm.addressBook.bindType != 'DDUE' && vm.addressBook.bindType != 'KSPD'
                && vm.addressBook.bindType != 'GBLS' && vm.addressBook.bindType != 'VTDRCT'
                && vm.addressBook.bindType != 'ECTMS' && vm.addressBook.bindType != 'UPSNEW'
                && vm.addressBook.bindType != 'AUOPS' && vm.addressBook.bindType != 'IMILE'
                && vm.addressBook.bindType != 'VITEUPS' && vm.addressBook.bindType != 'VITEFEDEX'
                && vm.addressBook.bindType != 'FEDEXNEW' && vm.addressBook.bindType != 'FEDEXNEWTEST'
                && vm.addressBook.bindType != 'MAILAMERICAS' && vm.addressBook.bindType != 'UPSNEWRETURN'
                && vm.addressBook.bindType != 'AUOPSNEW' && vm.addressBook.bindType != 'AMAZONSHIPPING'
                && vm.addressBook.bindType != 'ZDA' && vm.addressBook.bindType != 'AwesungNew';
        }

        $scope.showPassword = function() {
            return vm.addressBook.bindType && vm.addressBook.bindType != 'ANMEI'
                && vm.addressBook.bindType != 'AWESUNG' && vm.addressBook.bindType != 'DELIVERR'
                && vm.addressBook.bindType != 'ZRSUPPLY' && vm.addressBook.bindType != 'TUSPS'
                && vm.addressBook.bindType != 'HLT' && vm.addressBook.bindType != 'KINGKONG'
                && vm.addressBook.bindType != 'RTB'
                && vm.addressBook.bindType != 'SPBR' && vm.addressBook.bindType != 'HTK'
                && vm.addressBook.bindType != 'POSTLABEL' && vm.addressBook.bindType != 'HF'
                && vm.addressBook.bindType != 'DDUE' && vm.addressBook.bindType != 'LSTM'
                && vm.addressBook.bindType != 'ORGE' && vm.addressBook.bindType != 'KSPD'
                && vm.addressBook.bindType != 'GBLS' && vm.addressBook.bindType != 'VTDRCT'
                && vm.addressBook.bindType != 'PACC' && vm.addressBook.bindType != 'SIXSIXPOST'
                && vm.addressBook.bindType != 'ECTMS' && vm.addressBook.bindType != 'IMILE'
                && vm.addressBook.bindType != 'RPDL' && vm.addressBook.bindType != 'BAOCHEN'
                && vm.addressBook.bindType != 'MAILAMERICAS' && vm.addressBook.bindType != 'HYEUS'
                && vm.addressBook.bindType != 'ZDA' && vm.addressBook.bindType !='WallTech'
                && vm.addressBook.bindType != 'AwesungNew';
        }

        $scope.showAccessKey= function() {
            return vm.addressBook.bindType && vm.addressBook.bindType != 'DPD'
                && vm.addressBook.bindType != 'DPDDE'
                && vm.addressBook.bindType != 'DELIVERR' && vm.addressBook.bindType != 'POSTPONY'
                && vm.addressBook.bindType != 'DEFT' && vm.addressBook.bindType != 'DDUE'
                && vm.addressBook.bindType != 'SPRT' && vm.addressBook.bindType != 'ECPRT'
                && vm.addressBook.bindType != 'OPZA' && vm.addressBook.bindType != 'FRAYUN'
                && vm.addressBook.bindType != 'SHPSTT' && vm.addressBook.bindType != "HUBEZ"
                && vm.addressBook.bindType != "ITDIDA" && vm.addressBook.bindType != "PRIORITYONE"
                && vm.addressBook.bindType != "FURTHERTRY" && vm.addressBook.bindType != "APLUS"
                && vm.addressBook.bindType != 'UPSNEW' && vm.addressBook.bindType != 'BAOCHEN'
                && vm.addressBook.bindType != 'MFYC' && vm.addressBook.bindType != 'UPSNEWRETURN'
                && vm.addressBook.bindType != 'IFFS' && vm.addressBook.bindType != 'OIS'
                && vm.addressBook.bindType != 'AMAZONSHIPPING' && vm.addressBook.bindType != 'SQUAREMART'
                && vm.addressBook.bindType != 'EXPRESS.EC';
        }

        var ratesAvaliable = function() {
            return vm.addressBook.bindType == 'UPS' || vm.addressBook.bindType == 'FEDEX'
                || vm.addressBook.bindType == 'FEDEXTST'
                || vm.addressBook.bindType == 'ANMEI' || vm.addressBook.bindType == 'AWESUNG'
                || vm.addressBook.bindType == 'HLT' || vm.addressBook.bindType == 'KINGKONG'
                || vm.addressBook.bindType == 'AID' || vm.addressBook.bindType == 'SPBR'
                || vm.addressBook.bindType == 'POSTPONY' || vm.addressBook.bindType == 'POSTLABEL'
                || vm.addressBook.bindType == 'DEFT' || vm.addressBook.bindType == 'ADB'
                || vm.addressBook.bindType == 'ORGE' || vm.addressBook.bindType == 'SIXSIXPOST'
                || vm.addressBook.bindType == 'KSPD' || vm.addressBook.bindType == 'OPZA'
                || vm.addressBook.bindType == 'VTDRCT' || vm.addressBook.bindType == 'ECTMS'
                || vm.addressBook.bindType == 'ECPRT' || vm.addressBook.bindType == 'HYEUS'
                || vm.addressBook.bindType == 'AUOPS' || vm.addressBook.bindType == 'SHPSTT'
                || vm.addressBook.bindType == 'RPDL' || vm.addressBook.bindType == 'ITDIDA'
                || vm.addressBook.bindType == "YUEMA" || vm.addressBook.bindType == 'YUNWUJ'
                || vm.addressBook.bindType == 'HTY' || vm.addressBook.bindType == 'FURTHERTRY'
                || vm.addressBook.bindType == 'APLUS' || vm.addressBook.bindType == 'BAOCHEN'
                || vm.addressBook.bindType == 'MFYC' || vm.addressBook.bindType == 'FEDEXNEW'
                || vm.addressBook.bindType == 'FEDEXNEWTEST' || vm.addressBook.bindType == 'UPSNEW'
                || vm.addressBook.bindType == 'RAPID' || vm.addressBook.bindType == 'UPSNEWRETURN'
                || vm.addressBook.bindType == 'GBLS' || vm.addressBook.bindType == 'PRIORITYONE'
                || vm.addressBook.bindType == 'IFFS' || vm.addressBook.bindType == 'SHIPILOT'
                || vm.addressBook.bindType == 'OIS' || vm.addressBook.bindType == 'AUOPSNEW'
                || vm.addressBook.bindType == 'UNI' || vm.addressBook.bindType == 'DTOP'
                || vm.addressBook.bindType == 'AMAZONSHIPPING' || vm.addressBook.bindType == 'DhlV4'
                || vm.addressBook.bindType == 'AwesungNew';
        }

        var servicesRequired = function() {
            return vm.addressBook.bindType != 'ANMEI' && vm.addressBook.bindType != 'POSTPONY'
                && vm.addressBook.bindType != 'FEDEX' && vm.addressBook.bindType != 'ADB'
                && vm.addressBook.bindType != 'VTDRCT' && vm.addressBook.bindType != 'AUOPS'
                && vm.addressBook.bindType != 'SHPSTT' && vm.addressBook.bindType != 'RPDL'
                && vm.addressBook.bindType != 'PRIORITYONE' && vm.addressBook.bindType != 'RAPID'
                && vm.addressBook.bindType != 'AUOPSNEW' && vm.addressBook.bindType != 'UNI';
        }

        //LABEL_NO_SERVICES_REQUIRED LABEL_RATES_EDI_TYPES

        /********************END*************************/
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if(!vm.addressBook.whName){
                vm.addressBook.whId = null;
            }
            var addressBook = angular.copy(vm.addressBook);
            if(addressBook.servicesJson){
                addressBook.servicesJson = angular.toJson(addressBook.servicesJson);
            }
            if (addressBook.id !== null) {
                AddressBook.update(addressBook, onSaveSuccess, onSaveError);
            } else {
                AddressBook.save(addressBook, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            layer.msg($translate.instant('global.messages.submitted'));
            $scope.$emit('autopsApp:addressBookUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submittedfailed'));
        }

        function accountAuth () {
            $uibModal.open({
                templateUrl: 'app/entities/account-auth/account-auth-dialog.html',
                controller: 'AccountAuthDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function(){
                        return vm.addressBook;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountAuth');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }
        function bindChange () {
            if(vm.addressBook.binded && !vm.addressBook.bindType){
                vm.addressBook.bindType = "UPS";
            }
        }
        $scope._onItemSelected = function(data) {
            vm.addressBook.itemId = data.id;
        }
        function clientSelected (client, fd) {
            vm.addressBook[fd] = client.id;
        }


        $scope.clearServiceJson = function() {
            vm.addressBook.servicesJson = [];
            vm.addressBook.accountNum = null;
            vm.addressBook.userName = null;
            vm.addressBook.password = null;
            vm.addressBook.accessKey = null;
            vm.serviceTypes  = [];
        }
        $scope.restrictedServicesChange = function() {
            if(!vm.addressBook.restrictedServices && (!vm.addressBook.servicesJson || vm.addressBook.servicesJson.length == 0)){
                $rootScope.OPS_ALERT($translate.instant("autopsApp.addressBook.serviceListEmpty"));
                return;
            }
            vm.addressBook.restrictedServices = !vm.addressBook.restrictedServices;
        }

        $scope.autoChannelEnable = function() {
            // layer.msg($translate.instant("global.developingAlert"));
            // return;
            if(!vm.addressBook.enabled && servicesRequired() && (!vm.addressBook.servicesJson || vm.addressBook.servicesJson.length == 0)){
                $rootScope.OPS_ALERT($translate.instant("autopsApp.addressBook.serviceListEmpty"));
                return;
            }
            if(!ratesAvaliable()){
                $rootScope.OPS_ALERT($translate.instant("autopsApp.addressBook.channelNotAvailable"));
                return;
            }
            if(vm.addressBook.enabled){
                vm.addressBook.enabled = false;
                vm.addressBook.servicesJson = [];
            }
            else {
                if(vm.addressBook.bindType == "ECTMS"){
                    vm.addressBook.enabled = true;
                    return;
                }
                layer.msg("Service availability testing...", {
                    shade: 0.3,
                    time: 200000
                });
                var addressBook = angular.copy(vm.addressBook);
                if(addressBook.servicesJson){
                    addressBook.servicesJson = angular.toJson(addressBook.servicesJson);
                }
                AddressBook.labelRateTest(addressBook, function(result){
                    layer.closeAll();
                    vm.addressBook.enabled = true;
                    layer.msg($translate.instant('global.valid_success'));
                }, function(error){
                    layer.closeAll();
                    if(error && error.data && error.data.description){
                        $rootScope.OPS_ALERT(error.data.description);
                    }
                    else {
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.addressBook.enableFailed'));
                    }
                })
            }
        }

        $scope.shipServiceSelected = function(item) {
            if(!vm.addressBook.servicesJson){
                vm.addressBook.servicesJson = [];
            }
            var isNew = true;
            angular.forEach(vm.addressBook.servicesJson, function(data){
                if(item.type == data.type && item.discription == data.discription){
                    isNew = false;
                }
            })
            if(isNew){
                var service = {
                    "type": item.type,
                    "discription": item.discription
                }
                vm.addressBook.servicesJson.push(service);
            }
            vm.addressBook.serviceDescription="";
        }

        $scope.delService = function(index) {
            vm.addressBook.servicesJson.splice(index, 1);
        };


        $scope.addressCheck = function() {
            if(angular.uppercase(vm.addressBook.countryCode) != "US"){
                layer.msg($translate.instant('autopsApp.addressBook.notUsAlert'));
                return;
            }
            var resquestBody = angular.copy(vm.addressBook);
            if(resquestBody.servicesJson){
                resquestBody.servicesJson = angular.toJson(resquestBody.servicesJson);
            }
            AddressBook.addressCheck(resquestBody, function(result){
                angular.copy(result.addressBook, vm.addressBook)
                if(result.codeType && result.codeType.description){
                    $rootScope.OPS_ALERT(result.codeType.description);
                }
                else {
                    layer.msg($translate.instant('autopsApp.addressBook.addressCheckSuccess'));
                }
            }, function(error){
                layer.msg($translate.instant('global.requestFail'));
            })
        }

        $scope.getCityState = function() {
            if(angular.uppercase(vm.addressBook.countryCode) != "US"){
                layer.msg($translate.instant('autopsApp.addressBook.notUsAlert'));
                return;
            }
            if(vm.addressBook.province && vm.addressBook.city){
                return;
            }
            if(!vm.addressBook.postCode || vm.addressBook.postCode.length < 5){
                return;
            }
            var resquestBody = angular.copy(vm.addressBook);
            if(resquestBody.servicesJson){
                resquestBody.servicesJson = angular.toJson(resquestBody.servicesJson);
            }
            AddressBook.getCityState(resquestBody, function(result){
                if(result.city){
                    vm.addressBook.city = result.city;
                }
                if(result.province){
                    vm.addressBook.province = result.province;
                }
            }, function(error){
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                }
                else {
                    layer.msg($translate.instant('global.requestFail'));
                }
            })
        }

        $scope.getZipCode = function() {
            if(angular.uppercase(vm.addressBook.countryCode) != "US"){
                layer.msg($translate.instant('autopsApp.addressBook.notUsAlert'));
                return;
            }
            if(!vm.addressBook.province || !vm.addressBook.city || vm.addressBook.postCode){
                return;
            }
            var resquestBody = angular.copy(vm.addressBook);
            if(resquestBody.servicesJson){
                resquestBody.servicesJson = angular.toJson(resquestBody.servicesJson);
            }
            AddressBook.getZipCode(resquestBody, function(result){
                if(result.postCode){
                    vm.addressBook.postCode = result.postCode;
                }
            }, function(error){
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                }
                else {
                    layer.msg($translate.instant('global.requestFail'));
                }
            })
        }
        vm.wmswarehouses = [];
        function getWarehouses () {
            if(vm.wmswarehouses.length > 0){
                return;
            }
            WmsWarehouse.getAll({
                includeOwn: true,
                includeVirtual: true,
                includeGroup: true
            }, function (data) {
                vm.wmswarehouses = data;
            });
        }

        function warehouseSelected (data) {
            vm.addressBook.whId = data.id;
        }

        function getServiceTypes() {
            if(vm.serviceTypes && vm.serviceTypes.length > 0){
                return;
            }
            var resquestBody = angular.copy(vm.addressBook);
            if(resquestBody.servicesJson){
                resquestBody.servicesJson = angular.toJson(resquestBody.servicesJson);
            }
            AddressBook.getServicesList({}, resquestBody, function(results){
                vm.serviceTypes = results;
            })
        }

        var loadHostCompanyId = function() {
            if(!$rootScope.HOST_COMPANY_ID){
                OpsCompany.getCompanyByUserId({userId: $rootScope.account.id}, function(result){
                    $rootScope.HOST_COMPANY_ID = result.companyId;
                })
            }
        }

        $scope._onCountrySelected = function(item) {
            if($rootScope.HOST_COMPANY_ID == 411){
                vm.addressBook.countryCode = angular.uppercase(item.enName) + "(" + item.code + ")";
            }
        }

        $scope.goUPS = function() {
            if(!vm.addressBook.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if(!vm.addressBook.hubId) {
                layer.msg($translate.instant('autopsApp.addressBook.clientIdCannotBeEmpty'));
                return;
            }
            // 因UPS redirectUri只能使用https不能使用http，故协议固定使用https，如跳转回乐代系统时无法使用https，可在浏览器地址栏手动去掉s
            var redirectUri = "https://" + $location.host();
            if ($location.port() && $location.port() != '80' && $location.port() != '443') {
                redirectUri = redirectUri + ":" + $location.port();
            }
            var state = $location.protocol() + "-" + $location.host() + "_" + "UPSAuth" + "_" + (vm.addressBook.id * 69849842);
            var url = "https://wwwcie.ups.com/security/v1/oauth/authorize?client_id=" + vm.addressBook.hubId + "&redirect_uri=" + redirectUri + "&response_type=code&state=" + state;
            window.open(url);
            $rootScope.OPS_ALERT($translate.instant("autopsApp.addressBook.loginAuthAlert"), {
                icon: 0,
                yes:function(){
                    $uibModalInstance.close();
                    layer.closeAll();
                }
            });
        };
        function weightRules() {
            if(!vm.addressBook.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/js-expression/js-expression-weight-rules.html',
                controller: 'JsExpressionWeightRulesController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return JsExpression.getByAddressBookId({addressBookId: vm.addressBook.id}).$promise;
                    }
                }
            }).result.then(function() {
            }, function() {
            });
        }
        function fillAddresssBook() {
            var resquestBody = angular.copy(vm.addressBook);
            if(resquestBody.servicesJson){
                resquestBody.servicesJson = angular.toJson(resquestBody.servicesJson);
            }
            AddressBook.fillAddresssBook({}, resquestBody, function(result){
                if(vm.addressBook.bindType == "ADB" || vm.addressBook.bindType == 'RAPID'){
                    if(!result.accountNum){
                        $rootScope.OPS_ALERT("没有匹配的uuid，请检查发件地址邮编是否匹配！");
                    }
                    else{
                        vm.addressBook.accountNum = result.accountNum;
                        $rootScope.OPS_ALERT("已自动获取+匹配uuid，请核查是否正确！");
                    }
                }
            }, $rootScope.ALERT_ERROR);
        }


        $scope.goAmazon = function() {
            if(!vm.addressBook.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            var amazonUrl = "";
            if(vm.addressBook.userName == "US"){
                amazonUrl = "https://sellercentral.amazon.com";
            }
            if(vm.addressBook.userName == "CA") {
                amazonUrl = "https://sellercentral.amazon.ca";
            }
            if(vm.addressBook.userName == "MX") {
                amazonUrl = "https://sellercentral.amazon.com.mx";
            }
            if(vm.addressBook.userName == "BR") {
                amazonUrl = "https://sellercentral.amazon.com.br";
            }
            amazonUrl += "/apps/authorize/consent?application_id=amzn1.sp.solution.ed9c671c-70ae-42b0-9008-e9e89c360f9c&state=";
            // amazonUrl += "/apps/authorize/consent?application_id=amzn1.sellerapps.app.18cf8d02-dfb5-446b-8121-b48f1d7667c8&state=";
            var absUrl = $location.absUrl();
            amazonUrl += $location.protocol() + "-" + $location.host() + "_" + "amazonshipping" + "_" + (vm.addressBook.id * 8157468);
            window.open(amazonUrl);
            $rootScope.OPS_ALERT($translate.instant("autopsApp.addressBook.loginAuthAlert"), {
                icon: 0,
                yes:function(){
                    $uibModalInstance.close();
                    layer.closeAll();
                }
            });
        };


        $scope.amazonMarketplaceChange = function() {
            vm.addressBook.password = null;
            vm.addressBook.token = null;
        }

        $scope.Distribution_Center = [
            { value: "Forest Park, GA", label: "USATL1"},
            { value: "USBOS1", label: "Franklin, MA"},
            { value: "USBWI1", label: "Elkridge, MD"},
            { value: "USCAK1", label: "Stow, OH"},
            { value: "USCVG1", label: "Hebron, KY"},
            { value: "USDEN1", label: "Denver, CO"},
            { value: "USEWR1", label: "Secaucus, NJ"},
            { value: "USLAX1", label: "Compton, CA"},
            { value: "USMCO1", label: "Orlando, FL"},
            { value: "USORD1", label: "Melrose Park, IL"},
            { value: "USPHX1", label: "Phoenix, AZ"},
            { value: "USRDU1", label: "Raleigh, NC"},
            { value: "USSEA1", label: "Auburn, WA"},
            { value: "USSFO1", label: "Union City, CA"},
            { value: "USSLC1", label: "Salt Lake City, UT"},
            { value: "USSTL1", label: "St. Louis, MO"},
            { value: "USIAH1", label: "Houston, TX"},
            { value: "CNYYZ1", label: "Mississauga, ON, CA"},
            { value: "GBLHR1", label: "Axis Park, Langley, GB"}]
    }
})();
