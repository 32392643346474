(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('FbaDialogController', FbaDialogController);

    FbaDialogController.$inject = ['$timeout', '$stateParams', '$scope', '$rootScope', '$http', '$state', '$location', '$uibModal', '$translate', '$uibModalInstance', '$translatePartialLoader',
        'Principal', 'Guide', 'entity', 'OpsRates', 'Dictionary', 'ExpressParcel',
        'Booking', 'DateUtils', 'BookingCtns', 'OpsFactory', 'AccountBind', 'WarehousingData',
        'OpsVender', 'GuideCtns', 'OpsCompany', 'OpsReport', 'OpsCarrier', 'WmsIn', 'WmsWarehouse',
        'OpsFiles', 'DataUtils',  'OpsCost', 'Email', 'GuideTemplate', 'AddressBook', 'GridState'
    ];

    function FbaDialogController($timeout, $stateParams, $scope, $rootScope, $http, $state, $location, $uibModal, $translate, $uibModalInstance, $translatePartialLoader,
        Principal, Guide, entity, OpsRates, Dictionary, ExpressParcel,
        Booking, DateUtils, BookingCtns, OpsFactory, AccountBind, WarehousingData,
        OpsVender, GuideCtns, OpsCompany, OpsReport, OpsCarrier, WmsIn, WmsWarehouse,
        OpsFiles, DataUtils, OpsCost, Email, GuideTemplate, AddressBook, GridState) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                initGuide();
            });
        }
        $translatePartialLoader.addPart('expressParcel');

        $scope.guide = entity;
        var guideCopy = {};
        var activeTabCopy = $rootScope.GUIDEACTIVETAB == null?0:$rootScope.GUIDEACTIVETAB;
        $scope.activeTab = activeTabCopy;

        var path = $location.path();
        var allRoles = ["ROLE_SALES", "ROLE_CS", "ROLE_OPERATOR", "ROLE_DOCUMENT", "ROLE_OVERSEAS_CS"];
        $timeout(function() {
            loadData();
            $rootScope.PARCEL_SERVICES  = null;
            for(var i = 0; i < allRoles.length; i++){
                if($rootScope.HAVEAUTH(allRoles[i])){
                    $scope.choosedRole = allRoles[i];
                    break;
                }
            }
            $translate.refresh();
        });


        $scope.isVMed = false;
        $scope.isVFinal = false;
        $scope.isVClient= false;

        function initVirtual () {
            if($scope.guide.isVirtual){
                if (!$rootScope.account.companyId){
                    $scope.isVClient = true;
                }
                else if(!$scope.guide.medCompanyId || $scope.guide.medCompanyId == $rootScope.account.companyId){
                    $scope.isVMed = true;
                }
                else {
                    $scope.isVFinal = true;
                }
            }
        }
        $scope.cargoIsOut = false;
        $scope.cargoIsIn = false;
        $scope.jobNumCopy = null;
        var initGuide = function() {
            initVirtual();
            $scope.jobNumCopy = $scope.guide.jobNum;
            if($scope.guide.otherTimeTwo){
                $scope.cargoIsOut = true;
            }
            else {
                $scope.cargoIsOut = false;
            }
            if($scope.guide.otherTimeOne){
                $scope.cargoIsIn = true;
            }
            else {
                $scope.cargoIsIn = false;
            }
            if ($scope.guide.id
                && $rootScope.account.mustAudit
                && isOpDocOnly()
                && $scope.guide.createdBy != $rootScope.account.id
                && $scope.guide.checkStatus != 'approved') {
                layer.msg($translate.instant('autopsApp.guide.home.notAuditedAlert'));
                goBack();
                return;
            }
            if (!$scope.guide.guideShipperBl) {
                $scope.guide.guideShipperBl = $rootScope.account.blShow;
            }
            $scope.lanes = $rootScope.GET_LINES($scope.guide.shipmentType);

            if (!$scope.guide.polName) {
                angular.forEach($rootScope.ISSEA($scope.guide.shipmentType)?$rootScope.OPSPORTS.seaports:$rootScope.OPSPORTS.airports, function(data) {
                    if (data.name == $rootScope.account.defaultPol) {
                        $scope.guide.polName = data.name;
                        $scope.guide.polId = data.code;
                        $scope.guide.booking.polName = data.name;
                        $scope.guide.booking.polId = data.code;
                    }
                })
            }
            if (!$scope.guide.issuePlace) {
                $scope.guide.issuePlace = $rootScope.account.defaultPol;
            }
            if (!$scope.guide.booking) {
                $scope.guide.booking = {};
            }
            if (!$scope.guide.booking.notifyBl) {
                $scope.guide.booking.notifyBl = "SAME AS CONSIGNEE";
            }
            if (!$scope.guide.guideNotifyBl) {
                $scope.guide.guideNotifyBl = "SAME AS CONSIGNEE";
            }
            if (!$scope.guide.guideDocs) {
                $scope.guide.guideDocs = [];
            }
            if (!$scope.guide.guideCtns) {
                $scope.guide.guideCtns = [];
            }
            /*初始化创建人*/
            if (!$scope.guide.createdBy || !$scope.guide.companyId) {
                $scope.guide.createdBy = $rootScope.account.id;
                if(!$scope.isVMed){
                    $scope.guide.companyId = $rootScope.account.companyId;
                }
            }
            if (!$scope.guide.id && !$scope.guide.salesName && $scope.salesFilter($rootScope.account) && !$scope.isVFinal) {
                $scope.guide.salesId = $rootScope.account.id;
                $scope.guide.salesName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                $scope.guide.team = $rootScope.account.team;
                $scope.guide.branch = $rootScope.account.branch;
            }
            if (!$scope.guide.id && !$scope.guide.cusName && $scope.cusFilter($rootScope.account) && !$scope.isVFinal) {
                $scope.guide.cusId = $rootScope.account.id;
                $scope.guide.cusName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                $scope.guide.booking.cusId = $rootScope.account.id;
            }
            if (!$scope.guide.id && !$scope.guide.opName && $scope.opFilter($rootScope.account) && !$scope.isVFinal) {
                $scope.guide.opName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                $scope.guide.booking.opId = $rootScope.account.id;
                $scope.guide.opId = $rootScope.account.id;
            }
            // if (!$scope.guide.id && !$scope.guide.bookingBy && $scope.opFilter($rootScope.account) && !$scope.isVFinal) {
            //     $scope.guide.bookingBy = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            //     $scope.guide.bookingById = $rootScope.account.id;
            // }
            if (!$scope.guide.id && !$scope.guide.docName && $scope.docFilter($rootScope.account) && !$scope.isVFinal) {
                $scope.guide.docId = $rootScope.account.id;
                $scope.guide.docName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                $scope.guide.booking.docId = $rootScope.account.id;
            }
            if (!$scope.guide.id && !$scope.guide.checkByName && $scope.marketFilter($rootScope.account) && !$scope.isVFinal) {
                $scope.guide.checkById = $rootScope.account.id;
                $scope.guide.checkByName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            }
            if(!$scope.guide.booking.divVolBy){
                if($scope.guide.chargeByCbm){
                    $scope.guide.booking.divVolBy = 1000;
                }
                else {
                    $scope.guide.booking.divVolBy = 6000;
                }
            }
            if($scope.guide.hblConfirmedTime){
                $scope.hblConfirmed = true;
            }
            else {
                $scope.hblConfirmed = false;
            }
            if($scope.guide.mblConfirmedTime){
                $scope.mblConfirmed = true;
            }
            else {
                $scope.mblConfirmed = false;
            }
            if ($scope.guide.status == "new" && $scope.guide.booking.id) {
                OpsFiles.viewBookinSendedFile({
                    guideId: $scope.guide.id
                }, function(response) {
                    var file = new Blob([response.data], {
                        type: 'application/pdf'
                    });
                    if (file.size == 0) {
                        return;
                    }
                    var fileURL = URL.createObjectURL(file);
                    var title = $translate.instant('autopsApp.guide.detail.booking');
                    layer.open({
                        type: 2,
                        area: ['900px', '680px'],
                        maxmin: true,
                        title: title,
                        shade: 0.3,
                        moveType: 0,
                        shift: 0,
                        btn: [$translate.instant('autopsApp.guide.layer.acceptBooking'), $translate.instant('entity.action.back'), $translate.instant('autopsApp.guide.layer.declineBooking')],
                        content: fileURL,
                        yes: function(index, layero) {
                            $scope.guide.booking.status = "Confirmed";
                            $scope.guide.status = "Uncommitted";
                            $scope.guide.booking.lastModifiedTime = new Date();
                            Guide.update($scope.guide, onSaveSuccess, onSaveError);
                            $translate.refresh();
                        },
                        cancel: function(index) {
                            layer.close(index);
                            goBack();
                        },
                        btn3: function(index, layero) {
                            $scope.guide.booking.status = "Declined";
                            $scope.guide.status = "Declined";
                            $scope.guide.booking.lastModifiedTime = new Date();
                            Guide.update($scope.guide, onSaveSuccess, onSaveError);
                            goBack();
                        }
                    });
                }, function(error) {
                    //layer.msg($translate.instant('autopsApp.guide.layer.noBooking'));
                });
            };
            guideCopy = angular.copy($scope.guide);
            if (guideCopy.jobNum) {
                document.title = guideCopy.jobNum;
            } else {
                document.title = "New FBA Biz";
            }
            $scope.gwVolCopy = "";
            if($scope.guide.pkgNum != null){
                if($scope.guide.booking.pkgType){
                    $scope.gwVolCopy = $scope.guide.pkgNum + $scope.guide.booking.pkgType + "; ";
                }
                else {
                    $scope.gwVolCopy = $scope.guide.pkgNum + ";";
                }
            }
            if($scope.guide.gw){
                $scope.gwVolCopy = $scope.gwVolCopy + $scope.guide.gw.toFixed(2) + "KGS" + "; ";
            }
            if ($scope.guide.vol){
                $scope.gwVolCopy = $scope.gwVolCopy + $scope.guide.vol.toFixed(3) + "CBM";
            }
            $rootScope.getCountryName($scope.guide.destCountryCode);
            getHblsCount();
            $rootScope.VOID_MARK($scope.guide);
            $rootScope.AUTOFULLNODE($scope.guide);
            $scope.$emit('autopsApp:guideUpdate', guideCopy);
        }

        $scope.loadGuide = function() {
            layer.confirm($translate.instant('autopsApp.guide.refreshAlert'), {
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {
            });
        }

        var loadGuide = function(refreshPWV) {
            Guide.get({
                id: $scope.guide.id
            }, function(result) {
                $scope.guide = result;
                initGuide();
                $rootScope.SET_AUTO_TRACK($scope.guide, true);
                if(refreshPWV){
                    $scope.updateVolGw();
                    $scope.save();
                }
            });
        }

        var loadData = function() {
            if (path.indexOf("dialog") >= 0 || $rootScope.id == "view") {
                initGuide();
            } else if (path.indexOf("copy") >= 0 || $rootScope.id == "copy") {
                $scope.guide = DataUtils.clearCopyGuide($scope.guide);
                var now = new Date();
                $scope.guide.createdTime = now;
                $scope.guide.lastModifiedTime = now;
                $scope.guide.createdBy = $rootScope.account.id;
                if(!$scope.guide.isVirtual){
                    $scope.guide.companyId = $rootScope.account.companyId;
                }
                $scope.guide.checkStatus = "notChecked";
                $scope.guide.bookingBy = null;
                $scope.guide.bookingById = null;
                if ($rootScope.IS_OP_CHECK()) {
                    $scope.guide.status = "new";
                } else {
                    $scope.guide.status = "Uncommitted";
                }

                $rootScope.COPY_GUIDE_CHOOSE($scope.guide);
                initGuide();
            } else {
                var now = new Date();
                if (angular.isDefined($rootScope.account)) {
                    $scope.guide = {
                        createdBy: $rootScope.account.id,
                        companyId: $rootScope.account.companyId,
                        createdTime: now,
                        shipmentType: $rootScope.newShipmentType,
                        booking: {
                            shipmentType: $rootScope.newShipmentType,
                            bookingCtns: [],
                            bookingPrices: [],
                            bookingTrucks: []
                        },
                        bookingCustoms: [],
                        guideRates: [],
                        guideDocs: [],
                        guideCtns: [],
                        checkStatus: "notChecked",
                        chargeByCbm: $rootScope.newShipmentType.indexOf("SEA") !== -1 || $rootScope.newShipmentType=="WMS",
                        isApplied: false,
                        isExport: path.indexOf("import") !== -1?false:true,
                        mblOnly: true,
                        allBilled: false,
                        isVoid: false,
                        isClosed: false,
                        isWood: false,
                        isTemplate: false,
                        isLocked: false,
                        issueFeederHbl: false,
                        etdLocked: false,
                        trackingFinished: false,
                        hidenCost: false,
                        hidenSelling: false,
                        vat: false,
                        // dontSynTtl: true,
                        incomeTax: false,
                        lastModifiedTime: now
                    };
                    $scope.guide.bizType = $scope.isFba()?"direct":"nomination";
                    if ($rootScope.IS_OP_CHECK()) {
                        $scope.guide.status = "new";
                    } else {
                        $scope.guide.status = "Uncommitted";
                    }
                    if($scope.guide.chargeByCbm){
                        $scope.guide.booking.divVolBy = 1000;
                    }
                    else {
                        $scope.guide.booking.divVolBy = 6000;
                    }
                    initGuide();
                }
            }
        }
        var isOpDocOnly = function() {
            if(path.indexOf("expPop") >= 0){
                if ($rootScope.account.id == $scope.guide.salesId || $rootScope.account.id == $scope.guide.cusId) {
                    return false;
                }
                if (!$rootScope.account || angular.equals($rootScope.account, {})) {
                    return true;
                }
                var authorities = ["ROLE_BOSS", "ROLE_SALES", "ROLE_CS", "ROLE_MARKET"];
                for (var i = 0; i < authorities.length; i++) {
                    if ($rootScope.account.authorities.indexOf(authorities[i]) !== -1) {
                        return false;
                    }
                }
                return true;
            }
            if($rootScope.choosedRole == "ROLE_OPERATOR" || $rootScope.choosedRole == "ROLE_DOCUMENT" || ($rootScope.choosedRole && $rootScope.choosedRole.indexOf("TABLE") !== -1)){
                return true;
            }
            return false;
        };
        var isOpOrSales = function() {
            var authorities = ["ROLE_BOSS", "ROLE_DOCUMENT", "ROLE_MARKET", "ROLE_OPERATOR", "ROLE_SALES", "ROLE_CS"];
            if (!$rootScope.account || angular.equals($rootScope.account, {})) {
                return false;
            }
            for (var i = 0; i < authorities.length; i++) {
                if ($rootScope.account.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }
            return false;
        };

        $scope.payerData = {
            value: []
        };

        var payerChooseOneForEmailIndex = 0;

        $scope.opFilter = function(item) {
            return item.authorities.indexOf("ROLE_OPERATOR") !== -1;
        };
        $scope.docFilter = function(item) {
            return item.authorities.indexOf("ROLE_DOCUMENT") !== -1;
        };
        $scope.salesFilter = function(item) {
            return item.authorities.indexOf("ROLE_SALES") !== -1;
        };
        $scope.cusFilter = function(item) {
            return item.authorities.indexOf("ROLE_CS") !== -1;
        };
        $scope.overseasCusFilter = function(item) {
            return item.authorities.indexOf("ROLE_OVERSEAS_CS") !== -1;
        };
        $scope.marketFilter = function(item) {
            return item.authorities.indexOf("ROLE_MARKET") !== -1;
        };
        $scope.finFilter = function(item) {
            return item.authorities.indexOf("ROLE_ACCOUNT") !== -1;
        };
        $scope.bookingByFilter = function(item) {
            return item.authorities.indexOf("BOOKING_BY") !== -1 || item.authorities.indexOf("ROLE_WAREHOUSE") !== -1;
        };
        $scope.truckByFilter = function(item) {
            if($rootScope.account.companyId == 647){
                return item.authorities.indexOf("TRUCK_BY") !== -1 || item.authorities.indexOf("ROLE_SALES") !== -1;
            }
            return item.authorities.indexOf("TRUCK_BY") !== -1;
        };
        $scope.customByFilter = function(item) {
            return item.authorities.indexOf("CUSTOM_BY") !== -1;
        };

        $scope.hidenAgent = function() {
            if ($scope.guide && !$scope.guide.deliverySecret) {
                return false;
            }
            if (!$rootScope.account || angular.equals($rootScope.account, {})) {
                return true;
            }
            var authorities = ["ROLE_OPERATOR", "ROLE_ACCOUNT", "ROLE_DOCUMENT", "ROLE_BOSS"];
            for (var i = 0; i < authorities.length; i++) {
                if ($rootScope.account.authorities.indexOf(authorities[i]) != -1) {
                    return false;
                }
            }
            return true;
        };

        $scope.hidenSelling = function() {
            if ($scope.guide && !$scope.guide.hidenSelling) {
                return false;
            }
            if (!$rootScope.account || angular.equals($rootScope.account, {})) {
                return true;
            }
            return $rootScope.account.authorities.indexOf("FUN_VIEWSELLING") == -1;
        };

        $scope.hideCost = function() {
            if ($scope.guide && !$scope.guide.hidenCost) {
                return false;
            }
            if (!$rootScope.account || angular.equals($rootScope.account, {})) {
                return true;
            }
            return $rootScope.account.authorities.indexOf("FUN_VIEWCOST") == -1;
        };

        $scope.files = new Array();
        $scope.clientFiles = new Array();
        var goBack = function() {
            $uibModalInstance.close(guideCopy);
        }

        $scope.goBack = function() {
            if (!angular.equals($scope.guide, guideCopy)) {
                layer.confirm($translate.instant('global.notSavedAlert'), {
                    title: $translate.instant('global.messageInfo'),
                    btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
                }, function(index) {
                    layer.close(index);
                    goBack();
                }, function() {});
                return;
            } else {
                goBack();
            }
        }

        $scope.deleteCtn = function(bookingCtn) {
            if(!bookingCtn.id || !$scope.guide.id){
                var index = $scope.guide.booking.bookingCtns.indexOf(bookingCtn);
                if (index > -1) {
                    $scope.guide.booking.bookingCtns.splice(index, 1);
                };
                return;
            }
            if($scope.guide.isClosed){return;}
            if (!angular.equals(guideCopy, $scope.guide) && $scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var loadIndex = layer.load(1, {shade: 0.3});
            BookingCtns.delete({
                id: bookingCtn.id
            }, function(){
                layer.close(loadIndex);
                loadGuide();
            }, function(result){
                layer.close(loadIndex);
                if(result.status == 409){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.cargoIsOutAlert'), {icon:0});
                }
                else {
                    layer.msg($translate.instant('global.delete_fail'));
                }
            });
        };

        $scope.deliveryWayChange = function() {
            if($scope.guide.deliveryWay == "express" || $scope.guide.shipmentType == "FBAAIR" || $scope.guide.shipmentType == "EXPRESS"){
                if(!$scope.guide.booking.divVolBy){
                    $scope.setChargeMode(false);
                }
            }
            else{
                if($rootScope.ISWMS($scope.guide.shipmentType) && ($scope.guide.deliveryWay == "ltl" || $scope.guide.deliveryWay == "ftl")){
                    if(!$scope.guide.booking.divVolBy){
                        $scope.setChargeMode(false);
                    }
                }
                else {
                    if(!$scope.guide.booking.divVolBy){
                        $scope.setChargeMode(true);
                    }
                }
            }
        };

        $scope._selected = function($item, which) {
            switch (which) {
                case "podChoose":
                    $scope.guide.booking.podId = $item.code;
                    break;
                case "porChoose":
                    $scope.guide.booking.porId = $item.code;
                    if(!$scope.guide.polRegion){
                        $scope.guide.polRegion = $item.region;
                    }
                    break;
                case "destChoose":
                    $scope.guide.booking.destId = $item.code;
                    $scope.guide.podRegion = $item.region;
                    if($item.countryCode){
                        $scope.guide.destCountryCode = $item.countryCode;
                        $rootScope.OWN_IMPORTER_CHECK($item.countryCode, $scope.guide);
                    }
                    break;
                case "deliverytoChoose":
                    //换个方法
                    $scope.guide.booking.deliverytoId = $item.code;
                    AddressBook.getFbaAddressByIdAndType({id: $item.id , type: $item.type}, function(result){
                        DataUtils.onGuideAddressChoosed($scope.guide, result);
                        $rootScope.OWN_IMPORTER_CHECK(result.countryCode,$scope.guide);
                    });
                    break;
                case "polChoose":
                    $scope.guide.booking.polId = $item.code;
                    if(!$scope.guide.polRegion){
                        $scope.guide.polRegion = $item.region;
                    }
                    break;
            }
        }
        var onSaveSuccess = function(result) {
            saved = false;
            $scope.isSaving = false;
            $scope.guide = result;
            guideCopy = angular.copy($scope.guide);
            $scope.$emit('autopsApp:guideUpdate', guideCopy);
            if($scope.guide.otherTimeTwo){
                $scope.cargoIsOut = true;
            }
            else {
                $scope.cargoIsOut = false;
            }
            if($scope.guide.otherTimeOne){
                $scope.cargoIsIn = true;
            }
            else {
                $scope.cargoIsIn = false;
            }
            layer.closeAll();
            if (notDeliveriedAlert) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.newTips'));
            }
            else if (!$scope.guide.etd && $scope.guide.status != "Declined") {
                layer.msg($translate.instant('autopsApp.guide.home.noEtdAlert'));
            }
            else if (DateUtils.getDaysDiff($scope.guide.etd, $scope.guide.eta) < 0) {
                layer.msg($translate.instant('autopsApp.guide.etaLessThanEtd'));
            }
            else {
                layer.msg($translate.instant('global.messages.submitted'));
            }
            document.title = result.jobNum;
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
            if (result.status == 409) {
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {});
            }
            else if (result.status == 403) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.lockedAlert'));
            }
            else if (result.status == 406) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.overdue'));
            }
            else if (result.status == 423) {
                layer.msg($translate.instant('autopsApp.guide.jobNumConflictAlert'));
            }
            else {
                $rootScope.ALERT_ERROR(result);
            }

            $translate.refresh();
        };

        var notDeliveriedAlert = false;
        $scope.save = function() {
            if ($scope.isSaving) {
                return;
            }
            if ($rootScope.HAVENOAUTH("AU_AMEND_SHIPMENT")) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_AMEND_SHIPMENT');
                return;
            }
            if ($scope.guide.isVirtual && !$scope.guide.whId) {
                layer.msg($translate.instant('autopsApp.guide.warehouseNullAlert'));
                return;
            }
            if ($scope.guide.isClosed) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if ($scope.guide.isVoid) {
                layer.msg($translate.instant('global.voidedAlert'));
                return;
            }
            if($scope.guide.companyId == 647 && !$scope.guide.productName){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.productNameRequired'));
                return;
            }

            // if($scope.guide.submited){
                var langName = $rootScope.RQ_CHECK($scope.guide);
                if(langName){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.requiredNullAlert', {param: langName}));
                    return;
                }
            // }

            if($rootScope.isYuyueExHaShen($scope.guide.companyId) && $scope.guide.submited && !yyRequiredCheckOk()){
                return;
            }
            if (!$rootScope.IS_OP_CHECK() && $scope.guide.isLocked) {
                layer.msg($translate.instant('global.opDocAuRequired'));
                return;
            }
            if (angular.equals($scope.guide, guideCopy) && $scope.guide.id) {
                layer.msg($translate.instant('global.no_amendmend'));
                return;
            };
            // if ($rootScope.isZb($rootScope.account.companyId)) {
            //     var isAllOk = true;
            //     var cargoNames = new Set();
            //     angular.forEach($scope.guide.booking.bookingCtns, function(ctn){
            //         if(ctn.cargoName && !ctn.location){
            //             if(cargoNames.has(ctn.cargoName)){
            //                 isAllOk = false;
            //             }
            //             else {
            //                 cargoNames.add(ctn.cargoName);
            //             }
            //         }
            //     })
            //     if(!isAllOk){
            //         layer.msg($translate.instant('autopsApp.booking.cargoNameRepeat'));
            //         return;
            //     }
            // }

            $scope.isSaving = true;
            if (!$scope.guide.submited) {
                notDeliveriedAlert = true;
            } else {
                notDeliveriedAlert = false;
            }
            vendersInit();
            $scope.guide.booking.bookingToName = $rootScope.account.companyName;
            $rootScope.AUTOFULLNODE($scope.guide);
            Guide.update($scope.guide, onSaveSuccess, onSaveError);
        };

        var deliverySuccess = function(result) {
            $scope.isSaving = false;
            $scope.guide = result;
            guideCopy = angular.copy($scope.guide);
            $scope.$emit('autopsApp:guideUpdate', guideCopy);
            layer.closeAll();
            layer.msg($translate.instant('global.messages.submitted'));
            $translate.refresh();
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.opType = 2;
            guideReportDTO.reportType = 20;
            guideReportDTO.fileType = 1;
            OpsReport.generateGuide({}, guideReportDTO);
        };

        var deliveryError = function(result) {
            $scope.isSaving = false;
            if (result.status == 409) {
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {});
            }
            else if (result.status === 403) {
                $scope.guide.status = guideCopy.status;
                layer.msg($translate.instant('global.messages.creditOverLimitFms'));
                $translate.refresh();
            } else {
                $rootScope.ALERT_ERROR(result);
            }
        };

        var yyRequiredGdFd = ["cargoReadyDate", "productName", "cargoType", "pkgNum", "chargeWeight", "shiptoCode", "bookingEtd",
            "vol", "gw", "whName", "salesName", "cusName", "opName", "customerName", "cargoName", "shiptoPostcode", "destCountryCode"];

        var yyRequiredBkFd = ["pkgType", "porName", "polName", "deliveryTo", "bookingToAttn", "bookingToEmail",
            "bookingToFax", "bookingToAddress", "bookingToTel"];

        var getFdName= function(fd){
            var fdName;
            if(fd == "chargeWeight"){
                fdName =  "(" + $translate.instant('autopsApp.booking.' + fd) + ")";
            }
            else if(fd == "shiptoCode"){
                fdName =  "(" + $translate.instant('autopsApp.guide.shipToParty') + ")";
            }
            else if(fd == "whName"){
                fdName =  "(" + $translate.instant('autopsApp.wmsIn.warehouseName') + ")";
            }
            else if(fd == "shiptoPostcode"){
                fdName =  "(" + $translate.instant('autopsApp.guide.destPostCode') + ")";
            }
            else if(fd == "bookingToAttn"){
                fdName =  "(" + $translate.instant('autopsApp.expressParcel.attentionName') + ")";
            }
            else if(fd == "bookingToEmail"){
                fdName =  "(" + $translate.instant('autopsApp.expressParcel.city') + ")";
            }
            else if(fd == "bookingToFax"){
                fdName =  "(" + $translate.instant('autopsApp.expressParcel.province') + ")";
            }
            else if(fd == "bookingToAddress"){
                fdName =  "(" + $translate.instant('autopsApp.expressParcel.addressLine') + "1)";
            }
            else if(fd == "bookingToTel"){
                fdName =  "(" + $translate.instant('global.tel') + ")";
            }
            else {
                fdName =  "(" + $translate.instant('autopsApp.guide.' + fd) + ")";
            }
            return fdName;
        }



        $scope.deliveryGuide = function() {
            if ($scope.isSaving) {
                return;
            }
            if(!$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if ($scope.guide.isVirtual && !$scope.guide.whId) {
                layer.msg($translate.instant('autopsApp.guide.warehouseNullAlert'));
                return;
            }
            var langName = $rootScope.DELIVERY_RQ_CHECK($scope.guide);
            if(langName){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.requiredNullAlert', {param: langName}));
                return;
            }
            vendersInit();
            if($rootScope.isYuyueExHaShen($scope.guide.companyId)){
                if(!yyRequiredCheckOk()){
                    return;
                }
            }
            $scope.guide.status = "Requested";
            if (!$scope.guide.createdBy || !$scope.guide.companyId) {
                $scope.guide.createdBy = $rootScope.account.id;
                $scope.guide.companyId = $rootScope.account.companyId;
            }
            $scope.isSaving = true;
            var pageUrl = window.location.href;
            if(!pageUrl.endsWith("=")){
                pageUrl = pageUrl + "/dialog/" + $scope.guide.encodeId;
            }
            Guide.delivery({pageUrl: pageUrl}, $scope.guide, deliverySuccess, deliveryError);
        }
        var yyRequiredCheckOk = function() {
            for(var i = 0; i < yyRequiredGdFd.length; i++){
                if(!$scope.guide[yyRequiredGdFd[i]]){
                    $("#" + yyRequiredGdFd[i] + "_field").focus();
                    layer.msg($translate.instant('global.requiredNull') + getFdName(yyRequiredGdFd[i]));
                    return false;
                }
            }
            for(var i = 0; i < yyRequiredBkFd.length; i++){
                if(!$scope.guide.booking[yyRequiredBkFd[i]]){
                    $("#" + yyRequiredBkFd[i] + "_field").focus();
                    layer.msg($translate.instant('global.requiredNull') + getFdName(yyRequiredBkFd[i]));
                    return false;
                }
            }
            return true;
        }
        $scope.isSame = function() {
            return angular.equals($scope.guide, guideCopy);
        }

        var vendersInit = function() {
            if ($scope.guide.warehouseVid && !$scope.guide.warehouseName) {
                $scope.guide.warehouseVid = null;
                $scope.guide.warehouseName = null;
            }

            if ($scope.guide.fumigationVid && !$scope.guide.fumigationName) {
                $scope.guide.fumigationVid = null;
                $scope.guide.fumigationName = null;
            }
            if ($scope.guide.insuranceVid && !$scope.guide.insuranceName) {
                $scope.guide.insuranceVid = null;
                $scope.guide.insuranceName = null;
            }
            if ($scope.guide.traderVid && !$scope.guide.traderName) {
                $scope.guide.traderVid = null;
                $scope.guide.traderName = null;
            }
            if ($scope.guide.deliveryId && !$scope.guide.deliveryName) {
                $scope.guide.deliveryId = null;
                $scope.guide.agentUserId = null;
                $scope.guide.deliveryName = null;
                $scope.guide.deliveryEmail = null;
                $scope.guide.deliverySecret = null;
            }
            if ($scope.guide.bookingToId && !$scope.guide.bookingToName) {
                $scope.guide.bookingToId = null;
                $scope.guide.bookingToName = null;
                $scope.guide.bookingToEmail = null;
            }
            if ($scope.guide.shipperId && !$scope.guide.shipperName) {
                $scope.guide.shipperId = null;
                $scope.guide.shipperName = null;
                $scope.guide.shipperUserId = null;
            }
            if (!$scope.guide.shipfromCode) {
                $scope.guide.shipFromId = null;
            }
            if (!$scope.guide.shiptoCode) {
                $scope.guide.shipToId = null;
            }
        }

        $scope.updateSupervisor = function(item) {
            $scope.guide.checkById = item.id;
            $scope.guide.checkByName = item.name;
        };
        $scope.updateOperator = function(item) {
            $scope.guide.opName = item.name;
            $scope.guide.booking.opId = item.id;
            $scope.guide.opId = item.id;
            if(!$scope.guide.bookingBy && item.authorities.indexOf("BOOKING_BY") !== -1) {
                $scope.guide.bookingBy = item.name;
                $scope.guide.bookingById = item.id;
            }
            if(!$scope.guide.truckBy && item.authorities.indexOf("TRUCK_BY") !== -1) {
                $scope.guide.truckBy = item.name;
                $scope.guide.truckById = item.id;
            }
            if(!$scope.guide.customBy && item.authorities.indexOf("CUSTOM_BY") !== -1) {
                $scope.guide.customBy = item.name;
                $scope.guide.customById = item.id;
            }
        };
        $scope.updateDoc = function(item) {
            $scope.guide.docName = item.name;
            $scope.guide.booking.docId = item.id;
            $scope.guide.docId = item.id;
        };
        $scope.updateFin = function(item) {
            $scope.guide.finName = item.name;
            $scope.guide.booking.finId = item.id;
            $scope.guide.finId = item.id;
        };
        $scope.updateSales = function(item) {
            $scope.guide.salesName = item.name;
            $scope.guide.salesId = item.id;
            $scope.guide.team = item.team;
            $scope.guide.branch = item.branch;
        };
        $scope.updateCus = function(item) {
            $scope.guide.cusName = item.name;
            $scope.guide.cusId = item.id;
        };
        $scope.updateOverseasCus = function(item) {
            $scope.guide.overseasCs = item.name;
            $scope.guide.overseasCsId = item.id;
        };
        $scope.updateBookingBy = function(item) {
            $scope.guide.bookingBy = item.name;
            $scope.guide.bookingById = item.id;
        };
        $scope.updateTruckBy = function(item) {
            $scope.guide.truckBy = item.name;
            $scope.guide.truckById = item.id;
        };
        $scope.updateCustomBy = function(item) {
            $scope.guide.customBy = item.name;
            $scope.guide.customById = item.id;
        };

        $scope.addCtn = function() {
            $scope.guide.booking.bookingCtns.push({importCountryCode:$scope.guide.destCountryCode});
            $translate.refresh();
        };
        $scope.previewReport = function(code, truckId) {
            if($rootScope.VIEW_GUIDE_FORBIDDEN(code)){
                return;
            }
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.packingList = guideReportDTO.booking.bookingCtns;
            guideReportDTO.reportType = code;
            guideReportDTO.opType = 1;
            guideReportDTO.fileType = 1;
            if((code >= 350 && code < 360) || (code >= 50 && code < 60)){
                guideReportDTO.ctnMawb = guideReportDTO.sayCtns;
                guideReportDTO.sayCtns = generateSayCtns();
            }
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {

            });
        }
        $scope.exportExcelPdf = function(format, code, fileName, idCopy) {
            if($rootScope.VIEW_GUIDE_FORBIDDEN(code)){
                return;
            }
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.packingList = guideReportDTO.booking.bookingCtns;
            guideReportDTO.reportType = code;
            guideReportDTO.opType = 1;
            guideReportDTO.fileType = format;
            guideReportDTO.fileName = fileName;
            if((code >= 350 && code < 360) || (code >= 50 && code < 60)){
                guideReportDTO.ctnMawb = guideReportDTO.sayCtns;
                guideReportDTO.sayCtns = generateSayCtns();
            }
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                $rootScope.EXPORTDOWNLOAD(result, format, fileName);
            });
        }

        var generateSayCtns = function(isSwitch) {
            if (!$scope.guide.pkgNum) {
                return;
            }
            return "SAY " + DataUtils.translate($scope.guide.pkgNum.toString()) + " (" + $scope.guide.pkgNum + ") " + $scope.guide.booking.pkgType + " ONLY.";
        }

        $scope.updatePkgsAndType = function() {
            if(!$scope.guide.mblConfirmedTime){
                $scope.guide.pkgsAndType = $scope.guide.pkgNum + " " + $scope.guide.booking.pkgType;
            }
            if(!$scope.guide.hblConfirmedTime){
                $scope.guide.booking.pkgsAndType = $scope.guide.pkgNum + " " + $scope.guide.booking.pkgType;
            }
            // if(!$scope.guide.otherTimeOne && !$scope.guide.masterId && !$scope.guide.parcelId){
            //     $scope.guide.booking.pkgNum = $scope.guide.pkgNum;
            // }
        }

        var sendEmailPop = function(entity) {
            $uibModal.open({
                templateUrl: 'app/entities/email/email-dialog.html',
                controller: 'EmailDialogController',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                $scope.guide = result;
                if(!angular.equals(guideCopy, $scope.guide)){
                    $scope.save();
                }
            }, function(result) {});
        }


        var onSendSuccess = function(result) {
            $scope.isSaving = false;
            $scope.guide = result;
            guideCopy = angular.copy($scope.guide);
            $scope.$emit('autopsApp:guideUpdate', guideCopy);
            layer.closeAll();
            layer.msg($translate.instant('global.messages.submitted'));
        };

        /* ctns view */
        $scope.addGCtn = function() {
            $scope.guide.guideCtns.push({
                smsSent: false
            });
            $translate.refresh();
        };

        $scope.deleteGCtn = function(ctn) {
            if($scope.guide.isClosed){return;}
            if (ctn.id != null) {
                GuideCtns.delete({
                    id: ctn.id
                });
            };
            var index = $scope.guide.guideCtns.indexOf(ctn);
            if (index > -1) {
                $scope.guide.guideCtns.splice(index, 1);
            };
            $scope.guideCtnChange();
        };

        $scope.truckRequiredChange = function(required) {
            if (required) {
                if(!$scope.guide.truckBy){
                    $scope.guide.truckById = $scope.guide.opId;
                    $scope.guide.truckBy = $scope.guide.opName;
                }
            }
            else {
                $scope.guide.truckById = null;
                $scope.guide.truckBy = null;
            }
        }
        $scope.customRequiredChange = function(required) {
            if (required && !$scope.guide.customBy) {
                $scope.guide.customById = $scope.guide.opId;
                $scope.guide.customBy = $scope.guide.opName;
            }
        }


        $scope.smSend = function(ctn) {
            var entity = {};
            entity.ctn = ctn;
            entity.guide = $scope.guide;
            $uibModal.open({
                templateUrl: 'app/entities/bookingCtns/bookingCtns-delete-dialog.html',
                controller: 'BookingCtnsDeleteController',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {}, function(result) {});
        }

        $scope.lockAll = function() {
            for (var i = 0; i < $scope.guide.guideCtns.length; i++) {
                $scope.guide.guideCtns[i].locked = true;
            }
        }

        $scope.addressBooks = [];
        $scope.loadAddressBooks = function() {
            if ($scope.addressBooks.length == 0 && !$scope.isSaving) {
                $scope.isSaving = true;
                AddressBook.getAll(function(result) {
                    $scope.addressBooks = result;
                    $scope.isSaving = false;
                });

            };
        };


        $scope.updateGwVol = function() {
            DataUtils.updateGuideCw($scope.guide);
        };

        $scope.priceChange = function() {
            var ttlPrice = 0;
            var ttlPieces = 0;
            angular.forEach($scope.guide.booking.bookingCtns, function(data) {
                if(data.unitPrice != null && data.pieces != null){
                    data.totalPrice = data.unitPrice * data.pieces;
                    data.totalPrice = DataUtils.round(data.totalPrice, 2);
                    ttlPrice = ttlPrice + data.totalPrice;
                }
                if(data.pieces){
                    ttlPieces = ttlPieces + data.pieces;
                }

            })
            $scope.guide.cargoValue = ttlPrice + "USD";
            $scope.guide.pieces = DataUtils.round(ttlPieces, 2);
        };


        $scope.chargeWeightChange = function(chargeWeight) {
            $scope.guide.awbWeight = chargeWeight;
        };

        $scope.guideCtnChange = function() {
            DataUtils.guideCtnChange($scope.guide);
        }

        var onAuthError = function(result) {
            $scope.isSaving = false;
            if (result.status === 404) {
                layer.msg($translate.instant('autopsApp.guide.authErrorAlert'));
            }
            else if (result.status === 409) {
                layer.msg($translate.instant('autopsApp.guide.entrustConflict'));
            }
            else if (result.status === 403) {
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    icon: 3,
                    title: $translate.instant('global.messageInfo'),
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {});
            } else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        };

        $scope.authorizeBranch = function() {
            if (!$scope.guide.bookingToId) {
                layer.msg($translate.instant('autopsApp.guide.authorizeBranchNull'));
                return;
            }
            if(!$scope.guide.submited){
                layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                return;
            }
            var title = $translate.instant('autopsApp.guide.authorizeBranch');

            var content = $scope.guide.bookingToName + $translate.instant('autopsApp.guide.home.auth_send_content');
            layer.confirm(content, {
                title: title,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                if($scope.guide.masterId == 0){
                    var sendSubOrderTip = $translate.instant('autopsApp.guide.home.isSendSubOrder');
                    layer.confirm(sendSubOrderTip, {
                        title: title,
                        btn: [$translate.instant('autopsApp.guide.home.includeSubOrder'), $translate.instant('autopsApp.guide.home.onlyMasterOrder')]
                    }, function(){
                        $scope.isSaving = true;
                        Guide.authorizeBranch({sendSubOrder: true}, $scope.guide, onSendSuccess, onAuthError);
                    }, function(){
                        $scope.isSaving = true;
                        Guide.authorizeBranch({sendSubOrder: false}, $scope.guide, onSendSuccess, onAuthError);
                    })
                }else{
                    $scope.isSaving = true;
                    Guide.authorizeBranch($scope.guide, onSendSuccess, onAuthError);
                }
            }, function() {
                return;
            });
        }
        var onSynSuccess = function(result) {
            $scope.isSaving = false;
            $scope.guide = result;
            layer.msg($translate.instant('autopsApp.guide.synSuccess'));
        };

        var unsubscribeSynGuide = $rootScope.$on('autopsApp:onSynSuccess', function(event, result) {
            if(result.id == $scope.guide.id){
                $scope.guide = result;
                layer.msg($translate.instant('autopsApp.guide.synSuccess'));
            }
        });
        $scope.$on('$destroy', unsubscribeSynGuide);

        var onSynFailed = function(result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('autopsApp.guide.synFailed'));
        };
        $scope.synBranchGuide = function() {
            if($rootScope.HAVENOAUTH("SYN_EDI_DATA")){
                $rootScope.FORBIDDEN_BY_ALERT('SYN_EDI_DATA');
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $scope.isSaving = true;
            Guide.synBranchGuide($scope.guide, onSynSuccess, onSynFailed);
        }

        $scope.deleteGuide = function() {
            layer.confirm($translate.instant('autopsApp.guide.delete.question', {
                id: $scope.guide.jobNum
            }), {
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                Guide.delete({
                    id: $scope.guide.id
                }, function() {
                    layer.close(index);
                    layer.msg($translate.instant('global.delete_success'));
                    goBack();
                }, $rootScope.ALERT_ERROR);
            }, function() {});
        }

        $scope.setVoid = function() {
            if($rootScope.HAVENOAUTH('AU_VOID_ORDER')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_VOID_ORDER');
                return;
            }
            if ($scope.guide.isVoid) {
                Guide.setVoid({id:$scope.guide.id}, function(result) {
                    loadGuide();
                    layer.msg($translate.instant('global.valid_success'));
                }, $rootScope.ALERT_ERROR);
            } else {
                if (!angular.equals(guideCopy, $scope.guide)) {
                    layer.msg($translate.instant('global.saveAlert'));
                    return;
                };
                layer.confirm($translate.instant('autopsApp.guide.delAllRatesAlert'), {
                    icon: 3,
                    title: $translate.instant('global.alert_title'),
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                }, function(index) {
                    Guide.setVoid({id:$scope.guide.id}, function(result) {
                        loadGuide();
                        layer.msg($translate.instant('global.invalid_success'));
                    }, $rootScope.ALERT_ERROR);
                }, function() {
                });
            }
        }
        $scope.setIsClosed = function() {
            if ($scope.guide.isClosed && $rootScope.account.authorities.indexOf("AU_BILL_CLOSE") === -1) {
                $scope.guide.isClosed = !$scope.guide.isClosed;
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_CLOSE');
                return;
            }
            if (!$scope.guide.etd) {
                layer.msg($translate.instant('autopsApp.guide.etdNullAlert'));
                $scope.guide.isClosed = !$scope.guide.isClosed;
                return;
            };
            if (!$scope.guide.isClosed) {
                if ($rootScope.account.authorities.indexOf("AU_BILL_OPEN") === -1) {
                    $scope.guide.isClosed = !$scope.guide.isClosed;
                    $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_OPEN');
                    return;
                }
                $scope.guide.isClosed = false;
                $scope.guide.status = "Restored";
            } else {
                $scope.guide.isClosed = true;
                $scope.guide.status = "isClosed";
            }
            Guide.update($scope.guide, function(result) {
                $scope.guide = result;
                guideCopy = angular.copy($scope.guide);
                $scope.$emit('autopsApp:guideUpdate', guideCopy);
                layer.msg($translate.instant('global.save_success'));
                if(result.masterId == 0){
                    layer.confirm($translate.instant('autopsApp.guide.lockSubGuidesAllTips'), {
                        btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                        }, function(index) {
                            layer.msg($translate.instant('global.isSavingMsg'), {
                              shade: 0.3,
                              time: 200000
                            });
                            Guide.lockSubGuidesAll({
                                masterId: result.id,
                                bValue: result.isClosed,
                                type: "guide"
                            }, function(result) {
                                layer.closeAll();
                                layer.msg($translate.instant('global.save_success'));
                            })
                            layer.close(index);
                        }, function() {
                    });
                }
            }, onSaveError);
        }
        $scope.applyRelease = function() {
            guideCopy.isApplied = !guideCopy.isApplied;
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                $scope.guide.isApplied = !$scope.guide.isApplied;
                return;
            };
            guideCopy.isApplied = !guideCopy.isApplied;

            if (!$scope.guide.submited) {
                layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                $scope.guide.isApplied = !$scope.guide.isApplied;
                return;
            }
            if(!$scope.guide.finId){
                layer.msg($translate.instant('autopsApp.guide.home.finNullAlert'));
                $scope.guide.isApplied = !$scope.guide.isApplied;
                return;
            }
            if ($scope.guide.hblReleasedTime && $scope.guide.isApplied) {
                layer.msg($translate.instant('autopsApp.guide.home.blrelased'));
                $scope.guide.isApplied = !$scope.guide.isApplied;
                return;
            }
            $scope.isSaving = true;
            Guide.applyRelease({
                guideId: $scope.guide.id,
                isApplied: $scope.guide.isApplied
            }, function(result, headers) {
                loadGuide();
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
            });
        }

        $scope.checkPop = function() {
            var guideCheckStatus = $scope.guide.checkStatus;
            $uibModal.open({
                templateUrl: 'app/entities/guideRates/guideRates-dialog.html',
                controller: 'GuideRatesDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return $scope.guide;
                    }]
                }
            }).result.then(function(result) {
                loadGuide();
            }, function(result) {
                $scope.guide.checkStatus = guideCheckStatus;
            });
        };
        $scope.changeMblNum = function() {
            if (!$scope.guide.etdLocked) {
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            layer.prompt({
                formType: 0,
                value: $scope.guide.mblNum,
                title: $translate.instant('autopsApp.guide.changeMblNum')
            }, function(value, index, elem) {
                layer.close(index);
                value = value.replace(/\s+/g, "");
                Guide.amendMblNumByOp({
                    guideId: $scope.guide.id,
                    mblNum: value
                }, function(result) {
                    loadGuide();
                    layer.msg($translate.instant('global.messages.submitted'));
                }, function(result) {
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                });
            });
        }
        $scope.updateVol = function(bookingCtn) {
            if (bookingCtn.length && bookingCtn.width && bookingCtn.height) {
                bookingCtn.width = bookingCtn.width.toFixed(4) - 0;
                bookingCtn.length = bookingCtn.length.toFixed(4) - 0;
                bookingCtn.height = bookingCtn.height.toFixed(4) - 0;
                bookingCtn.vol = bookingCtn.length * bookingCtn.width * bookingCtn.height / 1000000;
                bookingCtn.vol = DataUtils.round(bookingCtn.vol, 5);
                $scope.volChange(bookingCtn);
            }
        };

        $scope.gwChange = function(bookingCtn) {
            if(bookingCtn.pkgNum && bookingCtn.gw){
                var rdGw = bookingCtn.gw;
                if($scope.guide.shipmentType == "EXPRESS"){
                    rdGw = DataUtils.round(bookingCtn.gw, 0);
                    if(rdGw < bookingCtn.gw){
                        rdGw = rdGw + 0.5;
                    }
                }
                bookingCtn.gwTtl = DataUtils.round(rdGw * bookingCtn.pkgNum, 4);
                $scope.updateVolGw();
            }
        };
        $scope.volChange = function(bookingCtn) {
            if(bookingCtn.pkgNum && bookingCtn.vol){
                bookingCtn.volTtl = DataUtils.round(bookingCtn.vol * bookingCtn.pkgNum, 5);
                $scope.updateVolGw();
            }
        };
        $scope.pkgChange = function(bookingCtn) {
            if(bookingCtn.pkgNum){
                if(bookingCtn.vol){
                    bookingCtn.volTtl = DataUtils.round(bookingCtn.vol * bookingCtn.pkgNum, 5);
                }
                if(bookingCtn.gw){
                    bookingCtn.gwTtl = DataUtils.round(bookingCtn.gw * bookingCtn.pkgNum, 4);
                }
                $scope.updateVolGw();
            }
        };

        $scope.updateVolGw = function() {
            DataUtils.updateGuidePl($scope.guide);
        }

        $scope.deliveryNotice = function(type, code) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.shipperName;
            email.subject = $scope.guide.hblNum + "--" + $translate.instant('entity.email.dNoticeSubject');
            email.content = $translate.instant('entity.email.dNoticeContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = code;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.shipperId;
            entity.contactId = $scope.guide.shipperContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }
        $scope.ctnLoading = function(type, code) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.warehouseName;
            email.subject = $scope.guide.mblNum + "--" + $translate.instant('entity.email.ctnLoadingSubject');
            email.content = $translate.instant('entity.email.ctnLoadinContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = code;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.warehouseVid;
            entity.contactId = $scope.guide.warehouseContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }


        $scope.getDeliveryAddress = function() {
            if (!$scope.guide.booking.remarkTwo) {
                var add = "送货时间: " + DateUtils.convertLocalDateToServer($scope.guide.cargoReadyDate) + "\n\n";
                OpsVender.getDTO({id: $scope.guide.warehouseVid}, function(result){
                    if (result.companyName) {
                        add = add + result.companyName + "\n";
                    }
                    if (result.address) {
                        add = add + result.address + "\n";
                    }
                    if(result.contactList && result.contactList.length > 0){
                        angular.forEach(result.contactList, function(contact){
                            if(contact.id == $scope.guide.warehouseContactId){
                                if (contact.attn) {
                                    add = add + "Pic: " + contact.attn + "\n";
                                }
                                if (contact.mob) {
                                    add = add + "Mob: " + contact.mob + "\n";
                                }
                                if (contact.tel) {
                                    add = add + "Tel: " + contact.tel + "\n";
                                }
                                if (contact.fax) {
                                    add = add + "Fax: " + contact.fax + "\n";
                                }
                                if (contact.email) {
                                    add = add + "Email: " + contact.email + "\n";
                                }
                            }
                        })

                    }
                    $scope.guide.booking.remarkTwo = add;
                })
            }
        }


        $scope.getLoadRemark = function() {
            if (!$scope.guide.booking.loadRemark) {
                var add = "预计货到堆场时间：" + DateUtils.convertLocalDateToServer($scope.guide.cargoReadyDate) + "\n\n";
                add = add + "装箱要求：" + "\n";
                add = add + "报箱号时间：" + "\n";
                add = add + "费用：" + "\n";
                $scope.guide.booking.loadRemark = add;
            }
        }

        $scope.checkCtnRepeat = function(ctn) {
            if (!ctn.ctnNum) {
                return;
            }
            angular.forEach($scope.guide.guideCtns, function(data) {
                if (data != ctn && data.ctnNum == ctn.ctnNum) {
                    $rootScope.OPS_ALERT(data.ctnNum + ": " + $translate.instant('autopsApp.guide.home.ctnRepeatedAlert'));
                }
            })
        }

        $rootScope.LOAD_REPORT_TEMPLATES();

        $scope.synCtns = function() {
            Guide.synCtns($scope.guide, function(result) {
                if (result && result.length > 0) {
                    angular.forEach(result, function(data) {
                        $scope.guide.guideCtns.push(data);
                    })
                    layer.msg($translate.instant('autopsApp.guide.synSuccess'));
                } else {
                    layer.msg($translate.instant('global.messages.resultNull'));
                }
            }, function(result) {
                layer.msg($translate.instant('autopsApp.guide.synFailed'));
            });
        };

        $scope.unbind = function() {
            layer.confirm($translate.instant('autopsApp.guide.unbindAlert'), {
                title: $translate.instant('global.messageInfo'),
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                Guide.unbind({
                    guideId: $scope.guide.id,
                    branchGuideId: $scope.guide.branchGuideId
                }, function(result) {
                    $scope.guide.branchGuideId = null;
                    layer.close(index);
                    layer.msg($translate.instant('global.messages.submitted'));
                }, $rootScope.ALERT_ERROR);
            }, function() {});
        }
        $scope.factoriesPop = function() {
            $uibModal.open({
                templateUrl: 'app/entities/opsFactory/opsFactory-dialog.html',
                controller: 'OpsFactoryDialogController',
                size: 'md',
                resolve: {
                    entity: function() {
                        return $scope.guide;
                    }
                }
            }).result.then(function(result) {

            }, function() {

            })
        }
        $scope.importCtns = function(file) {
            if (!file) {
                return;
            }
            var fd = new FormData();
            fd.append('file', file);
            $http.post(
                'api/guides/importCtns', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                data = angular.fromJson(data);
                angular.forEach(data, function(ctn) {
                    $scope.guide.guideCtns.push(ctn);
                })
                $scope.guideCtnChange();
                layer.msg($translate.instant('global.importSuccess'));
            }).error(function(error) {
                if (error.msg) {
                    $rootScope.OPS_ALERT(error.msg);
                } else {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                }

            });
        };

        $scope.amendEtd = function(amendBillDate) {
            if (!$rootScope.IS_OP_CHECK() && $scope.guide.isLocked) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.opDocAuRequired'));
                return;
            }
            if (!$scope.guide.etdLocked && !amendBillDate) {
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var guideDto = {};
            guideDto.id = $scope.guide.id;
            guideDto.etd = $scope.guide.etd;
            if(amendBillDate && $scope.guide.billDate){
                guideDto.etd = $scope.guide.billDate;
            }
            guideDto.lockEDate = true;
            guideDto.amendBillDate = amendBillDate;
            $uibModal.open({
                templateUrl: 'app/entities/guide/amend-etd.html',
                controller: 'AmendEtdController',
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return guideDto;
                    }]
                }
            }).result.then(function(result) {
                loadGuide();
                $translate.refresh();
            }, function() {});
        }

        KEYBOARD = "fbaGuideDialog";
        var saved = false;
        $(document).keydown(function(event) {
            if( KEYBOARD != "fbaGuideDialog"){
                return;
            }
            else if (event.keyCode == 83 && event.ctrlKey) {
                event.preventDefault();
                if (saved){
                    return;
                }
                else {
                    saved = true;
                    $scope.save();
                }
            }
        });
        $scope.$on('modal.closing', function(event, reason, closed){
            if(reason == "escape key press"){
                event.preventDefault(); //You can use this to prevent the modal from closing
                $scope.goBack();
            }
        });
        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }
        $scope.excelExport = function() {
            var guides = [];
            guides.push($scope.guide);
            var fileName = $translate.instant('autopsApp.guide.packingList') + ".xlsx";
            OpsFiles.exportPackingList({
                enCn: $translate.instant('global.enCn')
            }, guides, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }

        $scope.importPlByExcel = function (file) {
            if (!file) {return;}
            if (!$scope.guide.id || !angular.equals($scope.guide, guideCopy)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if($scope.guide.isClosed){
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            var fd = new FormData();
            fd.append('file', file);
            fd.append('guideId', $scope.guide.id);
            $http.post(
                'api/bookingCtnss/importByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    loadGuide(true);
                    layer.msg($translate.instant('global.importSuccess'));
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                });
        };

        $scope.refreshGwVolPkgs = function() {
            if($scope.guide.dontSynTtl){
                return;
            }
            if($rootScope.account.authorities.indexOf('WMS') === -1){
                layer.msg($translate.instant('global.forbiddenWMS'));
                return;
            }
            if(!$scope.guide.otherTimeOne){
                layer.msg($translate.instant('autopsApp.booking.wmsNotInAlert'));
                return;
            }
            Guide.refreshGwVolPkgs($scope.guide, function(result){
                $scope.guide = result;
                $scope.updateVolGw();
                layer.msg($translate.instant('autopsApp.guide.synSuccess'));
            })
        }


        $scope.wmswarehouses = null;
        $scope.getWarehouses = function() {
            if ($scope.wmswarehouses){
                return;
            }
            if ($scope.guide.isVirtual
                        && (!$scope.guide.medCompanyId || $scope.guide.medCompanyId == $rootScope.account.companyId)){
                WmsWarehouse.getAll({
                    includeOwn: false,
                    includeVirtual: true,
                    includeGroup: false,
                    shipmentType: $scope.guide.shipmentType
                }, function (data) {
                    $scope.wmswarehouses = data;
                });
            }
            else {
                WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: true,
                    includeGroup: true,
                    shipmentType: $scope.guide.shipmentType
                }, function (data) {
                    $scope.wmswarehouses = data;
                });
            }
        }

        $scope.wmsWarehouseSelected = function(warehouse) {
            var noWmsCargo = true;
            angular.forEach($scope.guide.booking.bookingCtns, function(cargo){
                if(cargo.wmsCargoId){
                    noWmsCargo = false;
                }
            })
            if(!noWmsCargo){
                $scope.guide.whName = guideCopy.whName;
                layer.msg($translate.instant('autopsApp.guide.clearWmsCargoAlert'));
                return;
            }
            $scope.guide.whName = warehouse.name;
            $scope.guide.whId = warehouse.id;
            // if(warehouse.companyId){
            //     $scope.guide.companyId = warehouse.companyId;
            //     $scope.guide.medCompanyId = warehouse.medCompanyId;
            //     $scope.guide.isVirtual = warehouse.isVirtual;
            //     $scope.guide.medCompany = warehouse.medCompany;
            //     $scope.guide.medVenderId = warehouse.medVenderId;
            // }
            if($scope.guide.deliveryChannel){
                Guide.checkChannel($scope.guide, function(){
                }, function(){
                    layer.confirm($translate.instant('global.invalidChannelAlert'), {
                        title: "Msg",
                        btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                    }, function(index) {
                        layer.close(index);
                        $scope.guide.deliveryChannel = null;
                        $translate.refresh();
                    }, function() {
                        return;
                    });
                })
            }

        }

        $scope.getCargo = function() {
            if($scope.guide.masterId == 0){
                layer.msg($translate.instant('autopsApp.guide.masterGetCargoAlert'));
                return;
            }
            if (!$scope.guide.id || !angular.equals($scope.guide, guideCopy)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            var entt = {};
            entt.guide = angular.copy($scope.guide);
            $uibModal.open({
                templateUrl: 'app/entities/express-cargo/express-cargo-dialog.html',
                controller: 'ExpressCargoDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entt;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('wmsCargo');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                loadGuide();
            }, function() {
            });
        }

        $scope.ratesPop = function() {
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $rootScope.GUIDE_RATES_POP($scope.guide);
        }

        $scope.setActiveTab = function (index) {
            if(index == 1){
                $scope.activeTab = activeTabCopy;
            }
            else {
                activeTabCopy = index;
            }
        }

        $scope.isInvalid = function () {
            if($rootScope.account.companyId == 152 || $rootScope.account.companyId == 153 || $rootScope.account.companyId == 162){
                return false;
            }
            if(!$scope.guide
                || !$scope.guide.submited
                || $scope.guide.isVoid){
                return true;
            }
            if($rootScope.account.mustAudit && $scope.guide.checkStatus != 'approved'){
                return true;
            }
            return false;
        }

        $scope.loadByJobNum = function() {
            $scope.isSaving = true;
            Guide.getGuideByJobNumAndType({
                companyId: $scope.guide.companyId,
                jobNum: $scope.jobNumCopy,
                type: $scope.guide.shipmentType,
                isExport: $scope.guide.isExport
            }, function(result) {
                $scope.guide = result;
                initGuide();
                $scope.isSaving = false;
                layer.msg($translate.instant('entity.action.loadSuccess'));
                $scope.files = [];
                $scope.clientFiles = [];
            }, function(result){
                layer.msg($translate.instant('global.loadFailed'));
            })
        }

        $scope.onTrackingCarrierSelected = function(carrier) {
            $scope.guide.booking.carrierCode = carrier.code;
            $scope.guide.booking.carrierFullName = carrier.name;
        }

        $scope.wmsLabels = function(reportType, fileType) {
            if(!$scope.guide.customerId){
                layer.msg($translate.instant('autopsApp.guide.clientNotChoosedAlert'));
                return;
            }
            if($scope.guide.isVoid){
                layer.msg($translate.instant('autopsApp.guide.home.voidedAlert'));
                return;
            }
            var wmsIn = {};
            wmsIn.guideId = $scope.guide.id;
            wmsIn.companyId = $scope.guide.companyId;
            WmsIn.wmsLabels({
                reportType: reportType,
                fileType: fileType
            }, wmsIn, function(result) {
                $rootScope.PDFPRINTEXCELDOWNLOAD(result, fileType, $scope.guide.jobNum + "_labels");
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        $scope.printIn = function(reportType, fileType){
            // if($rootScope.account.authorities.indexOf('WMS') === -1){
            //     layer.msg($translate.instant('global.forbiddenWMS'));
            //     return;
            // }
            if(!$scope.guide.customerId){
                layer.msg($translate.instant('autopsApp.guide.clientNotChoosedAlert'));
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var wmsIn = {isOut:false};
            wmsIn.guideId = $scope.guide.id;
            wmsIn.fileType = fileType;

            OpsFiles.generateWmsInPaper({reportType: reportType}, wmsIn, function(result){
                $rootScope.PDFPRINTEXCELDOWNLOAD(result, fileType, $scope.guide.jobNum + "_warehouse_paper");
            },function(error){
                layer.msg($translate.instant('autopsApp.wmsIn.viewWmsInPaperFailed'));
            });
        }

        $scope.antiTempRegistIn = function(bCtn) {
            // if($rootScope.account.authorities.indexOf('WMS') === -1){
            //     layer.msg($translate.instant('global.forbiddenWMS'));
            //     return;
            // }

            if($rootScope.HAVENOAUTH('AU_WMS_ANTI') && $rootScope.account.authorities.indexOf('WMS') !== -1){
                $rootScope.FORBIDDEN_BY_ALERT('AU_WMS_ANTI');
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var wmsGuide;
            if(bCtn){
                wmsGuide = {};
                wmsGuide.id = $scope.guide.id;
                wmsGuide.booking = {};
                wmsGuide.booking.bookingCtns = [];
                wmsGuide.booking.bookingCtns.push(bCtn);
            }
            else {
                wmsGuide = angular.copy($scope.guide);
            }
            layer.confirm($translate.instant('autopsApp.wmsIn.antiInAlert'), {
                icon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    layer.close(index);
                    WmsIn.antiTempRegistIn(wmsGuide, function(){
                        layer.msg($translate.instant('global.messages.submitted'));
                        loadGuide();
                    }, function(result){
                        loadGuide();
                        if(result.status == 403){
                            $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.cargoIsOutAlert'));
                        }
                        else {
                            layer.msg($translate.instant('global.messages.submittedfailed'));
                        }
                    });
                }, function() {
            });
        }

        $scope.saveTempRegistIn = function() {
            if ($scope.guide.isVoid) {
                layer.msg($translate.instant('global.voidedAlert'));
                return;
            }
            if($rootScope.account.authorities.indexOf('WMS') === -1){
                layer.msg($translate.instant('global.forbiddenWMS'));
                return;
            }
            if(!$scope.guide.customerId || !$scope.guide.whId){
                layer.msg($translate.instant('autopsApp.wmsIn.chooseClientAndWarehouseTips'));
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-register.html',
                controller: 'WmsInRegisterController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: ['WmsIn', function(WmsIn) {
                        return WmsIn.getWmsInByGuide({isOut: false}, angular.copy($scope.guide)).$promise;
                    }]
                }
            }).result.then(function() {
                loadGuide();
            }, function() {
                loadGuide();
            });
        }

        $scope.generateCharges = function () {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var allDutyAudited = true;
            angular.forEach($scope.guide.booking.bookingCtns, function(data){
                if(data.mixedPls && data.mixedPls.length > 0){
                    angular.forEach(data.mixedPls, function(pl){
                        if(!pl.dutyAudited){
                            allDutyAudited = false;
                        }
                    })
                }
                else if(!data.dutyAudited){
                    allDutyAudited = false;
                }
            })
            if(!allDutyAudited){
                layer.msg($translate.instant('autopsApp.guide.dutyNotAudited'));
                return;
            }
            layer.confirm($translate.instant('autopsApp.booking.bookingCtns.generateChargesTips'), {
                icon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    Guide.generateDutyCharges(angular.copy($scope.guide), function(result){
                        $scope.ratesPop();
                        layer.msg($translate.instant('global.save_success'));
                    }, function(result){
                        if(result.status == 304){
                            layer.msg($translate.instant('global.noRecord'));
                        }
                        else {
                            layer.msg($translate.instant('global.messages.submittedfailed'));
                        }
                    })
                    layer.close(index);
                }, function() {
            });
        }

        $scope.isFba = function() {
            if(!$scope.guide || !$scope.guide.booking){
                return false;
            }
            if( $scope.guide.shipmentType == 'EXPRESS'
                || $scope.guide.shipmentType == 'FBASEA'
                || $scope.guide.shipmentType == 'FBARAIL'
                || $scope.guide.shipmentType == 'FBAAIR'){
                return true;
            }
            return false;
        }
        $scope.plConfirmedChange = function() {
            if($rootScope.HAVENOAUTH('AU_AMEND_TIMESTAMP') && $scope.guide.plConfirmTime){
                $rootScope.FORBIDDEN_BY_ALERT('AU_AMEND_TIMESTAMP');
                $scope.guide.plConfirmed  = true;
                return;
            }

            if(!$scope.guide.otherTimeTwo && !$scope.guide.otherTimeOne){
                $scope.guide.plConfirmed = !$scope.guide.plConfirmed;
                layer.msg($translate.instant('autopsApp.booking.wmsNotInAlert'));
                return;
            }
            if ($scope.guide.plConfirmed) {
                $scope.guide.plConfirmTime = new Date();
                // if($rootScope.HAVEAUTH('WMS')){
                //     $scope.refreshGwVolPkgs();
                // }
                $scope.guide.status = "plConfirmed";
            }
            else {
                $scope.guide.plConfirmTime = null;
                $scope.guide.status = guideCopy.status;
            }
        }
        $scope.updateNode = function(status, fd) {
            if("plConfirmed" == status && $rootScope.isYuyueExHaShen($scope.guide.companyId) && $rootScope.IS_AUTH_TO($scope.guide)){
                layer.msg("委托订单不能修改该节点");
                return;
            }
            if(!$rootScope.IS_OP_CHECK()){
                layer.msg($translate.instant('autopsApp.guide.opDocEnterAlert'));
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if(status == "status"){
                if ($scope.guide.isClosed && $rootScope.account.authorities.indexOf("AU_BILL_OPEN") === -1) {
                    $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_OPEN');
                    return;
                }
                if (!$scope.guide.isClosed && $rootScope.account.authorities.indexOf("AU_BILL_CLOSE") === -1) {
                    $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_CLOSE');
                    return;
                }
            }
            if($scope.guide[fd] || $scope.guide.booking[fd]){
                if($rootScope.HAVENOAUTH('AU_AMEND_TIMESTAMP') && status != 'Cancelled'){
                    $rootScope.FORBIDDEN_BY_ALERT('AU_AMEND_TIMESTAMP');
                    return;
                }
                layer.confirm($translate.instant('autopsApp.guide.clearNodeAlert'), {icon: 3,
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                    }, function(index) {
                        Guide.setStatusOne({
                            guideId: $scope.guide.id,
                            status: status
                        }, function(result) {
                            layer.msg($translate.instant('global.save_success'));
                            loadGuide();
                        }, function(result) {
                            layer.closeAll();
                            $scope.isSaving = false;
                            $rootScope.ALERT_ERROR(result);
                        });
                        layer.close(index);
                    }, function() {
                });
                return;
            }
            var entity = {};
            entity.guide = angular.copy($scope.guide);
            entity.status = status;
            $uibModal.open({
                templateUrl: 'app/entities/ops-track-status/ops-track-status-detail.html',
                controller: 'OpsTrackStatusDetailController',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                layer.msg($translate.instant('global.save_success'));
                loadGuide();
            }, function() {
            });
        }

        $scope.saSend = function(type, venderId) {
            var entity = {};
            entity.reportTemplates = $rootScope.REPORT_TEMPLATES;
            entity.shipmentType = $scope.guide.shipmentType;
            $uibModal.open({
                templateUrl: 'app/entities/reportTemplate/reportTemplate-choose.html',
                controller: 'ReportTemplateChooseController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reportTemplate');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                var reportTypeArray = new Array();
                reportTypeArray.push(result.hblCode);
                reportTypeArray.push(result.saCode);
                saSendEmailPop(type, venderId, reportTypeArray, result.cnCode);
            }, function(result) {

            });
        }

        var saSendEmailPop = function(type, venderId, reportTypeArray, cnCode) {
            var email = {};
            email.attachments = [];

            email.head = $scope.guide.deliveryName;
            email.subject = "HBL#: " + $scope.guide.hblNum + "/ MBL#: " + $scope.guide.mblNum + "--" + $translate.instant('entity.email.saSendSubject');

            var content = "TO: " + $scope.guide.deliveryName + "\n";
            var strs = [];
            if ($scope.guide.booking.shipperBl) {
                strs = $scope.guide.booking.shipperBl.split("\n");
                if (strs[0]) {
                    content += "SHIPPER: " + strs[0] + "\n";
                }
            }
            if ($scope.guide.booking.cneeBl) {
                strs = $scope.guide.booking.cneeBl.split("\n");
                if (strs[0]) {
                    content += "CNEE: " + strs[0] + "\n\n";
                }
            }

            content += "HBL#: " + $scope.guide.hblNum + "---" + $scope.guide.booking.freightTerm + "---" + $scope.guide.hblRlsWay + "\n";
            content += "MBL#: " + $scope.guide.mblNum + "---" + $scope.guide.freightTerm + "---" + $scope.guide.releaseWay + "\n\n";

            content += "Port of Loading#: " + $scope.guide.booking.polName + "\n";
            content += "Port of Discharge#: " + $scope.guide.booking.podName + "\n";
            content += "Port of Dest#: " + $scope.guide.booking.destName + "\n";
            if($scope.guide.booking.deliveryTo){
                content += "Place of Delivery#: " + $scope.guide.booking.deliveryTo + "\n\n";
            }

            content += "\nVsl/ Voy: " + $scope.guide.vsl + "/ " + $scope.guide.voy + "\n";
            content += "ETD/ ETA: " + DateUtils.convertLocalDateToServer($scope.guide.etd) + "/ " + DateUtils.convertLocalDateToServer($scope.guide.eta) + "\n\n";

            content += "VOLUME: " + $scope.guide.volumes + "\n";
            var ctnStr = "";
            angular.forEach($scope.guide.guideCtns, function(gctn){
                ctnStr += gctn.ctnNum + "/ " + gctn.ctnType + "; ";
            })
            if(ctnStr.length > 0){
                content += "CNTR NO.: " + ctnStr + "\n";
            }
            content += "COMMODITY: \n" + $scope.guide.booking.goodsDiscription + "\n\n";
            content += "IMPORTANT REMAINDER: \nCargo can be released ONLY AGAINST,\n1,EIGHTER ORIGINAL HOUSE BL COLLECT BACK FROM CONSIGNEE;\n";
            content += "2.OR RECEIVING FORMAL TELEX RELEASE NOTICE FROM OUR AUTHORIZED TR CONTROLLER. \n";
            content += "YOU & YOUR COMPANY will take up WHOLE LIABILITIES if releasing cargo WRONG.\n\n";

            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = content + $rootScope.account.signatures[0].signature;
            }
            else {
                email.content = content;
            }

            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = reportTypeArray;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.deliveryId;
            entity.contactId = $scope.guide.deliveryContactId;
            entity.saVenderId = venderId;
            entity.saCnCode = cnCode;
            entity.fileResult = null;
            sendEmailPop(entity);
        }
        $scope.saSendOnline = function(incSubshipments) {
            if ($scope.guide.refGuideId) {
                layer.msg($translate.instant('autopsApp.guide.isSecond'));
                return;
            }
            if (!$scope.guide.deliveryId) {
                layer.msg($translate.instant('autopsApp.guide.home.choose_agent'));
                return;
            };
            if(!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var title = $translate.instant('autopsApp.guide.home.sa_send_title');
            var content = $scope.guide.deliveryName + $translate.instant('autopsApp.guide.home.sa_send_content');
            layer.confirm(content, {
                title: title,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                layer.load(1, { shade: 0.3 });
                Guide.createImport({
                    incSubshipments: incSubshipments
                }, $scope.guide, function(){
                    loadGuide();
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submitted'));
                }, $rootScope.ALERT_ERROR);
            }, function() {
                return;
            });
        }
        var onSendError = function(result) {
            $scope.isSaving = false;
            if (result.status === 404) {
                layer.msg($translate.instant('autopsApp.guide.authErrorAlert'));
            } else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        };
        $scope.setChargeMode = function(chargeMode) {
            $scope.guide.chargeByCbm = chargeMode;
            if(chargeMode){
                $scope.guide.booking.divVolBy = 1000;
            }
            else {
                $scope.guide.booking.divVolBy = 6000;
            }
            $scope.updateGwVol();
        }
        $scope.changeExImport = function(guide) {
            if(guide.isClosed || guide.etdLocked){
                layer.msg("This shipment was closed or locked.");
                return;
            }
            var alertContent = guide.isExport?$translate.instant('autopsApp.guide.home.isImport'): $translate.instant('autopsApp.guide.home.isExport');
            alertContent = $translate.instant('autopsApp.guide.changeExImportAlert', {param: alertContent});
            layer.confirm(alertContent, {
                icon: 0,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                Guide.changeExImport({
                    id: guide.id
                }, function(result){
                    guide.isExport = result.isExport;
                    layer.msg($translate.instant('global.save_success'));
                })
            }, function() {
            });
        }

        $scope._onAddressSelected = function(data, fd) {
            if(fd == "labelBindType"){
                $rootScope.PARCEL_SERVICES  = null;
                $scope.guide.booking.labelBindType = data.bindType;
                $scope.guide.shipFromId = data.id;
                $scope.guide.shipfromCode = data.code;
                $scope.guide.serviceDescription = "";
                $scope.guide.serviceType = "";
                $scope.guide.labelChannelCode = null;
                if(data.bindType == "UPS" || data.bindType == "DHL"
                    || data.bindType == "FEDEX" || data.bindType == "DPD" || data.bindType == "EUB"){
                    $scope.guide.booking.carrierFullName = data.bindType;
                }
            }
            else {
                $scope.guide[fd] = data.id;
                if(fd == "shipToId"){
                    $scope.guide.shiptoPostcode = data.postCode;
                    $scope.guide.destCountryCode = data.countryCode;
                    $scope.guide.booking.bookingToAttn = data.attn;
                    $scope.guide.booking.hblNum = data.companyName;
                    $scope.guide.booking.bookingToTel = data.tel;
                    $scope.guide.booking.bookingToAddress = data.addressOne;
                    $scope.guide.booking.bookingToMob = data.addressTwo;
                    $scope.guide.booking.bookingToFax = data.province;
                    $scope.guide.booking.bookingToEmail = data.city;
                    $scope.guide.isResidential = data.isResidential;
                    $rootScope.OWN_IMPORTER_CHECK(data.countryCode, $scope.guide);
                }
            }
        }

        $scope.shipServiceSelected = function(data) {
            $scope.guide.serviceType = data.type;
        }

        $scope.labelAvailable = function() {
            if($scope.guide && $scope.guide.shipFromId && $scope.guide.booking.labelBindType){
                return true;
            }
            return false;
        }
        $scope.bindedAccounts = [];
        $scope.getAllAccounts = function() {
            if($rootScope.account.companyId && $rootScope.HAVENOAUTH('AU_LABEL_ACCOUNT')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_LABEL_ACCOUNT');
                return;
            }
            if ($scope.bindedAccounts.length == 0 && !$scope.isSaving) {
                $scope.isSaving = true;
                AddressBook.getAllAccounts(function(result) {
                    $scope.bindedAccounts = result;
                    $scope.isSaving = false;
                });

            };
        };
        $scope.getGuideLabel = function(guide){
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if (!$scope.guide.submited) {
                layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                return;
            };
            if($rootScope.isYuyue(guide.companyId) && guide.shiptoCode != guide.booking.deliverytoId){
                $rootScope.OPS_ALERT("送货地代码和收件人代码不一致");
                return;
            }
            layer.msg("Generating label...", {
              shade: 0.3,
              time: 200000
            });
            $scope.isSaving = true;
            Guide.getGuideLabel(angular.copy($scope.guide) , function(result, headers) {
                $rootScope.ALERT_RESULT_MSG(headers);
                layer.closeAll();
                $scope.isSaving = false;
                $rootScope.PDFPOP(result.labelUrl, "label");
                loadGuide();
            }, function(error){
                layer.closeAll();
                $scope.isSaving = false;
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                }
                else{
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                }
            });
        }
        $scope.importHGuides = function(file) {
            if (!file) {
                return;
            }
            var fd = new FormData();
            fd.append('guideId', $scope.guide.id);
            fd.append('isExport', true);
            fd.append('file', file);
            $http.post(
                'api/guides/importExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                loadGuide();
                layer.msg($translate.instant('global.importSuccess'));
            }).error(function(error) {
                if (error.msg) {
                    $rootScope.OPS_ALERT(error.msg);
                } else {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                }

            });
        };
        var updateUnsubscribe = $rootScope.$on('autopsApp:isRemoteConfirmed', function(event, result) {
            if(!result){
                $scope.guide.isRemote = false;
            }
            if(result.postcode == $scope.guide.shiptoPostcode && result.country == $scope.guide.destCountryCode ){
                $scope.guide.isRemote = true;
            }
        });
        $scope.$on('$destroy', updateUnsubscribe);

        $scope.ctnselected = function ($item, ctn){
            ctn.truckNum = $item.truckNum;
            ctn.driverMob = $item.driverMob;
            ctn.driverName = $item.driverName;
            if($scope.guide.shipmentType.indexOf('LOCAL') != -1){
                ctn.ctnType = $item.ctnType;
                ctn.ctnNum = $item.ctnNum;
                ctn.remarks = $item.remarks;
            }
        }

        $scope.sellingRequest = function() {
            if ($scope.guide.isClosed || $scope.guide.ratesLocked || $scope.guide.masterId || $scope.guide.parcelId) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var entity = angular.copy($scope.guide.booking);
            entity.shipmentType = $scope.guide.shipmentType;
            entity.isNew = false;
            $uibModal.open({
                templateUrl: 'app/entities/ops-cost/ops-cost-detail.html',
                controller: 'OpsCostDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsCost');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                $scope.updateRateByCost(result);
            }, function() {
            });
        }

        $scope.guideSendPod = function(type) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.shipperName;
            var subjectNum = $scope.guide.booking.bookingNum;
            if (!subjectNum) {
                if ($scope.guide.soNum) {
                    subjectNum = $scope.guide.soNum;
                } else {
                    subjectNum = $scope.guide.jobNum;
                }
            }
            email.subject = subjectNum + "-- POD";
            email.content = "Attached POD copy of " +subjectNum + "\n\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = null;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.shipperId;
            entity.contactId = $scope.guide.shipperContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        $scope.getGuideLabelRates = function(){
            if($scope.guide.booking.labelBindType == "ANMEI"){
                $rootScope.OPS_ALERT($translate.instant('global.serviceNA'));
                return;
            }
            layer.load(1, {shade: 0.3});
            ExpressParcel.getGuideLabelRates($scope.guide, function(result){
                layer.closeAll();
                ratesResultPop(result);
            }, function(error){
                layer.closeAll();
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                }
                ratesResultPop({});
            });
        }
        function ratesResultPop(result){
            var entity = {};
            entity.rateResult = result;
            entity.guide = angular.copy($scope.guide);
            $uibModal.open({
                templateUrl: 'app/entities/express-cargo/express-cargo-delete-dialog.html',
                controller: 'ExpressCargoDeleteController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                if($scope.guide.labelUrl || $scope.guide.mblNum){
                    $rootScope.OPS_ALERT("Label generated!");
                    return;
                }
                // $scope.guide.carrier = result.channel;
                if($rootScope.OPSPORTS.expressLiners){
                    angular.forEach($rootScope.OPSPORTS.expressLiners, function(carrier){
                        if(carrier.name.indexOf("UPS") != -1 || $scope.guide.carrier.indexOf("UPS") != -1 ){
                            $scope.guide.booking.carrierCode = carrier.code;
                        }
                        if(carrier.name.indexOf("DHL") != -1 || $scope.guide.carrier.indexOf("DHL") != -1 ){
                            $scope.guide.booking.carrierCode = carrier.code;
                        }
                        if(carrier.name.indexOf("FedEx") != -1 || $scope.guide.carrier.indexOf("FedEx") != -1 ){
                            $scope.guide.booking.carrierCode = carrier.code;
                        }
                    })
                }
                $scope.guide.amsNum = result.labelRefNum;
                $scope.guide.serviceType = result.serviceCode;
                $scope.guide.serviceDescription = result.service;
                $scope.guide.shipFromId = result.addressId;
                $scope.guide.deliveryChannel = result.channel;
                $scope.guide.booking.labelBindType = result.labelBindType;
                $scope.guide.shipfromCode = result.addressCode;
                $scope.guide.booking.labelChannelCode = result.labelChannelCode;
                if(result.docStamp){
                    $scope.guide.docStamp = result.docStamp;
                }
            })
        }
        $scope.isWmsLocal = function() {
            if(!$scope.guide || !$scope.guide.booking){
                return false;
            }
            if($scope.guide.shipmentType == 'WMS'
                || $scope.guide.shipmentType == 'LOCALKG'
                || $scope.guide.shipmentType == 'LOCAL'){
                return true;
            }
            return false;
        }

        $scope.customsPop = function() {
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $rootScope.customsMgmt($scope.guide);
        };

        var unsubscribeGuide = $rootScope.$on('autopsApp:loadGuide', function(event, result) {
            if(result.id == $scope.guide.id){
                loadGuide();
            }
        });
        $scope.$on('$destroy', unsubscribeGuide);


        $scope.setAutoSendPlCheck = function() {
            $scope.guide.autoSendPlCheck = !$scope.guide.autoSendPlCheck;
            $scope.save();
        }
        $scope.fmsRapidIn = function() {
            if (!$scope.guide.id || !angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $rootScope.FMS_RAPID_IN($scope.guide.shipmentType, $scope.guide.id);
        }

        $scope.clearLabelPop = function() {
            if(!$rootScope.account.companyId || !$scope.guide.labelUrl){return;}
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var alertContent = $translate.instant('autopsApp.expressParcel.clearLabelAlert');
            if($scope.guide.booking.labelBindType == "UPS"){
                alertContent = $translate.instant('autopsApp.expressParcel.clearUpsLabelAlert');
            }
            layer.confirm(alertContent, {
                icon: 0,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                Guide.voidLabel($scope.guide, function(result){
                    layer.msg($translate.instant('global.messages.submitted'));
                    loadGuide();
                    $scope.files = [];
                }, function(error){
                    if(error && error.data && error.data.description){
                        $rootScope.OPS_ALERT(error.data.description);
                    }
                    else {
                        layer.msg($translate.instant('global.messages.submittedfailed'));
                    }
                    loadGuide();
                    $scope.files = [];
                });
            }, function() {
                return;
            });
        }

        $scope.addressCheck = function() {
            if(angular.uppercase($scope.guide.destCountryCode) != "US"){
                layer.msg($translate.instant('autopsApp.expressParcel.notUsAlert'));
                return;
            }
            ExpressParcel.addressCheckGuide($scope.guide, function(result){
                $scope.guide.isResidential = result.guide.isResidential;
                $scope.guide.shiptoPostcode = result.guide.shiptoPostcode;
                $scope.guide.booking.bookingToAddress = result.guide.booking.bookingToAddress;
                $scope.guide.booking.bookingToMob = result.guide.booking.bookingToMob;
                $scope.guide.booking.bookingToFax = result.guide.booking.bookingToFax;
                $scope.guide.booking.bookingToEmail = result.guide.booking.bookingToEmail;
                $scope.guide.onHold = result.guide.onHold;
                $scope.guide.isRemote = result.guide.isRemote;
                $scope.guide.bookingMemo = result.guide.bookingMemo;
                if(result.codeType && result.codeType.description){
                    $rootScope.OPS_ALERT(result.codeType.description);
                }
                else {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.expressParcel.addressCheckResult', {
                        isResidential: $scope.guide.isResidential?$translate.instant('global.yes_answer'):$translate.instant('global.no_answer'),
                        isRemote: $scope.guide.isRemote?$translate.instant('global.yes_answer'):$translate.instant('global.no_answer')
                    }));
                }
            }, function(error){
                layer.msg($translate.instant('global.requestFail'));
            })
        }
        $scope.getCityState = function() {
            if(angular.uppercase($scope.guide.destCountryCode) != "US"){
                return;
            }
            if($scope.guide.booking.bookingToFax && $scope.guide.booking.bookingToEmail){
                return;
            }
            if(!$scope.guide.shiptoPostcode || $scope.guide.shiptoPostcode.length < 5){
                return;
            }
            ExpressParcel.getFbaCityState($scope.guide, function(result){
                if(result.shiptoCity){
                    $scope.guide.booking.bookingToEmail = result.shiptoCity;
                }
                if(result.shiptoProvinceCode){
                    $scope.guide.booking.bookingToFax = result.shiptoProvinceCode;
                }
            }, function(error){
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                }
                else {
                    layer.msg($translate.instant('global.requestFail'));
                }
            })
        }

        $scope.getZipCode = function() {
            if(angular.uppercase($scope.guide.destCountryCode) != "US"){
                return;
            }
            if(!$scope.guide.booking.bookingToFax || !$scope.guide.booking.bookingToEmail || $scope.guide.shiptoPostcode){
                return;
            }
            ExpressParcel.getFbaZipCode($scope.guide, function(result){
                if(result.shiptoPostalCode){
                    $scope.guide.shiptoPostcode = result.shiptoPostalCode;
                }
            }, function(error){
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                }
                else {
                    layer.msg($translate.instant('global.requestFail'));
                }
            })
        }
        $scope.hblView = function() {
            var entity = {};
            entity.guide = $scope.guide;
            $uibModal.open({
                templateUrl: 'app/entities/booking/booking-dialog.html',
                controller: 'BookingDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                getHblsCount();
            }, function(result) {
                getHblsCount();
            });
        };
        function getHblsCount () {
            Booking.getHblsCount({
                guideId: $scope.guide.id
            }, function(result, headers) {
                $scope.hbls = result.hbls;
            });
        }
        $scope.guideSendBooking = function(type, code) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.bookingToName;
            email.subject = $scope.guide.polName + " - " + $scope.guide.destName + "(" + $scope.guide.volumes + "By " + $scope.guide.carrier + "; ETD: "+ DateUtils.convertLocalDateToServer($scope.guide.etd) + ")--" + $translate.instant('entity.email.bookingSubject');
            email.content = $translate.instant('entity.email.bookingContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = code;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.bookingToId;
            entity.contactId = $scope.guide.bookingToContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }
        $scope.getGuideLabelApiRequestBody = function (type){
            layer.load(1, {shade: 0.3});
            ExpressParcel.getGuideLabelApiRequestBody({
                type: type
            }, $scope.guide, function(result){
                layer.closeAll();
                $rootScope.DOWNLOAD_TXT(result.value, $scope.guide.jobNum + "_" + type + ".txt");
            }, function(error){
                layer.closeAll();
            });
        }
        $scope.accountBinds = [];
        $scope.getAccountBinds = function (){
            if(!$rootScope.account.companyId || $scope.accountBinds.length > 0){
                return;
            }
            AccountBind.getEdiAccounts({
                companyId: $rootScope.account.companyId,
                type: "fba"
            }, function(result){
                $scope.accountBinds = result;
            })
        }


        $scope.pushFbaOrderApi = function (accountBind){
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };

            var pushOrdersDTO = {};
            if(accountBind){
                pushOrdersDTO.accountBind = accountBind;
            }
            else {
                pushOrdersDTO.accountBind = getPushAccountBind();
                accountBind = pushOrdersDTO.accountBind;
            }
            if(!pushOrdersDTO.accountBind){
                layer.msg($translate.instant('global.refreshAlert'));
                return;
            }
            pushOrdersDTO.guideId = $scope.guide.id;

            if(accountBind.type == 'pedder' || accountBind.type == 'MoreLinkBC' || accountBind.type == 'MoreLinkBCNew' || accountBind.type == 'TPL'
                || accountBind.type =="usniuku" || (accountBind.type == 'nextsls' && accountBind.thirdPartCode && accountBind.thirdPartCode.indexOf(";") !== -1) || (accountBind.type == 'ecang' && accountBind.payeeCode && accountBind.payeeCode.indexOf(";") !== -1)){
                $uibModal.open({
                    templateUrl: 'app/entities/sub-tracking/sub-tracking-delete-dialog.html',
                    controller: 'SubTrackingDeleteController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                        entity: [function() {
                            return pushOrdersDTO;
                        }]
                    }
                }).result.then(function(resultList) {
                    loadGuide();
                }, function() {
                });
                return;
            }
            else {
                layer.load(1, {shade: 0.3});
                Guide.pushFbaShipment(pushOrdersDTO, function(result){
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submitted'));
                    loadGuide();
                }, $rootScope.ALERT_ERROR);
            }
        }

        function getPushAccountBind(){
            if(!$scope.guide.thirdpartyPlatformId || !$scope.accountBinds || $scope.accountBinds.length == 0){
                return null;
            }
            for(var i = 0; i < $scope.accountBinds.length; i++){
                if($scope.accountBinds[i].id == $scope.guide.thirdpartyPlatformId){
                    return $scope.accountBinds[i];
                }
            }
            return null;
        }

        $scope.queryFbaOrder = function (){
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var pushOrdersDTO = {};
            pushOrdersDTO.accountBind = getPushAccountBind();
            pushOrdersDTO.guideId = $scope.guide.id;
            layer.load(1, {shade: 0.3});
            Guide.queryFbaOrder(pushOrdersDTO, function(result){
                layer.closeAll();
                layer.msg($translate.instant('global.update_success'));
                loadGuide();
            }, $rootScope.ALERT_ERROR);
        }

        $scope.cancelFbaOrder = function (){
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var pushOrdersDTO = {};
            pushOrdersDTO.accountBind = getPushAccountBind();
            pushOrdersDTO.guideId = $scope.guide.id;
            layer.load(1, {shade: 0.3});
            Guide.cancelFbaOrder(pushOrdersDTO, function(result){
                layer.closeAll();
                layer.msg($translate.instant('global.delete_success'));
                loadGuide();
            }, $rootScope.ALERT_ERROR);
        }
        $scope.getPackingList = function (){
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if(!$scope.guide.booking.bookingCtns){
                $scope.guide.booking.bookingCtns = [];
            }
            WarehousingData.getPackingList({guideId: $scope.guide.id}, function(result){
                angular.forEach(result, function(data){
                    $scope.guide.booking.bookingCtns.push(data);
                })
            }, $rootScope.ALERT_ERROR);
        }

        $scope.uploadLabel = function (file){
            if (!file) {
                return;
            }
            if (!$scope.guide.mblNum) {
                layer.msg($translate.instant('autopsApp.expressParcel.mblNullAlert'));
                return;
            };
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if (!DataUtils.isPdf(file.name) && !DataUtils.isPic(file.name)) {
                layer.msg("Please upload the PDF/Picture file");
                return;
            }
            var fd = new FormData();
            var filePath = "REPORTS/GUIDE/" + $scope.guide.id + "/LB-";
            fd.append('filepath', filePath);
            fd.append('file', file);
            $http.post(
                'api/guides/uploadLabel', fd, {
                    transformRequest : angular.identity,
                    headers : {
                        'Content-Type' : undefined
                    },
                }).success(
                function(data) {
                    if (angular.isDefined(data.filepath)) {
                        $scope.files = [];
                        loadGuide();
                        layer.msg($translate.instant('global.uploadSuccess'));
                    }
                }).error(function(error) {
                    layer.msg($translate.instant('global.uploadFail'));
            });
        };

        $scope.deletePlByGuideId = function() {
            var loadIndex = layer.load(1, {shade: 0.3});
            BookingCtns.deletePlByGuideId({
                guideId: $scope.guide.id
            }, function(){
                layer.close(loadIndex);
                loadGuide();
            }, function(result){
                layer.close(loadIndex);
                if(result.status == 409){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.cargoIsOutAlert'), {icon:0});
                }
                else {
                    layer.msg($translate.instant('global.delete_fail'));
                }
            });
        };
        $scope.getAuthAccounts = function() {
            if(!$scope.guide.shipFromId){
                return;
            }
            AddressBook.getAuthAccounts({
                addressId: $scope.guide.shipFromId
            }, function(result){
                $scope.authAccounts = result;
            })
        }

        $scope.clearService = function() {
            if(guideCopy.labelChannelCode != $scope.guide.labelChannelCode){
                $scope.guide.serviceType = null;
                $scope.guide.serviceDescription = null;
                return;
            }
            if(!$scope.guide.serviceDescription){
                $scope.guide.serviceType = null;
            }
        }
        $scope.setAutoTracking = function() {
            if (!$scope.guide.id || !angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $rootScope.AUTO_TRACKING($scope.guide);
        }
        $scope.choosedRole;
        $scope.changeRole = function(role) {
            if ($rootScope.HAVENOAUTH(role)) {
                $rootScope.FORBIDDEN_BY_ALERT(role);
                return;
            }
            $scope.choosedRole = role;
            $translate.refresh();
        }

        $scope.completeOrder = function() {
            if ($rootScope.HAVENOAUTH("AU_COMPLETE_ORDER")) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_COMPLETE_ORDER');
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/guide/fba-dialog.html',
                controller: 'FbaDialogController',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return $scope.guide;
                    }]
                }
            }).result.then(function(result) {}, function(result) {});
        }
        $scope.getCustomExcelImportRecord = function() {
            if ($scope.customExcelImportRecords) {
                return;
            }
            GridState.getCustomExcelImportRecord({types: Array.from(arguments)}, function(result) {
                $scope.customExcelImportRecords = result;
            })
        }

        $scope.importFbaOrdersByCustomize = function(customExcelImportRecord, file) {
            if ($scope.guide.id && !angular.equals($scope.guide, guideCopy)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $rootScope.importOrdersByCustomize(customExcelImportRecord, file, $scope.guide)
        }


        $scope.getDeliveryDateTest = function() {
            Guide.getDeliveryDateTest($scope.guide, function(reslt){
                alert(reslt.value);
            });
        }

        $scope.autoLabelTest = function() {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            Guide.autoLabelTest($scope.guide, function(reslt){
                loadGuide();
            }, $rootScope.ALERT_ERROR);
        }


        $scope.synOrderBranchGuide = function(synOrderConfig) {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $rootScope.SYN_ORDER_DO(synOrderConfig, $scope.guide);
        }

        // 美设ERP推送过来的内部类型，是用来标记直接客户的，客户想禁用这些公司的编辑，对应工单：W23097629325
        $rootScope.isDisabledInternalType = function(companyId) {
            if (companyId == 396 || companyId == 397 || companyId == 567) {
                return true;
            }
            return false;
        }
        var unsubscribeUpdateByChannel = $rootScope.$on('autopsApp:guideUpdateByChannel', function(event, result) {
            if(result.id == $scope.guide.id){
                loadGuide();
            }
        });
        $scope.$on('$destroy', unsubscribeUpdateByChannel);

        $scope.saveAlertYes = function() {
            return !$scope.guide.id || !angular.equals(guideCopy, $scope.guide);
        }


    }
})();
