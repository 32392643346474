(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsDistributionDialogController', WmsDistributionDialogController);

    WmsDistributionDialogController.$inject = ['$scope', '$state', '$rootScope', '$http', '$translate', '$location', '$timeout', '$uibModalInstance', '$stateParams', '$uibModal', 
    'OpsPorts', 'entity', 'WinAES', 'WmsIn', 'WmsCargo', 'OpsCarrier', 'Principal', 'OpsFiles', 'WmsWarehouse', 
    'ManageEnterprise', 'DataUtils', 'OpsItem', 'WmsWorkLog', 'AccountBind', 'OpsCompany', 'uiGridConstants', 'GridState', 'uiGridGroupingConstants'];

    function WmsDistributionDialogController($scope, $state, $rootScope, $http, $translate, $location, $timeout, $uibModalInstance, $stateParams, $uibModal,
        OpsPorts, entity, WinAES, WmsIn, WmsCargo, OpsCarrier, Principal, OpsFiles, WmsWarehouse, 
        ManageEnterprise, DataUtils, OpsItem, WmsWorkLog, AccountBind, OpsCompany, uiGridConstants, GridState, uiGridGroupingConstants) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };
        var vm = this;
        $scope.wmsIn = entity;
        vm.addCargo = addCargo;
        vm.save = save;
        vm.updateTtl = updateTtl;    
        vm.updateVol = updateVol;              

        vm.accept = accept; 
        vm.decline = decline;   
        vm.clientSelected = clientSelected;
        vm.warehouseSelected = warehouseSelected;
        vm.getPubGoods = getPubGoods;
        vm.warehouses = [];

        vm.portType = true;

        vm.getWarehouses = getWarehouses;
        vm.importCargo = importCargo;
        vm.setAudited = setAudited;
        vm.getBarcode = getBarcode;
        vm.printReturnWorkSheet = printReturnWorkSheet;
        vm.addSkuToWmsGoods = addSkuToWmsGoods;

        vm.copyCargo = copyCargo;
        vm.deleteOne = deleteOne;
        vm.antiIn = antiIn;

        vm.searchParam = null;

        vm.printIn = printIn;
        vm.printPl = printPl;
        vm.generateCustoms = generateCustoms;
        $scope.wmsWorkLogs = [];

        $scope.files = [];

        vm.accountBinds = [];
        vm._onLocationSelected = _onLocationSelected;
        vm.getLocations = getLocations;
        vm.autoFillLocation = autoFillLocation;


        vm.editIdx = null;

        function autoFillLocation (cargo) {
            if(!cargo.location){
                return;
            }
            angular.forEach($scope.wmsIn.cargoList, function (data) {
                if(!data.location){
                    data.location = cargo.location;
                    data.locationId = cargo.locationId;
                    data.locationType = cargo.locationType;
                    data.areaId = cargo.areaId;
                    data.warehouseId = cargo.warehouseId;
                }
            })
        }

        function _onLocationSelected (item, cargo) {
            cargo.location = item.code;
            cargo.locationId = item.locationId;
            cargo.locationType = item.locationType;
            cargo.areaId = item.areaId;
            cargo.warehouseId = item.warehouseId;
        }
        vm.locations = [];
        function getLocations () {
            if (!vm.locations || vm.locations.length == 0){
                WmsWarehouse.getLocations({warehouseId: $scope.wmsIn.warehouseId}, function (data) {
                    vm.locations = data;
                });
            }
        }
        
        $scope.updateSales = function(item) {
            $scope.wmsIn.salesId = item.id;
        };
        $scope.updateCus = function(item) {
            $scope.wmsIn.cusId = item.cusId;
        };
        $scope.salesFilter = function(item) {
            return item.authorities.indexOf("ROLE_SALES") !== -1;
        };
        $scope.cusFilter = function(item) {
            return item.authorities.indexOf("ROLE_CS") !== -1;
        };
        function generateCustoms () {
            vm.isSaving = true;
            WmsIn.generateCustoms($scope.wmsIn, onGenerateSuccess, onGenerateError);
        }

        function onGenerateSuccess(result) {
            result.deliveryArrange = result.deliveryBySelf?"y":"n";
            $scope.wmsIn = result;
            updateGwVolTtl();
            if ($scope.wmsIn.customDeclarationId){
                $scope.wmsIn.encodeIdCus = WinAES.encode($scope.wmsIn.customDeclarationId.toString());
            }              
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submitted'));
        }

        function updateGwVolTtl () {
            angular.forEach($scope.wmsIn.cargoList, function(data){
                if(data.pkgs){
                    if(!data.nwTtl && data.nw){
                        data.nwTtl = DataUtils.round(data.nw * data.pkgs, 4);
                    }
                    if(!data.gwTtl && data.gw){
                        data.gwTtl = DataUtils.round(data.gw * data.pkgs, 4);
                    }
                    if(!data.volTtl && data.vol){
                        data.volTtl = DataUtils.round(data.vol * data.pkgs, 4);
                    }
                    if(!data.capacityTtl && data.capacity){
                        data.capacityTtl = DataUtils.round(data.capacity * data.pkgs, 2);
                    }
                    if(!data.pieceTtl && data.piece){
                        data.pieceTtl = DataUtils.round(data.piece * data.pkgs, 2);
                    }
                }
            })
        }
        
        function updateRowGwVolTtl (data) {
            updateRowVol(data);  
            if(data.pkgs){
                if(data.nw){
                    data.nwTtl = DataUtils.round(data.nw * data.pkgs, 4);
                }
                if(data.gw){
                    data.gwTtl = DataUtils.round(data.gw * data.pkgs, 4);
                }
                if(data.vol){
                    data.volTtl = DataUtils.round(data.vol * data.pkgs, 4);
                }
                if(data.capacity){
                    data.capacityTtl = DataUtils.round(data.capacity * data.pkgs, 2);
                }
                if(data.piece){
                    data.pieceTtl = DataUtils.round(data.piece * data.pkgs, 2);
                }
            }
            updateTtl();
        }

        function onGenerateError() {
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submittedfailed'));
            
        }
        function printIn(reportType){
            var wmsIn = angular.copy($scope.wmsIn);
            wmsIn.wmsWorkLogs = $scope.wmsWorkLogs;
            OpsFiles.generateWmsInPaper({reportType: reportType},wmsIn,function(result){
                $rootScope.PRINTBYTES(result.data);
            },function(error){
                layer.msg($translate.instant('autopsApp.wmsIn.viewWmsInPaperFailed'));
            });
        }

        function printPl(reportType, fileType){
            var wmsIn = angular.copy($scope.wmsIn);
            wmsIn.reportType = reportType;
            wmsIn.fileType = fileType;
            wmsIn.wmsWorkLogs = $scope.wmsWorkLogs;
            OpsFiles.generateWmsInPaper({},wmsIn,function(result){
                $rootScope.PDFPRINTEXCELDOWNLOAD(result, fileType, 'packingList');                
            },function(error){
                layer.msg($translate.instant('autopsApp.wmsIn.viewWmsInPaperFailed'));
            });
        }

        function copyCargo (cargo) {
            var cargoCopy = angular.copy(cargo);
            cargoCopy.id = null;
            cargoCopy.inTime = null;
            cargoCopy.outTime = null;
            cargoCopy.location = null;
            cargoCopy.locationId = null;
            cargoCopy.locationType = null;
            cargoCopy.allOut = false;
            cargoCopy.pkgs = null;
            cargoCopy.warehouseId = null;
            cargoCopy.areaId = null;
            cargoCopy.palletAmt = null;
            cargoCopy.gateInTime = null;
            cargoCopy.remarks = null;
            cargoCopy.status = null;
            cargoCopy.billDate = null;
            cargoCopy.lastCargoId = null;
            cargoCopy.returnHandled = (isOut == null?false:null);
            $scope.wmsIn.cargoList.push(cargoCopy);
        }
        function doAntiIn () { 
            vm.isSaving = true;
            WmsIn.antiIn({
                id: $scope.wmsIn.id
            }, function(result){
                result.deliveryArrange = result.deliveryBySelf?"y":"n";
                $scope.wmsIn = result;
                updateGwVolTtl();
                vm.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(error){
                vm.isSaving = false;
                layer.msg($translate.instant('global.messages.submittedfailed'));
            })
        }
        $scope.wmsLabels = function(reportType) {
            $scope.format = 1;
            WmsIn.wmsLabels({
                reportType: reportType,
                fileType: $scope.format
            }, $scope.wmsIn, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }
        $scope.cargoLabel = function(cargo) {
            var wmsCopy = angular.copy($scope.wmsIn);
            wmsCopy.wmsCargos = [];
            wmsCopy.wmsCargos.push(cargo);

            WmsIn.wmsLabels({
                reportType: 690,
                fileType: 1
            }, wmsCopy, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }


        $scope.wmsPltLabels = function(reportType) {
            $scope.format = 1;
            WmsIn.wmsPltLabels({
                reportType: reportType,
                fileType: $scope.format
            }, $scope.wmsIn, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }       

        function antiIn(){
            if(!$rootScope.account.companyId){return;}
            if($rootScope.HAVENOAUTH('AU_WMS_ANTI')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_WMS_ANTI');    
                return;
            }
            layer.confirm($translate.instant('autopsApp.wmsIn.antiInAlert'), {
                icon: 0,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                doAntiIn();
            }, function() {
                return;
            }); 
        }

        $scope.opsItems = [];
        $scope.getAllWmsService = function (cargo){
            if(cargo && !cargo.wmsService){
                cargo.wmsService = "";
            }
            if($scope.opsItems && $scope.opsItems.length > 0){
                return;
            }
            OpsItem.getAllWmsService({
                whId: $scope.wmsIn.warehouseId,
                companyId: $rootScope.account.companyId
            }, function(result) {
                $scope.opsItems = result;
                if(!$scope.opsItems || $scope.opsItems.length == 0){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.wmsServiceTips'));
                }
            }, function(error) {
                layer.msg($translate.instant('global.noRecord'));
            });
        }

        function printReturnWorkSheet(){
            if(!$scope.wmsIn.cargoList || $scope.wmsIn.cargoList.length == 0){
                return;
            }
            var wmsIns = [];
            wmsIns.push($scope.wmsIn);
            WmsIn.printReturnWorkSheet({
                reportCode: 830,
                companyId: $rootScope.account.companyId
            }, wmsIns, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.noRecord'));
            });
        }

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        function deleteOne (wmsIn) {
            if ($rootScope.HAVENOAUTH('AU_DEL_BIZ')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_DEL_BIZ');
                return;
            }                  
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-delete-dialog.html',
                controller: 'WmsInDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return wmsIn;
                    }]
                }
            }).result.then(function() {
                $uibModalInstance.close($scope.wmsIn);  
            }, function() {
            });
            
        }

        $scope.clear = function () {
            $uibModalInstance.dismiss();                       
        }

        function isCreateReturn () {
            if(!$scope.wmsIn.cargoList){
                return false;
            }
            for(var i = 0; i < $scope.wmsIn.cargoList.length; i++){
                if($scope.wmsIn.cargoList[i].returnHandle){
                    return true;
                }
            }
            return false;
        }

        var path = $location.path();
        var wmsInSaveCopy = {};
        var isOut = false;
        $timeout(function() {
            if($scope.wmsIn.id){
                WmsWorkLog.getOpRequirment({
                    type: "wms",
                    id: $scope.wmsIn.id
                }, function(result){
                    $scope.wmsWorkLogs = result;
                });
                $rootScope.LOADFILES($scope.files, '/wmsIn/' + $scope.wmsIn.id + '/', $scope.wmsIn.companyId);
                if($scope.wmsIn.cargoList.length > 0){
                    WmsCargo.getWmsCargoInventory($scope.wmsIn.cargoList, function(inventoryList){
                        updateInventoryAmt(inventoryList);
                    })
                }
                
            }
            $scope.wmsIn.deliveryArrange = $scope.wmsIn.deliveryBySelf?"y":"n";
            updateGwVolTtl();            
            $timeout(function (){
                $scope.expandAll();
                angular.copy($scope.wmsIn, wmsInSaveCopy);
            }, 3000)
        });

        function updateInventoryAmt(inventoryList){
            angular.forEach($scope.wmsIn.cargoList, function(cargo){
                angular.forEach(inventoryList, function(inventory){
                    if(cargo.id == inventory.id){
                        cargo.inventoryAmt = inventory.inventoryAmt;
                        cargo.availableAmt = inventory.availableAmt;
                    }
                })
            })
            angular.copy($scope.wmsIn, wmsInSaveCopy);
        }


        var rateUnsubscribe = $rootScope.$on('autopsApp:wmsWorkLogUpdate', function(event, result) {
            if(result && result.id){
                var isNew = true;
                angular.forEach($scope.wmsWorkLogs, function(data){
                    if(data.id == result.id){
                        data.count = result.count;
                        data.work = result.work;
                        isNew = false;
                    }
                })
                if(isNew){
                    $scope.wmsWorkLogs.push(result)
                }
            }
        });
        $scope.$on('$destroy', rateUnsubscribe);

        function getBarcode (cargo, type) {
            if (cargo.allOut){return;}
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-dialog.html',
                controller: 'WmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md'
            }).result.then(function(code) {
                cargo[type] = code;                
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        function addCargo () {
            if (!$scope.wmsIn.cargoList){
                $scope.wmsIn.cargoList = [];
            }
            $scope.wmsIn.cargoList.push({
                isPallet: $scope.wmsIn.handleType == "transportService",
                allOut:false,
                returnHandle:isOut == null?"stock":null, 
                returnHandled: isOut == null?false:null,
                action:"appIn"
            });
            $timeout(function (){
                $scope.expandAll();
            })            
        }
        
        $scope.delCargo = function(cargo) {
            if(!$rootScope.account.companyId && cargo.batchThree){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.delTdCargoTkAlert'));
                return;
            }
            if (cargo.id && !angular.equals($scope.wmsIn, wmsInSaveCopy)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }    
            if(!$rootScope.account.companyId && $scope.wmsIn.status !='new'){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.bookingPriceLockedAlert'));
                return;
            }            
            if (cargo.id) {
                layer.confirm($translate.instant('global.confirmToDelete'), {
                    icon: 3,
                    title: $translate.instant('entity.action.confirm'),
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                }, function(index) {
                    layer.close(index);
                    WmsCargo.delete({
                        id: cargo.id
                    }, onSaveSuccess, $rootScope.ALERT_ERROR);
                    $translate.refresh();
                }, function() {
                    return;
                });
            } else {
                var index = $scope.wmsIn.cargoList.indexOf(cargo);
                if (index > -1) {
                    $scope.wmsIn.cargoList.splice(index, 1);
                };
                updateTtl();
                $timeout(function (){
                    $scope.expandAll();
                })
            }
        };

        function save () {
            if(!$scope.wmsIn.warehouseId){                
                layer.msg($translate.instant('autopsApp.wmsIn.chooseWarehouseAlert'));
                return;
            }
            if($rootScope.account.companyId && !$scope.wmsIn.venderId){                
                layer.msg($translate.instant('autopsApp.wmsIn.chooseClientAlert'));
                return;
            }
            vm.isSaving = true;
            if (angular.equals($scope.wmsIn, wmsInSaveCopy)){
                layer.msg($translate.instant('global.no_amendmend'));
                vm.isSaving = false;
                return;
            }    
            if($scope.wmsIn.isOut == false){
                var skuNull = false;             
                angular.forEach($scope.wmsIn.cargoList, function (data) {
                    if(!data.sku){
                        skuNull = true;
                    }
                })  
                if(skuNull && $scope.wmsIn.handleType == 'wmsService'){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.skuNullAlert'));
                    vm.isSaving = false;
                    return;
                }
            }
            if ($rootScope.account.companyId){
                if(!$scope.wmsIn.companyId){
                    $scope.wmsIn.companyId = $rootScope.account.companyId;
                }    
                $scope.wmsIn.opName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                if ($scope.wmsIn.status == "new" || $scope.wmsIn.status == "Requested" || $scope.wmsIn.status == "Amended"){
                    $scope.wmsIn.status = "Confirmed";
                }                
                if ($scope.wmsIn.actualNum){
                    if($scope.wmsIn.actualNum == $scope.wmsIn.applicationNum){
                        $scope.wmsIn.status = "allIn";
                        $scope.wmsIn.allOut = true;
                        if(!$scope.wmsIn.inTime){
                            $scope.wmsIn.inTime = new Date();
                        }
                    }
                    else {
                        $scope.wmsIn.status = "partIn";
                        $scope.wmsIn.allOut = false;
                    }                    
                }    
                angular.forEach($scope.wmsIn.cargoList, function (data) {
                    if(!data.keeperId){
                        data.inTime = new Date();
                        data.keeper = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                        data.keeperId = $rootScope.account.id;
                    }
                })       
            }    
            initWmsCargo();
            $scope.wmsIn.deliveryBySelf = $scope.wmsIn.deliveryArrange == "y"?true:false;          
            if ($scope.wmsIn.id) {
                WmsIn.update($scope.wmsIn, onSaveSuccess, onSaveError);
            } else {     
                $scope.wmsIn.isOut = isOut; 
                WmsIn.save($scope.wmsIn, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            result.deliveryArrange = result.deliveryBySelf?"y":"n";
            $scope.wmsIn = result;
            resize();
            updateGwVolTtl();
            wmsInSaveCopy = angular.copy($scope.wmsIn);
            vm.isSaving = false;
            $scope.$emit('autopsApp:wmsInUpdate', result);
            layer.msg($translate.instant('global.messages.submitted'));
        }

        function onSaveError (result) {
            vm.isSaving = false;
            $rootScope.ALERT_ERROR(result);
        }
        function updateRowVol (row) {
            if (row.length && row.width && row.height){
                row.vol = row.length/100 * row.width/100 * row.height/100;
                row.vol = row.vol.toFixed(4) -0;
            }
        }

        function updateVol () {
            $scope.wmsIn.vol = 0;
            for (var i = 0; i < $scope.wmsIn.cargoList.length; i ++){              
                updateRowVol($scope.wmsIn.cargoList[i]);  
                if ($scope.wmsIn.cargoList[i].vol && $scope.wmsIn.cargoList[i].pkgs){
                    $scope.wmsIn.vol = $scope.wmsIn.vol + $scope.wmsIn.cargoList[i].vol * $scope.wmsIn.cargoList[i].pkgs;
                }
            }
            if ($scope.wmsIn.vol){
                $scope.wmsIn.vol = $scope.wmsIn.vol.toFixed(3) -0;
            }
            else {
                $scope.wmsIn.vol = null;
            }
            updateTtl();
        }
        function updateTtl () {
            if (!$scope.wmsIn.cargoList || $scope.wmsIn.cargoList.length == 0){
                return;
            }

            $scope.wmsIn.gw = 0;
            $scope.wmsIn.vol = 0;
            $scope.wmsIn.applicationNum = 0;
            $scope.wmsIn.pallets = 0;
            angular.forEach($scope.wmsIn.cargoList, function(data){
                if(!data.vol){
                    if (data.length && data.width && data.height){
                        data.vol = data.length/100 * data.width/100 * data.height/100;
                        data.vol = DataUtils.round(data.vol, 4);
                    }
                }
                if (data.pkgs){
                    $scope.wmsIn.applicationNum += data.pkgs - 0;
                    if(data.vol){
                        data.volTtl = DataUtils.round(data.vol * data.pkgs, 4);
                        $scope.wmsIn.vol += data.volTtl;
                    }
                    if(data.gw){
                        data.gwTtl = DataUtils.round(data.gw * data.pkgs, 2);
                        $scope.wmsIn.gw += data.gwTtl;
                    }
                    if(data.nw){
                        data.nwTtl = DataUtils.round(data.nw * data.pkgs, 2);
                    }
                    if(data.capacity){
                        data.capacityTtl = DataUtils.round(data.capacity * data.pkgs, 2);
                    }
                    if(data.piece){
                        data.pieceTtl = DataUtils.round(data.piece * data.pkgs, 2);
                    }
                }
                if (data.palletAmt){
                    $scope.wmsIn.pallets += data.palletAmt - 0;
                }
            })

            if ($scope.wmsIn.gw){
                $scope.wmsIn.gw = DataUtils.round($scope.wmsIn.gw, 2);
            }
            else {
                $scope.wmsIn.gw = null;
            }

            if ($scope.wmsIn.vol){
                $scope.wmsIn.vol = DataUtils.round($scope.wmsIn.vol, 3);
            }
            else {
                $scope.wmsIn.vol = null;
            }

            if ($scope.wmsIn.applicationNum){
                $scope.wmsIn.applicationNum = DataUtils.round($scope.wmsIn.applicationNum);
            }
            else {
                $scope.wmsIn.applicationNum = null;
            } 
            resize();
        }

        vm.pubGoods = null;
        function getPubGoods () {
            if(vm.pubGoods || !$rootScope.account.companyId){
                return;
            }
            ManageEnterprise.getPubGoods({companyId:$rootScope.account.companyId}, function(result) {
                vm.pubGoods = result;
            })
        }

        function accept () {
            vm.isSaving = true; 
            $scope.wmsIn.status = "Confirmed";
            if(!$scope.wmsIn.companyId){
                $scope.wmsIn.companyId = $rootScope.account.companyId; 
            }   
            $scope.wmsIn.opName = $rootScope.account.firstName + " " + $rootScope.account.lastName;               
            initWmsCargo () 
            WmsIn.update($scope.wmsIn, onSaveSuccess, onSaveError);
        }

        function decline () {
            vm.isSaving = true;
            // $scope.wmsIn.warehousingNumber = null;
            $scope.wmsIn.updateTime = null;
            $scope.wmsIn.status = "Declined";
            if(!$scope.wmsIn.companyId){
                $scope.wmsIn.companyId = $rootScope.account.companyId; 
            }   
            $scope.wmsIn.opName = $rootScope.account.firstName + " " + $rootScope.account.lastName;               
            initWmsCargo ()   
            WmsIn.update($scope.wmsIn, onSaveSuccess, onSaveError);
        }

        function initWmsCargo () {         
            if($scope.wmsIn.cargoList && $scope.wmsIn.cargoList.length > 0){
                angular.forEach($scope.wmsIn.cargoList, function (data) {
                    data.clientId = $scope.wmsIn.venderId;
                    data.companyId = $scope.wmsIn.companyId;
                    data.medCompanyId = $scope.wmsIn.medCompanyId;
                })
            }    
        }

        function getWarehouses () {
            if (vm.warehouses && vm.warehouses.length > 0){
                return;
            }
            if(!$rootScope.account.companyId){
                WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: true,
                    includeGroup: true
                }, function (data) {
                    vm.warehouses = data;
                });
            }
            else if ($scope.wmsIn.isVirtual && $scope.wmsIn.medCompanyId == $rootScope.account.companyId){
                WmsWarehouse.getAll({
                    includeOwn: false,
                    includeVirtual: true,
                    includeGroup: false
                }, function (data) {
                    vm.warehouses = data;
                });
            }
            else {
                WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function (data) {
                    vm.warehouses = data;
                });
            } 
        }


        function clientSelected (client) {
            $scope.wmsIn.venderId = client.id;
            $scope.wmsIn.salesId = client.createdBy;
            $scope.wmsIn.salesName = client.creater;
            if(client.cusId && client.cusName){
                $scope.wmsIn.cusId = client.cusId;
                $scope.wmsIn.cusName = client.cusName;
            }            
            if(client.freeDays){
                $scope.wmsIn.freeDays = client.freeDays;
            }            
            $rootScope.WMSGOODS = [];
        }

        function warehouseSelected (warehouse) {
            $scope.wmsIn.warehouseName = warehouse.name;
            $scope.wmsIn.warehouseId = warehouse.id;
            $scope.wmsIn.companyId = warehouse.companyId;
            $scope.wmsIn.medCompanyId = warehouse.medCompanyId;
            $scope.wmsIn.isVirtual = warehouse.isVirtual;
            $scope.wmsIn.medCompany = warehouse.medCompany;
            $scope.wmsIn.medVenderId = warehouse.medVenderId;
        }

        function uploadFileOnClick(file){
            if (!file) {
                return;
            }
            var fd = new FormData();            
            var filePath = "/" + "wmsIn/" + $scope.wmsIn.id + "/";
            fd.append('filepath', filePath);
            fd.append('file', file);
            fd.append('companyId', $scope.wmsIn.companyId);

            $http.post(
                'api/ops-files/uploadFile', fd, {
                    transformRequest : angular.identity,
                    headers : {
                        'Content-Type' : undefined
                    },
                }).success(
                function(data) {
                    if (angular.isDefined(data.filepath)) {
                        reloadFiles(data);
                        layer.msg($translate.instant('global.uploadSuccess'));
                    }
                }).error(function(error) {
                    layer.msg($translate.instant('global.uploadFail'));
            });
        };

        function importCargo (file) {
            if (!file) {return;}
            var fd = new FormData();
            fd.append('file', file);
            $http.post(
                'api/wms-cargos/importTdByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {    
                    data = angular.fromJson(data);
                    if (!data || data.length == 0){
                        layer.msg($translate.instant('global.messages.resultNull'));  
                        return;
                    }
                    if($scope.wmsIn.cargoList 
                        && $scope.wmsIn.cargoList.length == 1 
                        && !$scope.wmsIn.cargoList[0].id 
                        && !$scope.wmsIn.cargoList[0].pkgs){
                        $scope.wmsIn.cargoList  = [];
                    }
                    angular.forEach(data, function (cargo) {
                        $scope.wmsIn.cargoList.push(cargo);
                    })           
                    updateTtl();
                    layer.msg($translate.instant('global.importSuccess'));
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));                           
                });
        };

        function setAudited (audied) {
            if (!$rootScope.account.authorities || $rootScope.account.authorities.indexOf("AU_WMS_AUDIT") == -1) {
                $scope.wmsIn.audited = !audied;
                $rootScope.FORBIDDEN_BY_ALERT('AU_WMS_AUDIT');    
                return;
            }
            if(audied){
                $scope.wmsIn.auditor = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            }
            else {
                $scope.wmsIn.auditor = null;
            }
            save();
        }

        $scope.palletizePop = function() {     
            $uibModal.open({
                templateUrl: 'app/entities/crm-price/crm-price-dialog.html',
                controller: 'CrmPriceDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return angular.copy($scope.wmsIn);
                    }]
                }
            }).result.then(function(result) {
                $scope.wmsIn.pallets = result.pltNum;
                $scope.wmsIn.palletizeJson = angular.toJson(result);
            }, function() {
            });
        }

        $scope.groupCompanys = [];
        $scope.loadCompanys = function() {
            if ($scope.groupCompanys && $scope.groupCompanys.length > 0) {
                return;
            }
            OpsCompany.getGroupCompanys(function(result) {
                $scope.groupCompanys = result;
            })
        };


        function addSkuToWmsGoods (){
            WmsIn.addSkuToWmsGoods($scope.wmsIn, function(result){
                $rootScope.OPS_ALERT($translate.instant('global.importSuccessAmt', {param: result.successCount}));
            }, $rootScope.ALERT_ERROR)
        }

        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: false,      
            enableCellEditOnFocus: true,

            enableGridMenu : true,//表格 菜单  
            showGridFooter: false,//表格的footer  
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤            
            useExternalFiltering: false,      

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: $translate.instant('global.menu.entities.distribution') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'], 

            minimumColumnSize: 30,
            // rowHeight: 200,
            columnDefs: [
                { field: 'podName',  
                    grouping: {groupPriority: 0},    
                    displayName: $translate.instant('autopsApp.wmsIn.shiptoPlace'), 
                    minWidth:80},
                { field: 'batchOne', 
                    minWidth:180,
                    grouping: {groupPriority: 1},                    
                    displayName: "Shipment ID"},
                { field: 'shipOut', 
                    width: 160,
                    enableFiltering: false,
                    enableCellEdit: false,
                    displayName: $translate.instant('autopsApp.wmsIn.shipOut'), 
                    cellTemplate: '<div class="grid-text"><span class="label label-danger" ng-click="grid.appScope.delCargo(row.entity)" ng-if="row.entity.id || (row.treeLevel != 0 && !row.treeLevel)"><span class="glyphicon glyphicon-remove"></span></span><span class="label label-primary" ng-click="grid.appScope.printPod(row)" ng-if="row.treeLevel == 0 || row.treeLevel == 1"><span class="glyphicon glyphicon-print"></span><span class="ml-4">BOL</span></span><span class="label label-info ml-4" ng-click="grid.appScope.cargoLabel(row.entity)" ng-if="row.entity.id && grid.appScope.account.companyId"><span class="glyphicon glyphicon-print"></span><span class="ml-4" translate="autopsApp.wmsIn.wmsLabel">wmsLabel</span></span><span class="label ml-4" ng-class="row.entity.id?\'label-info\':\'label-primary\'" ng-click="grid.appScope.createTms(row)" ng-if="(row.treeLevel == 0 || row.treeLevel == 1 || row.entity.id) && !row.entity.batchThree && grid.appScope.account.companyId" data-toggle="tooltip" data-placement="top" title="Create TMS/Truck order"><span class="glyphicon glyphicon-export"></span><span class="ml-4">TMS</span></span><span class="ml-4 text-info" ng-if="row.entity.id && row.entity.batchThree"><span class="ml-4" ng-click="grid.appScope.VIEWEXPRESS_BY_JOB(row.entity.batchThree)">{{row.entity.batchThree}}</span><span class="glyphicon glyphicon glyphicon-duplicate text-danger ml-4" ng-click="grid.appScope.COPYALL(row.entity.batchThree)"></span><span class="glyphicon glyphicon-remove-circle text-danger ml-4" ng-click="grid.appScope.delFieldValue(row.entity, \'batchThree\')" ng-if="grid.appScope.account.companyId"></span></span></div>'},
                { field: 'palletSkuIn', 
                    width: 210,
                    enableFiltering: false,
                    enableCellEdit: false,
                    displayName: $translate.instant('autopsApp.wmsIn.palletSkuIn'), 
                    cellTemplate: '<div class="grid-text"><span ng-if="(row.treeLevel == 0 || row.treeLevel == 1 || row.entity.id) && !row.entity.allOut && grid.appScope.account.companyId"><span class="label ml-4" ng-class="row.entity.id?\'label-info\':\'label-primary\'" ng-click="grid.appScope.registPltIn(row, true)" data-toggle="tooltip" data-placement="top" title="{{\'autopsApp.wmsIn.palletsInId\' | translate}}" ng-if="!row.entity.returnHandled"><span class="glyphicon glyphicon-log-in"></span><span class="ml-4">PLT</span></span><span class="label ml-4" ng-class="row.entity.id?\'label-info\':\'label-primary\'" ng-click="grid.appScope.registPltIn(row, false)" data-toggle="tooltip" data-placement="top" title="{{\'autopsApp.wmsIn.palletsInMix\' | translate}}" ng-if="!row.entity.returnHandled"><span class="glyphicon glyphicon-log-in"></span><span class="ml-4">PLT(MIX)</span></span><span class="label ml-4" ng-class="row.entity.id?\'label-info\':\'label-primary\'" ng-click="grid.appScope.registIn(row)" data-toggle="tooltip" data-placement="top" title="{{\'autopsApp.wmsIn.skuIn\' | translate}}" ng-if="!row.entity.returnHandled"><span class="glyphicon glyphicon-log-in"></span><span class="ml-4">SKU</span></span></span><span class="label label-info ml-4" ng-click="grid.appScope.WMS_LOGS_POP(row.entity.companyId, row.entity.id)" ng-if="row.entity.allOut && grid.appScope.account.companyId"><span class="glyphicon glyphicon-eye-open"></span><span class="ml-4" translate="autopsApp.wmsIn.viewInOut">viewInOut</span></span></div>'},
                { field: 'opRequirment', 
                    width: 160,
                    enableFiltering: false,
                    enableCellEdit: false,
                    displayName: $translate.instant('autopsApp.wmsIn.opRequirment'), 
                    cellTemplate: '<span ng-if="row.entity.id" class="grid-text"><span class="label label-info" ng-click="grid.appScope.WORKLOGPOP(grid.appScope.wmsIn, row.entity, null)"><span class="glyphicon glyphicon-plus-sign"></span></span><span class="grid-text" ng-repeat="workLog in grid.appScope.wmsWorkLogs | filter:{wmsCargoId: row.entity.id} track by $index"  ng-click="grid.appScope.WORKLOG_EDIT(workLog)"><span class="text-info">{{workLog.work}}</span><span class="badge">{{workLog.count}}</span></span></span>'},               
                { field: 'fbaLabel', 
                    displayName: $translate.instant('autopsApp.wmsIn.fbaLabel'), 
                    width:100,
                    enableFiltering: false,
                    enableCellEdit: false,
                    cellTemplate: '<span class="ml-4" ng-if="row.entity.id"><span class="label label-info" ngf-select ngf-change="grid.appScope.UPLOADFILE($file, \'/wmsIn/\' + grid.appScope.wmsIn.id + \'/\' + row.entity.id + \'/\', grid.appScope.files, grid.appScope.wmsIn.companyId)"><span class="glyphicon glyphicon-cloud-upload"></span></span><span ng-repeat="file in grid.appScope.files | filter:{fileurl: \'/\' + row.entity.id + \'/\'} track by $index" class="grid-text"><span ng-click="grid.appScope.PDFPOP(file.fileurl,file.filename)" data-toggle="tooltip" data-placement="top" title="{{file.filename}}"><span class="glyphicon glyphicon-print text-info">{{$index+1}}</span></span></span></span>'},
                { field: 'returnHandled', 
                    displayName: $translate.instant('autopsApp.wmsIn.palletized'),
                    minWidth:65,            
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-click="grid.appScope.changeReturnHandled(row.entity)" ng-class="row.entity.returnHandled?\'label-danger\':\' label-info\'"translate="{{row.entity.returnHandled?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'returnHandle', 
                    minWidth:80,            
                    displayName: $translate.instant('autopsApp.wmsIn.deliveryService')},
                { field: 'palletAmt', 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsIn.palletAmt'), 
                    minWidth:80},
                { field: 'pkgs',  
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,     
                    displayName: $translate.instant('autopsApp.wmsIn.pkgs'), 
                    minWidth:80},
                { field: 'gw',   
                    displayName: $translate.instant('autopsApp.wmsIn.gw'), 
                    minWidth:80},
                { field: 'vol', 
                    displayName: $translate.instant('autopsApp.wmsIn.vol'), 
                    minWidth:80},
                { field: 'gwTtl',   
                    enableCellEdit: false,
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,     
                    displayName: $translate.instant('autopsApp.wmsIn.gwTtl'), 
                    minWidth:80},
                { field: 'volTtl', 
                    enableCellEdit: false,
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsIn.volTtl'), 
                    minWidth:80},
                
                { field: 'length', 
                    displayName: "L(CM)", 
                    minWidth:50},
                { field: 'width', 
                    displayName: "W(CM)", 
                    minWidth:50},
                { field: 'height', 
                    displayName: "H(CM)", 
                    minWidth:50},
                { field: 'shiptoAddress',  
                    displayName: $translate.instant('autopsApp.wmsIn.shiptoAddress'), 
                    // enableCellEdit: false,
                    // cellTemplate: '<span class="grid-text" ng-class="row.entity.id==grid.appScope.choosedId?\'choosedColor\':\'\'"ng-click="grid.appScope.obfFdTextPop(row.entity, \'shiptoAddress\', true)">{{row.entity.shiptoAddress}}</span>',
                    minWidth:150},
                { field: 'sku',
                    minWidth:150,
                    displayName: "SKU"},
                { field: 'batchTwo', 
                    minWidth:150,            
                    displayName: "Reference ID"},
                { field: 'batchFour', 
                    minWidth:150,            
                    displayName: "Ref Job#"},
                { field: 'availableAmt', 
                    enableCellEdit: false,
                    displayName: $translate.instant('autopsApp.wmsIn.availableAmt'), 
                    minWidth:80},
                { field: 'inventoryAmt', 
                    enableCellEdit: false,
                    displayName: $translate.instant('autopsApp.wmsIn.inventoryAmt'), 
                    minWidth:80},                
                { field: 'cargoName',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.wmsIn.cargoName')},
                { field: 'packageType',  
                    displayName: $translate.instant('autopsApp.wmsIn.pkgType'), 
                    minWidth:50},
                { field: 'remarks',
                    minWidth:200,
                    displayName: $translate.instant('autopsApp.wmsIn.remarks')}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 115;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }     
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);                   
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;

                $scope.gridApi.edit.on.afterCellEdit($scope, function(rowEntity, colDef, newValue, oldValue) {
                    updateRowGwVolTtl(rowEntity);
                })                
                // $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                //     if(!$scope.gridOptions.useExternalSorting){
                //         if(sortColumns[sortColumns.length - 1]){
                //             var fd = sortColumns[sortColumns.length - 1].field;
                //             var dir = sortColumns[sortColumns.length - 1].sort.direction;
                //             $scope.gridOptions.data.sort(function(a, b) {
                //                 return DataUtils.gridSort(a, b, fd, dir);                     
                //             });
                //         }
                //         return;
                //     }
                // });
            }
        }
        $scope.choosedId = null;
        $scope.setChoosedId = function(id){
            $scope.choosedId = id;
        }
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 115
            }, function(result){
                gridStateObj = result;                
                if(!gridStateObj || !gridStateObj.id){                    
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
            });
            window.setTimeout(resize,1000);
        });
        function resize() {
            $scope.gridOptions.data = $scope.wmsIn.cargoList;
            var tabHeight = $scope.gridOptions.data.length * 30 + 200;
            var winHeight = $(window).height() - $("#gridDialogId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 500){
                tabHeight = 500;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        $scope.expandAll = function(){
            $scope.gridApi.treeBase.expandAllRows();
        };

        function getGroupFd(treeLevel, groupVal){
            var grouping = $scope.gridApi.grouping.getGrouping(true).grouping;
            var groupFd;
            angular.forEach(grouping, function(columnDef){
                if(columnDef.groupPriority == treeLevel){
                    angular.forEach($scope.gridOptions.data, function(item){
                        if(item[columnDef.field] == groupVal){
                            groupFd = columnDef.field;
                        }
                    })         
                }
            });
            return groupFd;
        }
        function getGroupVal(row) {
            var keys = Object.getOwnPropertyNames(row.entity);
            var groupVal;
            for(var i = 0; i < keys.length; i++){
                if(row.entity[keys[i]].groupVal){
                    groupVal = row.entity[keys[i]].groupVal;
                    break;
                }
            }
            if(groupVal == "Null"){
                groupVal = null;
            }
            return groupVal;
        }

        function getSubRows(row){
            var rows = [];
            if(row.entity.id){
                rows.push(row.entity);
            }
            else if(row.treeLevel == 0){
                var groupVal = getGroupVal(row);
                var groupFd = getGroupFd(0, groupVal);
                angular.forEach($scope.gridOptions.data, function(item){
                    if(item[groupFd] == groupVal){
                        rows.push(item);
                    }
                })
            }
            else if(row.treeLevel == 1){
                var parentVal = getGroupVal(row.treeNode.parentRow);
                var parentFd = getGroupFd(0, parentVal);

                var groupVal = getGroupVal(row);
                var groupFd = getGroupFd(1, groupVal);
                angular.forEach($scope.gridOptions.data, function(item){
                    if(item[parentFd] == parentVal && item[groupFd] == groupVal){
                        rows.push(item);
                    }
                })
            }
            return rows;
            // console.log(JSON.stringify(rows;));
        }

        $scope.printPod = function(row){       
            if (!$scope.wmsIn.id || !angular.equals($scope.wmsIn, wmsInSaveCopy)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }     
            var wmsInCopy = angular.copy($scope.wmsIn);
            wmsInCopy.cargoList = getSubRows(row);
            WmsIn.prindBol({reportType: 720}, wmsInCopy, function(result) {
                $rootScope.PRINTBYTES(result.data);
            },  function(error) {

            });
        };

        $scope.registIn = function(row){      
            if (!$scope.wmsIn.id || !angular.equals($scope.wmsIn, wmsInSaveCopy)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            var wmsInCopy = angular.copy($scope.wmsIn);
            if(row){
                wmsInCopy.cargoList = getSubRows(row);
            }            
            angular.forEach(wmsInCopy.cargoList, function(data){
                if(data.palletAmt){
                    data.isPallet = true;
                    data.palletCapacity = DataUtils.round(data.pkgs/data.palletAmt, 0);
                }
            })
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-register.html',
                controller: 'WmsInRegisterController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return wmsInCopy;
                    }]
                }
            }).result.then(function() {
                loadWmsIn();
            }, function() {
                loadWmsIn();
            });
        };

        $scope.registPltIn = function(row, byShipmentId){      
            if (!$scope.wmsIn.id || !angular.equals($scope.wmsIn, wmsInSaveCopy)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            var wmsInCopy = angular.copy($scope.wmsIn);
            if(row){
                wmsInCopy.cargoList = getSubRows(row);
            }
            var cargoCount = 0;
            angular.forEach(wmsInCopy.cargoList, function(data){
                if(!data.returnHandled){
                    cargoCount++;
                }
            });
            layer.confirm($translate.instant('autopsApp.wmsIn.pltPkgsAlert', {param: cargoCount}), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                $uibModal.open({
                    templateUrl: 'app/entities/wms-in/wms-in-plt.html',
                    controller: 'WmsInDetailController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxl',
                    resolve: {
                        entity: [function() {
                            return WmsIn.getPltWmsInByTdOrder({
                                byShipmentId: byShipmentId
                            }, wmsInCopy).$promise;
                        }]
                    }
                }).result.then(function() {
                    loadWmsIn();
                }, function() {
                    loadWmsIn();
                });
            }, function() {
                return;
            });           
        };

        $scope.delFieldValue = function(row){       
            if (!$scope.wmsIn.id || !angular.equals($scope.wmsIn, wmsInSaveCopy)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            layer.confirm($translate.instant('autopsApp.wmsIn.delTmsBindAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                WmsCargo.delFieldValue({
                    wmsCargoId: row.id,
                    fieldName: "batchThree"
                }, function(result) {
                    layer.msg($translate.instant('global.messages.submitted'));
                    loadWmsIn();
                },  function(error) {
                    loadWmsIn();
                });
            }, function() {
                return;
            }); 
        }

        $scope.createTms = function(row){            
            if (!$scope.wmsIn.id || !angular.equals($scope.wmsIn, wmsInSaveCopy)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            var wmsInCopy = angular.copy($scope.wmsIn);
            wmsInCopy.cargoList = getSubRows(row);
            for(var i = wmsInCopy.cargoList.length - 1; i >= 0; i--){
                if(wmsInCopy.cargoList[i].batchThree){
                    wmsInCopy.cargoList.splice(i, 1);
                }
            }
            if(!wmsInCopy.cargoList.length){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            layer.confirm($translate.instant('autopsApp.wmsIn.createTmsAlert', {param: wmsInCopy.cargoList.length}), {
                icon: 0,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                WmsIn.createTms(wmsInCopy, function(result) {
                    layer.msg($translate.instant('global.messages.submitted'));
                    loadWmsIn();
                },  function(error) {
                    loadWmsIn();
                });
            }, function() {
                return;
            }); 
        };

        function loadWmsIn () {
            vm.isSaving = true;
            WmsIn.get({id: $scope.wmsIn.id}, function(result){
                result.deliveryArrange = result.deliveryBySelf?"y":"n";
                $scope.wmsIn = result;
                resize();
                updateGwVolTtl();
                wmsInSaveCopy = angular.copy($scope.wmsIn);
                vm.isSaving = false;
            });
        }


        $scope.prindBol = function(reportCode) {
            if (!$scope.wmsIn.id || !angular.equals($scope.wmsIn, wmsInSaveCopy)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            var wmsInCopy = angular.copy($scope.wmsIn);
            WmsIn.prindBol({reportType: reportCode}, wmsInCopy, function(result) {
                if ($rootScope.isYuyue(wmsInCopy.companyId)) {
                    $rootScope.DOWNLOADEXCEL(result, wmsInCopy.ctnNum + "-BOL.zip");
                } else {
                    $rootScope.PRINTBYTES(result.data);
                }
            },  function(error) {

            });
        }

        $scope.tdForceOut = function() {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if(selectedRows.length == 0){
                layer.msg($translate.instant('autopsApp.wmsIn.noInventorySelected'));
                return;
            }
            layer.confirm($translate.instant('autopsApp.wmsIn.inventoryForceOutAlert', {param: selectedRows.length}), {
                icon: 3,
                title: "Alert",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                layer.load(1, { shade: 0.3 });
                WmsCargo.inventoryForceOut(selectedRows, function(inventoryList) {
                    layer.closeAll();
                    updateInventoryAmt(inventoryList);
                    layer.msg($translate.instant('global.messages.submitted'));
                }, function(){
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                })
            }, function() {});
        }

        $scope.tdPltForceOut = function() {
            layer.confirm($translate.instant('autopsApp.wmsIn.pltForceOutAlert'), {
                icon: 3,
                title: "Alert",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                layer.load(1, { shade: 0.3 });
                WmsCargo.tdPltForceOut({orderId: $scope.wmsIn.id}, function(inventoryList) {
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submitted'));
                }, $rootScope.ALERT_ERROR);
            }, function() {});
        }

        $scope.changeReturnHandled = function(row) {
            WmsCargo.changeReturnHandled({}, angular.copy(row), function(result) {
                row.returnHandled = result.returnHandled;
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(){
                layer.msg($translate.instant('global.messages.submittedfailed'));
            })
        }

        $scope.exportTdPlts = function() {
            OpsFiles.exportTdPlts({orderId: $scope.wmsIn.id}, function(result) {
                $rootScope.EXPORTDOWNLOAD(result, 2, "pallets_report");
            }, function(error) {
                layer.msg($translate.instant('global.exportFailed'));
            });
        }
        var unsubscribe = $rootScope.$on('autopsApp:tdCombineUpdate', function(event, result) {
            loadWmsIn();
        });
        $scope.$on('$destroy', unsubscribe);

        $scope.createImportFmsOrder = function() {
            if(!$scope.wmsIn.type){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsOut.shipmentTypeNull')); 
                return;
            }
            WmsIn.createImportFmsOrder($scope.wmsIn, function(result) {
                layer.msg($translate.instant('global.messages.submitted'));
            }, $rootScope.ALERT_ERROR);
        }

    }
})();
