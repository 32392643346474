(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsInChargesNewController', WmsInChargesNewController);

    WmsInChargesNewController.$inject = ['$scope', '$timeout', '$state', '$rootScope', '$translate', 
    'WmsIn', 'WmsWarehouse', 'OpsVender', 'DataUtils'];

    function WmsInChargesNewController($scope, $timeout, $state, $rootScope, $translate, 
        WmsIn, WmsWarehouse, OpsVender, DataUtils) {
        var vm = this;
        
        vm.etdPeriodDTO = {};
        vm.wmsChargesDTO = {};
        
        vm.getAllWarehouses = getAllWarehouses;
        vm.warehouseSelected = warehouseSelected;

        vm.delRate = delRate;

        vm.getAllWmsCharges = getAllWmsCharges;
        vm.saveWmsCharges = saveWmsCharges;

        var typeTemp = "wms";
        $timeout(function (){

        });


        $scope.datePickerForCargoReadyDate = {};
        $scope.datePickerForCargoReadyDate.status = {
            opened: false
        };
        $scope.datePickerForCargoReadyDateOpen = function($event) {
            $scope.datePickerForCargoReadyDate.status.opened = true;
        };

        function getAllWarehouses () {
            if (!vm.warehouses || vm.warehouses.length == 0){
                WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function (data) {
                    vm.warehouses = data;
                });
            }
        }

        function warehouseSelected (warehouse) {
            vm.etdPeriodDTO.warehouseId = warehouse.id;
        }

        $scope._onClientSelected = function(data) {
            vm.etdPeriodDTO.venderId = data.id;
        }

        $scope.isSaving = false;
        function getWmsCharges () {
            $scope.isSaving = true;
            WmsIn.getWmsCharges(vm.etdPeriodDTO, function(result) {
                $scope.isSaving = false;
                vm.wmsChargesDTO = result;
            }, function(response) {
                if (response.status == 409){
                    layer.msg($translate.instant('autopsApp.guide.endDateWrongAlert'));
                }
                else if (response.status == 404){
                    layer.msg($translate.instant('global.messages.resultNull'));
                }
                $scope.isSaving = false;
            });
        }

        function getAllWmsCharges () {
            getWmsCharges();
        }

        function saveWmsCharges () {
            $scope.isSaving = true;
            vm.wmsChargesDTO.guideId = vm.guide.id;
            vm.wmsChargesDTO.type = typeTemp;
            WmsIn.saveWmsCharges(vm.wmsChargesDTO, function(result) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.save_success'));                
            }, function(response) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.save_fail'));
            });
        }
        
        function delRate (rate) {
            var cargoToDel = null;
            angular.forEach(vm.wmsChargesDTO.wmsCargos, function(data){
                if (data.id == rate.sharedOn){
                    cargoToDel = data;
                }             
            })
            if (!cargoToDel){return;}
            var index = vm.wmsChargesDTO.wmsCargos.indexOf(cargoToDel);
            if (index > -1) {
                vm.wmsChargesDTO.wmsCargos.splice(index, 1);
            };

            index = vm.wmsChargesDTO.guideRates.indexOf(rate);
            if (index > -1) {
                vm.wmsChargesDTO.guideRates.splice(index, 1);
            };
        }

        $scope.amountChange = function(rate) {
            rate.price = DataUtils.round(rate.price, 4);
            if (rate.price < 0) {
                rate.price = 0;
            }
            if(null == rate.count || rate.count < 0){
                rate.count = 1.00;
            }
            rate.total = rate.count * rate.price;
            if (rate.days) {
                rate.total = rate.total * rate.days;
            }
            rate.payAmount = rate.total = DataUtils.round(rate.total, 2);
        }

        
    }
})();
