(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsVenderDetailController', OpsVenderDetailController);

    OpsVenderDetailController.$inject = ['$http', '$state', '$scope', '$timeout', '$rootScope', '$stateParams', '$uibModalInstance', '$uibModal', '$translate',
        'entity', 'ApiErp', 'OpsVender', 'OpsShipper', 'OpsFactory', 'InvoiceInfo', 'HfBill', 'OpsCompany', 'Account', 'DataUtils', 'User'
    ];

    function OpsVenderDetailController($http, $state, $scope, $timeout, $rootScope, $stateParams, $uibModalInstance, $uibModal, $translate,
        entity, ApiErp, OpsVender, OpsShipper, OpsFactory, InvoiceInfo, HfBill, OpsCompany, Account, DataUtils, User) {

        $scope.opsClient = entity;
        $scope.files = [];
        var clientCopy = {};
        $scope.opsVenderDeposite;
        $timeout(function() {
            if (!$scope.opsClient.opsFactorys) {
                $scope.opsClient.opsFactorys = [];
            }
            if (!$scope.opsClient.opsShippers) {
                $scope.opsClient.opsShippers = [];
            }
            if (!$scope.opsClient.contactList || $scope.opsClient.contactList.length == 0) {
                $scope.opsClient.contactList = [{}];
            }
            if (!$scope.opsClient.invoiceInfos) {
                $scope.opsClient.invoiceInfos = [];
            }
            if($scope.opsClient.creditRating){
                $scope.opsClient.creditRating = $scope.opsClient.creditRating + "";
            }
            clientCopy = angular.copy($scope.opsClient);
            if($scope.opsClient.id){
                OpsVender.getDeposteAmt({id: $scope.opsClient.id}, function(result){
                    $scope.opsVenderDeposite = result;
                    // $scope.venderInit();
                })
            }
            else {
                $scope.opsVenderDeposite = {};
                $scope.venderInit();
            }
        });
        
        $scope.venderInit = function () {
            if(!$scope.opsClient.isClient || ($rootScope.account.companyId !== 395 && $rootScope.account.companyId !== 397)){
                return;
            }
            //W23105202433
            if(!$scope.opsClient.paymentTerm){
                $scope.opsClient.paymentTerm = "credit";
            }
            if(!$scope.opsClient.creditLimit){
                $scope.opsClient.creditLimit = 10000;
            }
            if(!$scope.opsClient.creditDays){
                $scope.opsClient.creditDays = 30;
            }
            if(!$scope.opsClient.creditDateType){
                $scope.opsClient.creditDateType = "etd";
            }
            if(!$scope.opsClient.creditLimitOwms){
                $scope.opsClient.creditLimitOwms = 0;
            }
            if(!$scope.opsVenderDeposite.deposite){
                $scope.opsVenderDeposite.deposite = 0;
            }
        }

        $scope.filterStr = "";
        $scope.blFilterChange = function (filterStr) {
            $scope.opsClient.opsShippers.sort(function(a, b) {
                return DataUtils.sortShipprBl(a, b, filterStr);
            });
        }
        $scope.getInputer = function (filterStr) {
            User.getUserName({id: $scope.opsClient.inputBy}, function(result){
                $scope.inputer = result.userName;
            })
        }

        $scope.paymentTermChange = function() {
            if (!$scope.opsClient.paymentTerm || $scope.opsClient.paymentTerm == 'DP' || $scope.opsClient.paymentTerm == 'beforeETA') {
                $scope.opsClient.creditDateType = null;
                $scope.opsClient.creditLimit = null;
            }
            if ($scope.opsClient.paymentTerm != 'credit') {
                $scope.opsClient.creditDays = null;
            }
        };

        $scope.recharge = function() {
            if ($rootScope.HAVENOAUTH('AU_RECHARGE_MGMT')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_RECHARGE_MGMT');   
                return;
            }
            var hfBill = {};
            hfBill.venderId = $scope.opsClient.id;
            hfBill.venderName = $scope.opsClient.companyName;
            hfBill.type = "RECHARGE";
            hfBill.currency = $rootScope.account.baseCurrency;
            hfBill.time = new Date();
            $uibModal.open({
                templateUrl: 'app/entities/hf-bill/hf-bill-dialog.html',
                controller: 'HfBillDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return hfBill;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('hfBill');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                OpsVender.getDeposteAmt({id: $scope.opsClient.id}, function(result){
                    $scope.opsVenderDeposite = result;
                })
                layer.msg($translate.instant('global.save_success'));
            }, function() {

            });
        }
        $scope.hfBills = function() {
            var entity = {};
            entity.vender = $scope.opsClient;
            $uibModal.open({
                templateUrl: 'app/entities/hf-bill/hf-bill-delete-dialog.html',
                controller: 'HfBillDeleteController',
                controllerAs: 'vm',
                backdrop: false,
                size:  "xl",
                resolve: {
                    entity: [function() {
                        return entity;
                    }],        
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsCompany');
                        $translatePartialLoader.addPart('hfBill');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {

            }, function() {

            });
        }

        $scope.getAllFirstLetter = function() {
            if (!$scope.opsClient.companyName || $scope.opsClient.code) {
                return;
            }
            OpsVender.getAllFirstLetter({
                inputStr: $scope.opsClient.companyName
            }, function(result) {
                $scope.opsClient.code = result.reultStr;
            })
        }


        $scope.getLogin = function(contact) {
            contact.companyName = $scope.opsClient.companyName;
            $uibModal.open({
                templateUrl: 'app/entities/crm-quotation/crm-quotation-detail.html',
                controller: 'CrmQuotationDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: function () {
                        return contact;
                    }
                }
            }).result.then(function() {
            }, function() {
            });
        }

        $scope.setDiscount = function(discount) {
            $scope.opsClient.discount = discount;
        };

        $scope.addFactory = function() {
            $scope.opsClient.opsFactorys.push({});
        };
        $scope.delFactory = function(factory) {
            if (factory.id != null) {
                OpsFactory.delete({
                    id: factory.id
                });
            };
            var index = $scope.opsClient.opsFactorys.indexOf(factory);
            if (index > -1) {
                $scope.opsClient.opsFactorys.splice(index, 1);
            };
        };

        $scope.initVender = function() {
            if ($scope.isClient && (!$scope.opsClient.opsFactorys || $scope.opsClient.opsFactorys.length == 0)) {
                $scope.opsClient.opsFactorys = [{}];
                $scope.opsClient.opsFactorys[0] = {
                    companyName: $scope.opsClient.companyName,
                    address: $scope.opsClient.address,
                    attn: $scope.opsClient.attn,
                    mob: $scope.opsClient.mob,
                    tel: $scope.opsClient.tel,
                    fax: $scope.opsClient.fax
                }
            }
            if ($scope.isClient && (!$scope.opsClient.invoiceInfos || $scope.opsClient.invoiceInfos.length == 0)) {
                $scope.opsClient.invoiceInfos = [{}];
                $scope.opsClient.invoiceInfos[0] = {
                    invoiceTitle: $scope.opsClient.companyName,
                    addressTel: $scope.opsClient.address + $scope.opsClient.tel
                }
            }
            $scope.opsClient.cooperated = true;
        };

        $scope.addShipper = function() {
            $scope.opsClient.opsShippers.push({});
        };
        $scope.delShipper = function(shipper) {
            if (shipper.id != null) {
                OpsShipper.delete({
                    id: shipper.id
                });
            };
            var index = $scope.opsClient.opsShippers.indexOf(shipper);
            if (index > -1) {
                $scope.opsClient.opsShippers.splice(index, 1);
            };
        };
        $scope.upperCode = function(client) {
            client.code = angular.uppercase(client.code);
        };
        $scope.uppercaseBlShow = function(obj) {
            obj.blShow = angular.uppercase(obj.blShow);
        }
        var onSaveSuccess = function(result) {
            layer.msg($translate.instant('global.save_success'));
            $scope.isSaving = false;
            $scope.opsClient = result;
            if($scope.opsClient.creditRating){
                $scope.opsClient.creditRating = $scope.opsClient.creditRating + "";
            }
            clientCopy = angular.copy($scope.opsClient);
            $scope.$emit('autopsApp:opsVenderUpdate', clientCopy);
        }

        $scope.clear = function() {
            if (!angular.equals($scope.opsClient, clientCopy)) {
                layer.confirm($translate.instant('global.notSavedAlert'), {
                    btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
                }, function(index) {
                    $uibModalInstance.dismiss('cancel');
                    layer.close(index);
                }, function() {});
                return;
            }
            $uibModalInstance.dismiss('cancel');
        }
        
        $scope.save = function() {
            if (angular.equals($scope.opsClient, clientCopy)) {
                layer.msg($translate.instant('global.no_amendmend'));
                return;
            }
            if(allUnchoosed()){
                layer.msg($translate.instant('autopsApp.opsClient.allUnchoosedAlert'));                
                return;
            }
            $scope.isSaving = true;
            if ($rootScope.ISDEMO && $rootScope.HAVENOAUTH('ROLE_BOSS')) {
                layer.msg($translate.instant('global.demoAlert'));
                $scope.isSaving = false;
                return;
            }
            if (!$scope.opsClient.bindedCompany) {
                $scope.opsClient.bindedCompanyId = null;
            }
            if($rootScope.account.companyId){
                if (!$scope.opsClient.code || !$scope.opsClient.companyName) {
                    layer.confirm($translate.instant('autopsApp.opsClient.nullAlert'), {
                        title: $translate.instant('autopsApp.opsClient.notFull'),
                        btn: [$translate.instant('global.confirm')]
                    }, function(index) {
                        layer.close(index);
                    });
                    $scope.isSaving = false;
                    return;
                }
                var myreg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
                for (var i = 0; i < $scope.opsClient.contactList.length; i++) {
                    if(!$scope.opsClient.contactList[i].attn){
                        layer.msg($translate.instant('autopsApp.opsClient.attnNullAlert'));
                        $scope.isSaving = false;
                        return;
                    }
                    if ($scope.opsClient.contactList[i].email) {
                        $scope.opsClient.contactList[i].email = $scope.opsClient.contactList[i].email.replace(/\s+/g, "");
                        if (!myreg.test($scope.opsClient.contactList[i].email)) {
                            var alertContent = $scope.opsClient.contactList[i].email + ": " + $translate.instant('global.messages.validate.email.invalid');
                            $rootScope.OPS_ALERT(alertContent);
                            $scope.isSaving = false;
                            return;
                        }
                    }
                    if ($scope.opsClient.contactList[i].cc) {
                        var ccArray = $scope.opsClient.contactList[i].cc.split(";");
                        for (var j = 0; j < ccArray.length; j++) {
                            ccArray[j] = ccArray[j].replace(/\s+/g, "");
                            if (!myreg.test(ccArray[j]) && ccArray[j] != "") {
                                var alertContent = ccArray[j] + ": " + $translate.instant('global.messages.validate.email.invalid');
                                $rootScope.OPS_ALERT(alertContent);
                                $scope.isSaving = false;
                                return;
                            }
                        }
                    }
                }
            }

            if (!$scope.opsClient.checkByName) {
                $scope.opsClient.checkById = null;
            }
            $scope.initVender();
            if ($scope.opsClient.id != null) {
                OpsVender.update($scope.opsClient, onSaveSuccess, onSaveError);
            } else {
                OpsVender.save($scope.opsClient, onSaveSuccess, onSaveError);
            }
        };
        var onSaveError = function (error) {
            $scope.isSaving = false;
            $rootScope.ALERT_ERROR(error);            
        };
        var allUnchoosed = function(){
            return !$scope.opsClient.isClient && !$scope.opsClient.isColoader && !$scope.opsClient.isAgent && !$scope.opsClient.isTrucker && !$scope.opsClient.isCustom 
                && !$scope.opsClient.isTrader && !$scope.opsClient.isWarehouse && !$scope.opsClient.isFumigation && !$scope.opsClient.isInsurance;
        }

        $scope.salesFilter = function(item) {
            var authorities = ["ROLE_SALES", "ROLE_CS", "ROLE_BOSS"];
            for (var i = 0; i < authorities.length; i++) {
                if (item.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }
            return false;
        };

        $scope.marketFilter = function(item) {
            if (!item.authorities || $rootScope.account.companyId == 1) {
                return true;
            }
            return item.authorities.indexOf("ROLE_MARKET") !== -1;
        };


        $scope.generateBlShow = function(shipper) {
            shipper.companyName = $scope.opsClient.companyNameEn;
            shipper.isShipper = true;
            shipper.blShow = angular.uppercase($scope.opsClient.companyNameEn) + "\n" +
                angular.uppercase($scope.opsClient.address) + "\n" +
                "TEL: " + angular.uppercase($scope.opsClient.tel) + "\n" +
                "FAX: " + angular.uppercase($scope.opsClient.fax);
        };
        $rootScope.loadClerks();

        $scope.contactMore = function(contact) {
            contact.contactMore = !contact.contactMore;
        }
        $scope.addContact = function() {
            $scope.opsClient.contactList.push({});
        };
        $scope.invoiceInfo = function() {
            $scope.opsClient.invoiceInfos.push({});
        };

        $scope.delContact = function(contact) {
            if($scope.opsClient.contactList.length == 1){
                layer.msg($translate.instant('autopsApp.opsClient.contactNullAlert'));
                return;
            }
            if(contact.userId){
                layer.msg($translate.instant('autopsApp.opsClient.delContactForbidden'));
                return;
            }

            if (contact.id != null) {
                OpsShipper.deleteContact({
                    id: contact.id
                });
            };
            var index = $scope.opsClient.contactList.indexOf(contact);
            if (index > -1) {
                $scope.opsClient.contactList.splice(index, 1);
            };
        };
        $scope.delInvoiceInfo = function(invoiceInfo) {
            if (invoiceInfo.id != null) {
                InvoiceInfo.delete({
                    id: invoiceInfo.id
                });
            };
            var index = $scope.opsClient.invoiceInfos.indexOf(invoiceInfo);
            if (index > -1) {
                $scope.opsClient.invoiceInfos.splice(index, 1);
            };
        };
        $scope.locations = [];
        $scope.getAllLocation = function() {
            OpsVender.getAllLocation(function(result) {
                $scope.locations = result;
            });
        };
        
        $scope._onAgentSelected = function(data) {
            $scope.opsClient.agentVid = data.id;
            $scope.opsClient.agentName = data.companyName;
        }

        $scope._onGroupSelected = function(data) {
            $scope.opsClient.groupId = data.id;
            $scope.opsClient.groupName = data.companyName;
        }

        $scope.shareClient = function() {
            $uibModal.open({
                templateUrl: 'app/entities/share-client/share-client-dialog.html',
                controller: 'ShareClientDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return $scope.opsClient;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('shareClient');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {

            }, function() {

            });
        }

        $scope.updateSales = function(item) {
            $scope.opsClient.createdBy = item.id;
        };
        $scope.updateCus = function(item) {
            $scope.opsClient.cusId = item.id;
        };
        $scope.updateCheckBy = function(item) {
            $scope.opsClient.checkById = item.id;
        };
        $scope.setAudited = function(audied) {
            if ($rootScope.HAVENOAUTH('AU_OBJ_AUDIT')) {                
                $scope.opsClient.audited = !audied;
                $rootScope.FORBIDDEN_BY_ALERT('AU_OBJ_AUDIT');   
                return;
            }
            if (audied) {
                $scope.opsClient.auditor = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            } else {
                $scope.opsClient.auditor = null;
            }
        }

        $scope.setLocked = function(isLocked) {
            if ($rootScope.HAVENOAUTH('AU_OBJ_LOCK')) {
                $scope.opsClient.isLocked = !isLocked;
                $rootScope.FORBIDDEN_BY_ALERT('AU_OBJ_LOCK');   
            }
        }

        $scope.shiftSales = function() {
            if($scope.opsClient.isLocked){
                layer.msg($translate.instant('autopsApp.opsClient.isLocked'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/opsVender/change-sales.html',
                controller: 'ChangeSalesController',
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return $scope.opsClient;
                    }]
                }
            }).result.then(function(result) {
                $scope.opsClient.creater = result.creater;
                $scope.opsClient.createdBy = result.createdBy;
            }, function() {});
        }
        $scope.shipmentsReport = function() {
            if (!angular.equals($scope.opsClient, clientCopy)) {
                layer.confirm($translate.instant('global.notSavedAlert'), {
                    btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
                }, function(index) {
                    $rootScope.id = $scope.opsClient;
                    $state.go('profit');
                    layer.close(index);
                    $uibModalInstance.dismiss('cancel');
                }, function() {});
                return;
            }
            $rootScope.id = $scope.opsClient;
            $state.go('profit');
            $uibModalInstance.dismiss('cancel');
        }

        $scope.textPop = function(obj, fd) {
            var entity = {};
            entity.isCn = true;
            entity.content = obj[fd];
            $uibModal.open({
                templateUrl: 'app/entities/ops-change-log/ops-change-log-delete-dialog.html',
                controller: 'OpsChangeLogDeleteController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                obj[fd] = result;
            }, function() {});
        }

        $scope.groupCompanys = [];
        $scope.loadCompanys = function() {
            if ($scope.groupCompanys && $scope.groupCompanys.length > 0) {
                return;
            }
            OpsCompany.getBindCompanys(function(result) {
                $scope.groupCompanys = result;
            })
        };
        $scope.updateBindedCompany = function(company) {
            $scope.opsClient.bindedCompanyId = company.id;
        };

        $scope.getByEmail = function() {
            if ($rootScope.HAVENOAUTH('ROLE_BRANCH')) {
                layer.msg($translate.instant('global.forbiddenBranch'));
                return;
            }
            // if (!$scope.opsClient.email) {
            //     layer.msg($translate.instant('autopsApp.opsClient.email_inuse'));
            //     return;
            // }

            layer.prompt({
              formType: 0,
              value: null,
              title: $translate.instant('autopsApp.opsClient.salesEmail'),
              area: ['280px', '160px'] 
            }, function(value, index, elem){
                layer.close(index);
                OpsCompany.getByEmail({
                    email: value
                }, function(result) {
                    $scope.opsClient.bindedCompany = result.companyName;
                    $scope.opsClient.bindedCompanyId = result.id;
                    if (!result.id) {
                        layer.msg($translate.instant('autopsApp.opsClient.salesEmailAlert'));
                        return;
                    }
                    else{
                        layer.msg($translate.instant('entity.action.loadSuccess'));
                    }
                }, function(error) {
                    layer.msg($translate.instant('autopsApp.opsClient.salesEmailAlert'));
                })
            });
        };

        
        $scope.notifySales = function() {
            layer.confirm($translate.instant('autopsApp.opsClient.notifySalesTips'), {
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                OpsVender.notifySales($scope.opsClient, function() {
                    layer.msg($translate.instant('global.msgSent'));
                }, function(error) {
                    layer.msg($translate.instant('global.send_fail'));
                });
                layer.close(index);
            }, function() {});
        }
        $scope.defaultPicChange = function(contact) {
            if(contact.defaultPic){
                angular.forEach($scope.opsClient.contactList, function(data){
                    if(data != contact){
                        data.defaultPic = false;
                    }
                })
            }
        }
        $scope.autoCharge = function() {
            $uibModal.open({
                templateUrl: 'app/entities/opsFactory/opsFactory-delete-dialog.html',
                controller: 'OpsFactoryDeleteController',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [ function() {
                        return angular.fromJson($scope.opsClient.autoCharge);
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('booking');
                        $translatePartialLoader.addPart('opsTemplate');                        
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                $scope.opsClient.autoCharge = angular.toJson(result);
            }, function() {
            })
        }
        $scope.checkIdCode = function() {
            if(!$scope.opsClient.idCode || $scope.opsClient.idCode == clientCopy.idCode){
                return;
            }
            OpsVender.checkIdCode({
                id: $scope.opsClient.id,
                companyId: $rootScope.account.companyId,
                idCode: $scope.opsClient.idCode
            }, function() {
            }, function(error) {
                $scope.opsClient.idCode = null;
                layer.msg($translate.instant('autopsApp.opsClient.idCodeAlert'));
            });
        }


        $scope.importCustomer = function() {
            $scope.isSaving = true;
            ApiErp.importCustomer($scope.opsClient, onSaveSuccess, function(error){
                $scope.isSaving = false;
                if(error && error.data && error.data.description){
                    $rootScope.DOWNLOAD_TXT(error.data.description, $scope.opsClient.companyName + "_requestBody.txt");
                }
                else {
                    layer.msg($translate.instant('global.send_fail'));
                }
            });
        }

        $scope.changeCompany = function(companyId) {    
            if(!companyId){return;}
            if (!angular.equals($scope.opsClient, clientCopy)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if ($rootScope.HAVENOAUTH('TRANSFER_STMT_OBJ')) {
                $rootScope.FORBIDDEN_BY_ALERT('TRANSFER_STMT_OBJ');
                return;
            }
            $scope.isSaving = true;
            OpsVender.changeCompany({
                id: $scope.opsClient.id,
                companyId: companyId
            }, function(result){
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
                $uibModalInstance.dismiss('cancel');         
            }, function(error){
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submittedfailed'));
            })
        };

        $scope.initClientWmsCharges = function() {    
            if ($rootScope.HAVENOAUTH('ROLE_BOSS')){
                layer.msg($translate.instant('global.forbiddenBoss'));
                return;
            }
            layer.confirm("确认要初始化仓储计费吗？", {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.load(1, {shade: 0.3});
                OpsVender.initClientWmsCharges({id: $scope.opsClient.id}, function(){
                    $rootScope.OPS_ALERT($translate.instant('global.messages.submitted'));
                    layer.closeAll(); 
                }, $rootScope.ALERT_ERROR);
            }, function() {
                return;
            });
        }
        $scope.autoCreditRating = function() {    
            if ($rootScope.HAVENOAUTH('ROLE_BOSS')){
                layer.msg($translate.instant('global.forbiddenBoss'));
                return;
            }
            layer.confirm("确认要手动刷新客户信用评级吗？", {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                if(!$rootScope.IS_HH($rootScope.account.companyId)){
                    layer.closeAll(); 
                    layer.msg($translate.instant('global.serviceNotSupported'));
                    return;
                }
                layer.load(1, {shade: 0.3});
                OpsVender.autoCreditRating({id: $scope.opsClient.id}, function(result){
                    layer.closeAll(); 
                    $scope.opsClient.creditRating = result.creditRating;
                    $rootScope.OPS_ALERT(result.remarks);                    
                }, $rootScope.ALERT_ERROR);
            }, function() {
                return;
            });
        }


    }
})();