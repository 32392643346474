(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GuideRatesDialogController', GuideRatesDialogController);

    GuideRatesDialogController.$inject = ['$rootScope', '$scope', '$timeout', '$translate', '$uibModalInstance', '$uibModal',
    'DateUtils', 'GuideRates', 'entity', 'Guide', 'OpsExchangeRate', 'DataUtils', 'OpsVender'];

    function GuideRatesDialogController($rootScope, $scope, $timeout, $translate, $uibModalInstance, $uibModal,
        DateUtils, GuideRates, entity, Guide, OpsExchangeRate, DataUtils, OpsVender) {

        $scope.guide = entity;

        $scope.profit = {};
        $scope.costTotalList = [];
        $scope.sellingTotalList = [];
        $scope.profitList = [];

        $timeout(function (){
            if($rootScope.HAVENOAUTH('ROLE_MARKET')){
                $rootScope.FORBIDDEN_BY_ALERT('ROLE_MARKET');
                $uibModalInstance.dismiss('cancel');
            }
            if(!$scope.guide.submited && !$scope.guide.deliveryGuideTime){
                layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                $uibModalInstance.dismiss('cancel');
            }
            else if($scope.guide.id){
                loadGuide();
            }
        })
        var getTotal = function() {
            $scope.costTotalList = [];
            $scope.sellingTotalList = [];
            $scope.profitList = [];
            var currencys = [];
            angular.forEach($scope.guide.guideRates, function(data) {
                if (data.payCurrency && currencys.indexOf(data.payCurrency) === -1) {
                    currencys.push(data.payCurrency);
                }
            })
            var cost = 0;
            var selling = 0;
            var profit = 0;
            var profitTtl = 0;
            angular.forEach(currencys, function(currency) {
                cost = 0;
                selling = 0;
                profit = 0;
                angular.forEach($scope.guide.guideRates, function(rate) {
                    if (rate.payCurrency == currency && rate.payAmount) {
                        if (rate.isPay) {
                            cost = cost + rate.payAmount;
                            if(rate.exchangeRate){
                                profitTtl = profitTtl - rate.payAmount * rate.exchangeRate;
                            }
                            else {
                                profitTtl = profitTtl - rate.payAmount * exchangeRates[currency];
                            }
                        } else {
                            selling = selling + rate.payAmount;
                            if(rate.exchangeRate){
                                profitTtl = profitTtl + rate.payAmount * rate.exchangeRate;
                            }
                            else {
                                profitTtl = profitTtl + rate.payAmount * exchangeRates[currency];
                            }
                        }
                    }
                })
                $scope.costTotalList.push({
                    currency: currency,
                    total: DataUtils.round(cost, 2)
                });
                $scope.sellingTotalList.push({
                    currency: currency,
                    total: DataUtils.round(selling, 2)
                });
                profit = selling - cost;
                $scope.profitList.push({
                    currency: currency,
                    total: DataUtils.round(profit, 2)
                });
            })
            $scope.profit.ammount = DataUtils.round(profitTtl, 2);
            if (!$scope.profit.currency) {
                $scope.profit.currency = $rootScope.account.baseCurrency;
            }
        }
        var exchangeRates = null;
        var updateTotal = function() {
            if (exchangeRates){
                getTotal();
                return;
            }
            OpsExchangeRate.getExchangeRatesMap({
                    date: DateUtils.convertLocalDateToServer($scope.guide.etd)
                }, function(result) {
                exchangeRates = result;
                getTotal();
            })
        }
        var loadGuide = function() {
            $scope.isSaving = true;
            Guide.getAuditInfo({
                guideId: $scope.guide.id
            }, function(result) {
                $scope.guide = result;
                updateTotal();
                $scope.isSaving = false;
            }, function() {

            });
        }


        $scope.hidenSelling = function() {
            if (!$scope.guide.hidenSelling){
                return false;
            }
            if (!$rootScope.account || angular.equals($rootScope.account, {})){
                return true;
            }
            return $rootScope.account.authorities.indexOf("FUN_VIEWSELLING") == -1;
        };
        $scope.hideCost = function() {
            if (!$scope.guide.hidenCost){
                return false;
            }
            if (!$rootScope.account || angular.equals($rootScope.account, {})){
                return true;
            }
            return $rootScope.account.authorities.indexOf("FUN_VIEWCOST") == -1;
        };


        var onSaveSuccess = function (result) {
            $scope.guide.timeStamp = result.timeStamp;
            $uibModalInstance.close(result);
            layer.msg($translate.instant('global.messages.submitted'));
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            if (!$scope.guide.checkStatus) {
                return;
            }
            $scope.isSaving = true;
            $scope.guide.lastModifiedTime = new Date();
            Guide.setAudit($scope.guide, onSaveSuccess, onSaveError);
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope._onColoaderSelected = function(data) {
            OpsVender.getDTO({
                id: data.id
            }, function(result) {
                if (!result.audited && result.coloaderMustAudit) {
                    $scope.guide.bookingToName = null;
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.notAuditedAlert'));
                    return;
                }
                $scope.guide.bookingToId = result.venderId;
                $scope.guide.bookingToName = result.companyName;
                $scope.guide.bookingToAddress = result.address;
                $scope.guide.bookingToAttn = result.attn;
                $scope.guide.bookingToTel = result.tel;
                $scope.guide.bookingToFax = result.fax;
                $scope.guide.bookingToMob = result.mob;
                $scope.guide.bookingToEmail = result.email;
                $scope.guide.bookingToCc = result.cc;
                $scope.guide.payable = result.paymentTerm;
                $scope.guide.payableDays = result.creditDays;
                $scope.guide.payableRemarks = result.creditRemarks;
            });
        }

        $scope.lockRate = function(rate) {
            if (rate.locked || rate.actual || rate.isHidden){
                return;
            }
            GuideRates.setAudit({id: rate.id}, function(result) {
                rate.audited = result.audited;
                if (rate.audited){
                    layer.msg($translate.instant('entity.action.locked'));
                }
                else {
                    layer.msg($translate.instant('entity.action.unlocked'));
                }
            });
        }

        $scope.lockRatesAll = function(isPay) {
            angular.forEach($scope.guide.guideRates, function(data){
                if(data.isPay == isPay && !data.audited){
                    $scope.lockRate(data);
                }
            })
        }


        $scope.textPop = function(text, fd) {
            $uibModal.open({
                templateUrl: 'app/entities/ops-change-log/ops-change-log-delete-dialog.html',
                controller: 'OpsChangeLogDeleteController',
                controllerAs: 'vm',
                // backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return text;
                    }]
                }
            }).result.then(function(result) {
                $scope.guide[fd] = result;
            }, function() {});
        }

        $scope.guideDialog = function(guide) {
            $rootScope.GUIDEACTIVETAB = 0;
            $rootScope.id = "view";
            if(!guide.isExport){
                $uibModal.open({
                    templateUrl: 'app/entities/import/import-dialog.html',
                    controller: 'ImportDialogController',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: [function() {
                            return Guide.get({id : guide.id}).$promise;
                        }]
                    }
                }).result.then(function(result) {
                    loadGuide();
                }, function(result) {

                });
            }
            else {
                var htmlPath = 'app/entities/guide/sea-dialog.html';
                var controllerName = 'SeaDialogController';
                if(guide.shipmentType == "AIR"){
                    var htmlPath = 'app/entities/guide/air-dialog.html';
                    var controllerName = 'AirDialogController';
                }
                else if (guide.shipmentType == "EXPRESS" || guide.shipmentType == "FBAAIR" || guide.shipmentType == "FBASEA"
                    || guide.shipmentType == "WMS" || guide.shipmentType == "LOCAL" || guide.shipmentType == "LOCALKG"){
                    var htmlPath = ($rootScope.HAVEAUTH && $rootScope.HAVEAUTH("FB_WF_VIEW"))?'app/entities/guide/fba-role-dialog.html':'app/entities/guide/fba-dialog.html';
                    var controllerName = 'FbaDialogController';
                }
                $uibModal.open({
                    templateUrl: htmlPath,
                    controller: controllerName,
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: [function() {
                            return Guide.get({id : guide.id}).$promise;
                        }]
                    }
                }).result.then(function(result) {
                    loadGuide();
                }, function(result) {

                });
            }
        }

        $scope.onChannelSelected = function(carrier) {
            $scope.guide.booking.carrierCode = carrier.code;
            $scope.guide.booking.carrierFullName = carrier.name;
            if(carrier.volCoe || carrier.volumeShare){
                if(carrier.volCoe){
                    $scope.guide.booking.divVolBy = carrier.volCoe;
                }
                if(carrier.volumeShare){
                    $scope.guide.booking.volumeShare = carrier.volumeShare;
                }
            }
        }
        $scope.lockRateOne = function() {
            if (!$scope.guide.ratesLocked && $rootScope.account.authorities.indexOf("AU_RATE_LOCK") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_RATE_LOCK');
                return;
            }

            if ($scope.guide.ratesLocked && $rootScope.account.authorities.indexOf("AU_RATE_UNLOCK") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_RATE_UNLOCK');
                return;
            }

            $scope.isSaving = true;
            Guide.lockRateOne({
                guideId: $scope.guide.id,
                ratesLocked: !$scope.guide.ratesLocked
            }, function(result) {

                layer.msg($translate.instant('global.messages.submitted'));
                loadGuide();
            }, function(result) {
                $scope.isSaving = false;
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    icon: 3,
                    title: $translate.instant('global.messageInfo'),
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {});
            });
        };
    }
})();
