(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('HfBill', HfBill);

    HfBill.$inject = ['$resource', 'DateUtils'];

    function HfBill ($resource, DateUtils) {
        var resourceUrl =  'api/hf-bills/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateFrom = DateUtils.convertLocalDateFromServer(data.dateFrom);
                        data.dateTo = DateUtils.convertLocalDateFromServer(data.dateTo);
                        data.checkDate = DateUtils.convertLocalDateFromServer(data.checkDate);
                        data.time = DateUtils.convertDateTimeFromServer(data.time);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    data.checkDate = DateUtils.convertLocalDateToServer(data.checkDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateFrom = DateUtils.convertLocalDateFromServer(data.dateFrom);
                        data.dateTo = DateUtils.convertLocalDateFromServer(data.dateTo);
                        data.checkDate = DateUtils.convertLocalDateFromServer(data.checkDate);
                        data.time = DateUtils.convertDateTimeFromServer(data.time);
                    }
                    return data;
                }
            },
            'getCompanyBill': { 
                method: 'GET', 
                url:'api/hf-bills/getCompanyBill',
                isArray: true
            },    
            'advancedSearch': {
                method:'PUT',
                url:'api/hf-bills/advancedSearch',
                isArray:true,
                transformRequest: function (data) {
                    data.etdFrom = DateUtils.convertLocalDateToServer(data.etdFrom);
                    data.etdEnd = DateUtils.convertLocalDateToServer(data.etdEnd);                
                    return angular.toJson(data);
                }
            },            
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    data.checkDate = DateUtils.convertLocalDateToServer(data.checkDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateFrom = DateUtils.convertLocalDateFromServer(data.dateFrom);
                        data.dateTo = DateUtils.convertLocalDateFromServer(data.dateTo);
                        data.checkDate = DateUtils.convertLocalDateFromServer(data.checkDate);
                        data.time = DateUtils.convertDateTimeFromServer(data.time);
                    }
                    return data;
                }
            },
            'audit':{
                method: 'POST',
                url:'api/hf-bills/audit',
                transformRequest: function (data) {
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    data.checkDate = DateUtils.convertLocalDateToServer(data.checkDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateFrom = DateUtils.convertLocalDateFromServer(data.dateFrom);
                        data.dateTo = DateUtils.convertLocalDateFromServer(data.dateTo);
                        data.checkDate = DateUtils.convertLocalDateFromServer(data.checkDate);
                        data.time = DateUtils.convertDateTimeFromServer(data.time);
                    }
                    return data;
                }
            }
        });
    }
})();
