(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GuideDocDeleteController', GuideDocDeleteController);

    GuideDocDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'User'];
    
	function GuideDocDeleteController($scope, $uibModalInstance, entity, User) {

        $scope.guide = entity;
        // $scope.clear = function() {
        //     $uibModalInstance.dismiss('cancel');
        // };
        $scope.confirm = function() {
            $uibModalInstance.close();
        };
        $scope.updateSales = function(item) {
            $scope.guide.salesName = item.name;
            $scope.guide.salesId = item.id;
            $scope.guide.team = item.team;
            $scope.guide.branch = item.branch;
        };
        $scope.updateClientSales = function(item) {
            $scope.guide.creater = item.name;
            $scope.guide.createdBy = item.id;
            $scope.guide.team = item.team;
            $scope.guide.branch = item.branch;
        };

        $scope.salesFilter = function(item) {
            return item.authorities.indexOf("ROLE_SALES") !== -1;
        };
    }
})();
