(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('AccountInvoice', AccountInvoice);

    AccountInvoice.$inject = ['$resource','DateUtils'];
    
    function AccountInvoice($resource, DateUtils) {
        return $resource('api/accountInvoices/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'save': { 
                method:'POST',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.deadLine = DateUtils.convertLocalDateToServer(data.deadLine);
                    data.economicDate = DateUtils.convertLocalDateToServer(data.economicDate);
                    data.accountDate = DateUtils.convertLocalDateToServer(data.accountDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    data.deadLine = DateUtils.convertLocalDateFromServer(data.deadLine);
                    data.economicDate = DateUtils.convertLocalDateFromServer(data.economicDate);
                    data.accountDate = DateUtils.convertLocalDateFromServer(data.accountDate);
                    return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    data.deadLine = DateUtils.convertLocalDateFromServer(data.deadLine);
                    data.economicDate = DateUtils.convertLocalDateFromServer(data.economicDate);
                    data.accountDate = DateUtils.convertLocalDateFromServer(data.accountDate);
                    return data;
                }
            },
            'createAccountInvoice': { 
                url:'api/accountInvoices/createAccountInvoice',
                method:'POST',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.accountInvoice.deadLine = DateUtils.convertLocalDateToServer(data.accountInvoice.deadLine);
                    data.accountInvoice.economicDate = DateUtils.convertLocalDateToServer(data.accountInvoice.economicDate);
                    data.accountInvoice.accountDate = DateUtils.convertLocalDateToServer(data.accountInvoice.accountDate);
                    return angular.toJson(data);
                }
            },
            'printEInvoices':{
                method: 'POST',
                url:'api/accountInvoices/printEInvoices',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'getDnCn': {
                method: 'GET',
                url:'api/accountInvoices/getDnCn'
            },
            'getVenderUnpaidList': {
                method: 'GET',
                url:'api/accountInvoices/getVenderUnpaidList',
                isArray: true
            },            
            'updateTracking': {
                method: 'GET',
                url:'api/accountInvoices/updateTracking'
            },
            'getFilterPage': {
                method: 'POST',
                url:'api/accountInvoices/getFilterPage',
                isArray: true
            },
            'register': {
                method: 'GET',
                url:'api/invoiceAssistant/register'
            },
            'getInvoice': {
                method: 'POST',
                url:'api/invoiceAssistant/getInvoice'
            },
            'getGoodsCode': {
                method: 'GET',
                url:'api/invoiceAssistant/getGoodsCode',
                isArray: true
            },            
            'setManual': {
                method: 'GET',
                url:'api/accountPayments/setManual'
            },       
            'createInvoice': {
                method: 'POST',
                url:'api/invoiceAssistant/createInvoice'
            },
            'printInvoice': {
                method: 'POST',
                url:'api/invoiceAssistant/printInvoice'
            },
            'invoiceFile': {
                method: 'POST',
                url:'api/invoiceAssistant/invoiceFile'
            },
            'voidInvoice': {
                method: 'POST',
                url:'api/invoiceAssistant/voidInvoice'
            },
            'monitorData': {
                method: 'POST',
                url:'api/invoiceAssistant/monitorData'
            },   
            'invoicePurchaseQuery': {
                method: 'POST',
                url:'api/invoiceAssistant/invoicePurchaseQuery'
            },
            'registNewCompany': {
                method: 'POST',
                url:'api/invoiceAssistant/registNewCompany'
            },
            'connectedCheck': {
                method: 'GET',
                url:'api/invoiceAssistant/connectedCheck'
            },
            'commSearch': {
                method: 'GET',
                url:'api/invoiceAssistant/commSearch'
            },
            'companySearch': {
                method: 'GET',
                url:'api/invoiceAssistant/companySearch'
            }, 
            'invoiceQuery': {
                method: 'POST',
                url:'api/invoiceAssistant/invoiceQuery'
            }, 
            'findInvoice': {
                method: 'GET',
                url:'api/invoiceAssistant/findInvoice'
            },      
            'companyIllegal': {
                method: 'GET',
                url:'api/invoiceAssistant/companyIllegal'
            },                     
            'getNextInvoiceNum': {
                method: 'GET',
                url:'api/accountInvoices/getNextInvoiceNum'
            },
            'getGuides': {
                method: 'PUT',
                url:'api/accountInvoices/getGuides',
                isArray: true
            },
            'loadUnissuedInvoices': {
                method: 'GET',
                url:'api/accountInvoices/loadUnissuedInvoices',
                isArray: true
            },            
            'getByVoucherId': {
                method: 'GET',
                url:'api/getAccountInvoiceByVoucherId/:id',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    return data;
                }
            },
            'setAlertTag': {
                method: 'GET',
                url:'api/accountInvoices/setAlertTag',
                transformResponse: function (data) {
                    if(!data){return;}
                    data = angular.fromJson(data);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    return data;
                }
            },
            'getByDnNum': {
                method: 'GET',
                url:'api/getAccountInvoiceByDnNum',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    data.deadLine = DateUtils.convertLocalDateFromServer(data.deadLine);
                    data.economicDate = DateUtils.convertLocalDateFromServer(data.economicDate);
                    data.accountDate = DateUtils.convertLocalDateFromServer(data.accountDate);
                    return data;
                }
            },
            'getByDnNumOrVender': {
                method: 'GET',
                url:'api/accountInvoices/getAccountInvoiceByDnNumOrVender',
                isArray: true
            },
            'changeBillType': {
                method: 'GET',
                url:'api/accountInvoices/changeBillType'
            },
            'searchByDnNum': {
                method: 'POST',
                url:'api/accountInvoices/searchByDnNum',
                isArray: true
            },
            'doCom': {
                method: 'PUT',
                url:'api/accountInvoices/doCom',
                transformRequest: function (data) {
                    data.accountInvoice.deadLine = DateUtils.convertLocalDateToServer(data.accountInvoice.deadLine);
                    return angular.toJson(data);
                }
            },
            'getForCombine': {
                method: 'GET',
                url:'api/accountInvoices/getForCombine'
            },
            'getUnpaidList': {
                method: 'GET',
                url:'api/accountInvoices/getUnpaidList'
            },
            'dismissCom': {
                method: 'GET',
                url:'api/accountInvoices/dismissCom'
            },
            'getComList': {
                method: 'GET',
                url:'api/accountInvoices/getComList'
            },
            'searchByDnNumForPrint': {
                method: 'GET',
                url:'api/accountInvoices/searchByDnNumForPrint',
                isArray: true
            },
            'getAllByInvoice': {
                method:'GET',
                url:'api/accountInvoice/getAllByInvoice',
                isArray:true
            },  
            'getForInvoiceBatch': {
                method: 'GET',
                url:'api/accountInvoices/getForInvoiceBatch',
                isArray: true
            },
            'updateWithoutRates': {
                method:'PUT',
                url:'api/accountInvoiceWithoutRates'
            },
            'updateInvoiceNum': {
                method: 'PUT',
                url:'api/accountInvoices/updateInvoiceNum'
            },
            'update': { 
                method:'PUT',
                transformRequest: function (data) {
                    data.deadLine = DateUtils.convertLocalDateToServer(data.deadLine);
                    data.economicDate = DateUtils.convertLocalDateToServer(data.economicDate);
                    data.accountDate = DateUtils.convertLocalDateToServer(data.accountDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.deadLine = DateUtils.convertLocalDateFromServer(data.deadLine);
                    data.economicDate = DateUtils.convertLocalDateFromServer(data.economicDate);
                    data.accountDate = DateUtils.convertLocalDateFromServer(data.accountDate);                    
                    return data;
                }
            },
            'advanceSearchRequests': {
                method:'PUT',
                url:'api/accountInvoices/advanceSearchRequests',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.economicDateFM = DateUtils.convertLocalDateToServer(data.economicDateFM);
                    data.economicDateTO = DateUtils.convertLocalDateToServer(data.economicDateTO);
                    data.deadLineFM = DateUtils.convertLocalDateToServer(data.deadLineFM);
                    data.deadLineTO = DateUtils.convertLocalDateToServer(data.deadLineTO);
                    data.accountDateFM = DateUtils.convertLocalDateToServer(data.accountDateFM);
                    data.accountDateTO = DateUtils.convertLocalDateToServer(data.accountDateTO);
                    return angular.toJson(data);
                },
            },
            'generateReport':{
                method: 'POST',
                url:'api/accountInvoice/generateReport',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            }
        });
    }
})();
