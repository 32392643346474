
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpressCargoController',ExpressCargoController);

    ExpressCargoController.$inject = ['$uibModalInstance', '$rootScope', '$scope', '$translate', '$timeout', '$http', 
    'entity', 'ExpressParcel', 'DateUtils'];

    function ExpressCargoController($uibModalInstance, $rootScope, $scope, $translate, $timeout, $http, 
        entity, ExpressParcel, DateUtils) {
        var vm = this;
        vm.accountBind = vm.respObj = {};

        if(entity.id){
            vm.accountBind = entity;
        }
        else {
            vm.respObj = entity;
        }

        vm.clear = clear;
        vm.getOrders = getOrders;
        vm.saveOrders = saveOrders;
        vm.delOne = delOne;
        vm.initData = initData;
        vm.generatePickingTicket = generatePickingTicket;
        vm.getPickingTicket = getPickingTicket;
        vm.printLabels = printLabels;
        vm.generateLabels = generateLabels;
        vm.initRequest = initRequest;
        vm.delByWarehosueCode = delByWarehosueCode;
        vm.skuFilter = skuFilter;
        
        vm.lastOrderId;
        var tokenGot = false;
        vm.limit = null;

        vm.released = false;
        vm.fulfillmentByPlatform = false;

        vm.dateTo = new Date();
        vm.dateFrom = new Date();
        vm.dateFrom.setTime(vm.dateFrom.getTime()-24*60*60*1000);
        
        function initRequest () {
            // if(vm.accountBind.type == "Shopify"){
                // ExpressParcel.getLastOrder({}, vm.accountBind, function(result){
                //     if(result){
                //         vm.lastOrderId = result.orderId;
                //     }
                //     else {
                //         vm.lastOrderId = 0;
                //     }                    
                // });
            // }
            // else 
            if(vm.accountBind.type == "Walmart" || vm.accountBind.type == "Ebay"){
                vm.released = false;
                layer.msg("初始化授权...", {
                    shade: 0.3,
                    time: 2000000
                });
                ExpressParcel.getToken({}, vm.accountBind, function(result){
                    layer.closeAll();
                    vm.limit = 200;
                    tokenGot =  true;
                    layer.msg($translate.instant('global.init_success'));
                }, function(error){        
                    layer.closeAll();            
                    $rootScope.OPS_ALERT($translate.instant('global.init_fail'));                    
                });
            }
            else if (vm.accountBind.type == "MfyCang"){
                vm.dateTo = DateUtils.getNewDay(DateUtils.convertLocalDateToServer(new Date()), 1);
            }
        }
        $timeout(function() {            
            initRequest();     
            layer.closeAll();  
        })

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }        
        function delOne (index) {
            vm.respObj.orderList.splice(index, 1);
            vm.respObj.resultsReturned = vm.respObj.orderList.length;
        }
        $scope.datePickerOpenStatus = {};
        $scope.dayFormat = "yyyy-MM-dd";
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }      

        var resultCopy;
        function onGetSuccess (result) {
            layer.closeAll();
            $scope.isSaving = false;
            resultCopy = angular.copy(result);
            if(!resultCopy || !resultCopy.orderList || resultCopy.orderList.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            initData();
        }

        function onGetError (error) {
            $scope.isSaving = false;
            layer.closeAll();
            if(error && error.data && error.data.description){
                $rootScope.OPS_ALERT(error.data.description);
            }  
            else{
                layer.msg($translate.instant('global.syn_fail'));
            }            
        }

        function getOrders () {
            layer.msg("获取订单中...", {
              shade: 0.3,
              time: 2000000
            });
            $scope.isSaving = true;
            if(vm.accountBind.type == "MfyCang"){
                ExpressParcel.getMfycangOrders({
                    dateFrom: DateUtils.convertLocalDateToServer(vm.dateFrom),
                    dateTo: DateUtils.convertLocalDateToServer(vm.dateTo)
                }, vm.accountBind, onGetSuccess, onGetError);
            }
            else if(vm.accountBind.type == "Overstock"){
                ExpressParcel.getOverstockOrders({
                    dateFrom: DateUtils.convertLocalDateToServer(vm.dateFrom),
                    dateTo: DateUtils.convertLocalDateToServer(vm.dateTo)
                }, vm.accountBind, onGetSuccess, onGetError);
            }
            else if(vm.accountBind.type == "Shopify"){
                ExpressParcel.shopifyorders({
                    limit: vm.limit,
                    lastUpdatedAfter: vm.dateFrom
                }, vm.accountBind, onGetSuccess, onGetError);
            }
            else if(vm.accountBind.type == "Walmart"){
                if(!tokenGot){
                    layer.closeAll();
                    layer.msg($translate.instant('global.init_fail'));
                    $scope.isSaving = false;
                    return;
                }                   
                ExpressParcel.walmartOrders({
                    released: vm.released,
                    limit: vm.limit,
                    createdStartDate: vm.dateFrom,
                    createdEndDate: vm.dateTo
                }, vm.accountBind, onGetSuccess, onGetError);
            }
            else if(vm.accountBind.type == "Ebay"){
                if(!tokenGot){
                    layer.closeAll();
                    layer.msg($translate.instant('global.init_fail'));
                    $scope.isSaving = false;
                    return;
                }
                ExpressParcel.ebayOrders({
                    limit: vm.limit,
                    createdStartDate: vm.dateFrom,
                    createdEndDate: vm.dateTo
                }, vm.accountBind, onGetSuccess, onGetError);
            } 
            else if(vm.accountBind.type == "AmazonSftp" || vm.accountBind.type == "wayfair"){      
                if(!vm.dateFrom){
                    layer.msg($translate.instant('stats.stat.lackTimeDateFrom'));
                    return;
                }   
                ExpressParcel.getFtpOrders({
                    startTime: DateUtils.convertDateTimeToString(vm.dateFrom),
                    endTime: DateUtils.convertDateTimeToString(vm.dateTo)
                }, vm.accountBind, onGetSuccess, onGetError);
            }
            else if(vm.accountBind.type == "Amazon" || vm.accountBind.type == "AmazonSp"){
                if(vm.amazonOrderId){
                    var len = vm.amazonOrderId.split(/\s+/).length;
                    if(len > 50){
                        $rootScope.OPS_ALERT($translate.instant('global.exMaxAmtAlert', {param: len, param2: 50}));
                        return;                        
                    }
                }
                ExpressParcel.amazonOrders({
                    amazonOrderId: vm.amazonOrderId,
                    createdStartDate: vm.dateFrom,
                    createdEndDate: vm.dateTo,
                    fulfillmentByPlatform: vm.fulfillmentByPlatform
                }, vm.accountBind, onGetSuccess, onGetError);
            } 
            else {
                var pushOrdersDTO = {};
                pushOrdersDTO.accountBind = vm.accountBind;
                pushOrdersDTO.dateFrom = DateUtils.convertLocalDateToServer(vm.dateFrom);
                pushOrdersDTO.dateTo = DateUtils.convertLocalDateToServer(vm.dateTo);

                pushOrdersDTO.startTime = DateUtils.convertDateTimeToString(vm.dateFrom);
                pushOrdersDTO.endTime = DateUtils.convertDateTimeToString(vm.dateTo);

                ExpressParcel.getEdiOrders(pushOrdersDTO, onGetSuccess, onGetError);
            }
        }

        function delByWarehosueCode (warehouseCode) {
            for(var i = resultCopy.orderList.length - 1; i >= 0; i --){
                if(resultCopy.orderList[i].warehouseCode == warehouseCode){
                    resultCopy.orderList.splice(i, 1);
                }
            } 
            vm.respObj = angular.copy(resultCopy);
        }

        vm.exRefunded = true;
        vm.exNoLabel = true;
        function initData () {
            vm.respObj = angular.copy(resultCopy);
            if(!vm.respObj || !vm.respObj.orderList){
                return;
            }
            for(var i = vm.respObj.orderList.length - 1; i >= 0; i --){
                if(vm.accountBind.type == "MfyCang" && vm.exRefunded && vm.respObj.orderList[i].state.indexOf("退款") != -1){
                    vm.respObj.orderList.splice(i, 1);
                    continue;
                }
                if(vm.accountBind.type == "MfyCang" && vm.exNoLabel && !vm.respObj.orderList[i].trackingNumber){
                    vm.respObj.orderList.splice(i, 1);
                    continue;
                }
            }            
            vm.respObj.orderList.sort(function(a,b){
                if(a.itemList 
                    && a.itemList.length > 0 
                    && b.itemList 
                    && b.itemList.length
                    && a.itemList[0].upcBarcode
                    && b.itemList[0].upcBarcode){
                    return a.itemList[0].upcBarcode>b.itemList[0].upcBarcode?1:-1;      
                }                           
            });

            if(vm.skuFilterType){
                layer.msg("开始比对SKU...", {
                      shade: 0.3,
                      time: 2000000
                    });
                ExpressParcel.bindedSkuCheck({
                    skuFilterType:vm.skuFilterType
                }, vm.respObj, function(result){
                    layer.closeAll();  
                    vm.respObj = result;
                    checkData();
                })
            }
            else {
                checkData();
            }            
        }

        function checkData(noShade) {
            if(!noShade){
                layer.msg("开始比对业务单...", {
                  shade: 0.3,
                  time: 2000000
                });
            }            
            $scope.isSaving = true;
            ExpressParcel.checkOrders({companyId: vm.accountBind.companyId}, vm.respObj.orderList, function(results){
                $scope.isSaving = false;
                if(!noShade){
                    layer.closeAll();  
                }                
                angular.forEach(vm.respObj.orderList, function(oldOne){                    
                    angular.forEach(results, function(newOne){
                        if(newOne.theThirdNumber == oldOne.theThirdNumber){
                            oldOne.expressParcel = newOne.expressParcel;
                        }
                    })
                })
                angular.forEach(resultCopy.orderList, function(oldOne){                    
                    angular.forEach(results, function(newOne){
                        if(newOne.theThirdNumber == oldOne.theThirdNumber){
                            oldOne.expressParcel = newOne.expressParcel;
                        }
                    })
                })
            }, function(){
                $scope.isSaving = false;
                if(!noShade){
                    layer.closeAll();  
                }  
            })
        }
        
        function saveOrders () {
            var reqObj = angular.copy(vm.respObj);
            if(!reqObj || !reqObj.orderList){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            //过滤掉已经生成的业务单
            for(var i = reqObj.orderList.length - 1; i >= 0; i --){
                if(reqObj.orderList[i].jobNum){
                    reqObj.orderList.splice(i, 1);
                    continue;
                }
            }
            if(reqObj.orderList.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            $scope.isSaving = true;
            layer.msg("创建订单中...", {
              shade: 0.3,
              time: 2000000
            });
            ExpressParcel.importOrders(reqObj, function(result) {
                layer.closeAll();  
                $scope.isSaving = false;
                checkData();
                initRequest(); 
                $rootScope.DOWNLOADTXT(result, "orderPickingResult.txt");                   
            }, function(error) {
                layer.closeAll();  
                $scope.isSaving = false;
                layer.msg($translate.instant('global.save_fail'));
            });
        }
        
        function getPickingTicket (pickingNum) {
            $scope.isSaving = true;
            var loadIndex = layer.load(1, {shade: 0.3});    
            ExpressParcel.getPickingTicket({
                pickingNum: pickingNum
            }, function(result) {
                layer.close(loadIndex); 
                $scope.isSaving = false;
                $rootScope.PICKINGTICKETPOP(result);
            }, function(error) {
                layer.close(loadIndex); 
                $scope.isSaving = false;
                layer.msg($translate.instant('global.noRecord'));
            });
        }

        function generatePickingTicket () {            
            var filtedParcels = getExpressParcels();
            if(!filtedParcels){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            for(var i = filtedParcels.length - 1; i >= 0; i --){
                if(!filtedParcels[i] || !filtedParcels[i].id || filtedParcels[i].pickingNum){
                    filtedParcels.splice(i, 1);
                    continue;
                }
            }
            if(filtedParcels.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }   
            var loadIndex = layer.load(1, {shade: 0.3});
            ExpressParcel.generatePickingTicket(filtedParcels, function(result) {
                layer.close(loadIndex);
                checkData(true);
                layer.confirm($translate.instant('autopsApp.expressParcel.cratedPickingTicketAlert', {param: result.pickingNum}), {
                    icon: 3,
                    title: $translate.instant('global.alert_title'),
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                }, function(index) {
                    layer.close(index);
                    $rootScope.PICKING_TICKET_ALERT(result);
                    getPickingTicket(result.pickingNum);
                }, function() {
                    $rootScope.PICKING_TICKET_ALERT(result);
                });
            }, $rootScope.ALERT_ERROR);
        }

        function printLabels () {
            var loadIndex = layer.load(1, {shade: 0.3});
            $scope.isSaving = true;
            ExpressParcel.printLabels(getExpressParcels(), function(result) {
                layer.closeAll();
                $scope.isSaving = false;
                checkData(true);
                $rootScope.PRINTBYTES(result.data);                
            }, function(error) {
                layer.closeAll();
                $scope.isSaving = false;
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        function generateLabels () {
            layer.msg("Generating label(s)...", {
              shade: 0.3,
              time: 2000000
            });
            $scope.isSaving = true;
            ExpressParcel.generateLabels(getExpressParcels(), function(result) {                
                layer.closeAll();
                $scope.isSaving = false;
                $rootScope.OPS_ALERT($translate.instant('autopsApp.expressParcel.generateLabelsAlert', {count: result.count, takeTime: result.takeTime}));
            }, function(error) {
                layer.closeAll();
                $scope.isSaving = false;
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }       


        $scope.generateOneLabel = function(expressParcel) {
            layer.msg("Generating label...", {
              shade: 0.3,
              time: 2000000
            });
            $scope.isSaving = true;
            ExpressParcel.generateOneLabel({id: expressParcel.id}, function(result) {
                layer.closeAll();
                $scope.isSaving = false;
                angular.copy(result, expressParcel);
                $rootScope.PDFPOP(result.labelUrl, "label");
            }, function(error){
                layer.closeAll();
                $scope.isSaving = false;
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                }  
            });
        }

        vm.skuFilterType = null;
        function skuFilter (type) {
            vm.skuFilterType = type;
            initData()
        }

        function getExpressParcels () {
            var expressParcels = [];
            angular.forEach(vm.respObj.orderList, function(data){
                expressParcels.push(data.expressParcel);
            })
            return expressParcels;
        }

        $scope.getCloseouts = function (){
            $scope.isSaving = true;      
            ExpressParcel.getCloseouts(getExpressParcels(), function(result){
                $scope.isSaving = false;
                $rootScope.PRINTBYTES(result.data);
            }, function(error){
                $scope.isSaving = false;
                layer.msg($translate.instant('global.noRecord'));
            })
        }

        $scope.rePickingUp = function (){
            $scope.isSaving = true;      
            ExpressParcel.rePickingUp(getExpressParcels(), function(result){
                $scope.isSaving = false;
                angular.forEach(result, function(newOne){
                    angular.forEach(vm.respObj.orderList, function(oldOne){
                        if(oldOne.expressParcel && newOne.id == oldOne.expressParcel.id){
                            angular.copy(newOne, oldOne.expressParcel);
                        }
                    })
                })
            }, function(error){
                $scope.isSaving = false;
                layer.msg($translate.instant('global.requestFail'));
            })
        }
        $scope.lockAndChargeAll = function () {
            if ($rootScope.HAVENOAUTH('AU_BILL_CLOSE')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_CLOSE');
                return;
            }
            $scope.isSaving = true;
            ExpressParcel.lockAndChargeAll(getExpressParcels(), function(result){
                angular.forEach(vm.respObj.orderList, function(data){
                    if(data.expressParcel){
                        data.expressParcel.isClosed = true;
                    }                    
                })
                $scope.isSaving = false;
                layer.msg($translate.instant('global.save_success'));
            }, function(result){
                $scope.isSaving = false;
                layer.msg($translate.instant('global.save_fail'));
            })   
        }
        $scope.unlockAndChargeAll = function () {
            if ($rootScope.HAVENOAUTH('AU_BILL_OPEN')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_OPEN');
                return;
            }
            $scope.isSaving = true;
            ExpressParcel.unlockAndChargeAll(getExpressParcels(), function(result){
                angular.forEach(vm.respObj.orderList, function(data){
                    if(data.expressParcel){
                        data.expressParcel.isClosed = false;
                    }                    
                })
                $scope.isSaving = false;
                layer.msg($translate.instant('global.save_success'));
            }, function(result){
                $scope.isSaving = false;
                layer.msg($translate.instant('global.save_fail'));
            })   
        }
        $scope.orderAcknowledgement = function (expressParcel){
            layer.msg("Sending acknowledgement status to eCommerce platform...", {
              shade: 0.3,
              time: 2000000
            });
            var expressParcels = [];
            if(expressParcel){
                expressParcels.push(expressParcel);
            }
            else {
                expressParcels = getExpressParcels();
            }
            ExpressParcel.orderAcknowledgement(expressParcels, function(resultList){
                layer.closeAll();  
                updateExpressParcels(resultList);
                layer.msg($translate.instant('global.messages.submitted'));
            }, function (){
                layer.closeAll();  
                layer.msg($translate.instant('global.messages.submittedfailed'));
            });
        }

        $scope.shipNoice = function (expressParcel){
            layer.msg("Sending fulfillment status to eCommerce platform...", {
              shade: 0.3,
              time: 2000000
            });
            var expressParcels = [];
            if(expressParcel){
                expressParcels.push(expressParcel);
            }
            else {
                expressParcels = getExpressParcels();
            }
            ExpressParcel.shipNoice(expressParcels, function(resultList){
                layer.closeAll();
                updateExpressParcels(resultList);
                layer.msg($translate.instant('global.messages.submitted'));
            }, function (){
                layer.closeAll();  
                layer.msg($translate.instant('global.messages.submittedfailed'));
            });
        }

        $scope.sendInvoice = function (expressParcel){
            layer.msg("Sending Invoice to eCommerce platform...", {
              shade: 0.3,
              time: 2000000
            });
            var expressParcels = [];
            if(expressParcel){
                expressParcels.push(expressParcel);
            }
            else {
                expressParcels = getExpressParcels();
            }
            ExpressParcel.sendInvoice(expressParcels, function(resultList){
                layer.closeAll();  
                updateExpressParcels(resultList);
                layer.msg($translate.instant('global.messages.submitted'));
            }, function (){
                layer.closeAll();  
                layer.msg($translate.instant('global.messages.submittedfailed'));
            });
        }

        function updateExpressParcels (resultList) {
            angular.forEach(vm.respObj.orderList, function(data){
                angular.forEach(resultList, function(newOne){
                    if(data.expressParcel.id == newOne.id){
                        angular.copy(newOne, data.expressParcel);
                    }
                })
            })
        }

        $scope.initFiles = function (order) {
            order.expressParcel.files = [];
        }

        $scope.uploadLabel = function (file, expressParcel) {
            if (!file) {
                return;
            }
            if (!expressParcel.mblNum) {
                layer.msg($translate.instant('autopsApp.expressParcel.mblNullAlert'));
                return;
            };   
            var fd = new FormData();            
            var filePath = "/" + "expressParcel/" + expressParcel.id + "/";
            fd.append('filepath', filePath);
            fd.append('file', file);
            fd.append('companyId', expressParcel.companyId);
            $http.post(
                'api/express-parcels/uploadLabel', fd, {
                    transformRequest : angular.identity,
                    headers : {
                        'Content-Type' : undefined
                    },
                }).success(
                function(data) {
                    expressParcel.labelUrl = "labelUploaded";
                    layer.msg($translate.instant('global.uploadSuccess'));
                }).error(function(error) {
                    layer.msg($translate.instant('global.uploadFail'));
            });
        };

    }
})();