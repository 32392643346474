(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('PaymentRequestController', PaymentRequestController);

    PaymentRequestController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$translate', '$timeout', '$uibModal',
    'entity', 'Guide', 'OpsVender', 'GuideRates', 'DataUtils', 'DateUtils'];

    function PaymentRequestController($scope, $rootScope, $uibModalInstance, $translate, $timeout, $uibModal,
        entity, Guide, OpsVender, GuideRates, DataUtils, DateUtils) {

        $scope.guide = entity;

        $scope.isSaving = false;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.paymentRequest = {};
        $scope.choosedRates = [];
        var allChoosedRates = [];
        $scope.settlementObjs = [];
        $scope.showRatesList = false;

        $scope.accountingDate = new Date();
        $scope.deadlineDate = null;
        $scope.currencys = [];

        $timeout(function (){
            if ($rootScope.account.accountByEtd){
                $scope.accountingDate = DateUtils.getAccountDate($rootScope.account.companyId, $scope.guide.etd, $scope.guide.podRegion,  $scope.guide.shipmentType);
            }
            if(entity.paymentRequest){
                $scope.paymentRequest = entity.paymentRequest;
            }
            else {
                $scope.paymentRequest.jobNums = $scope.guide.jobNum;
                if($scope.guide.warehousingNumber){
                    $scope.paymentRequest.jobNums = $scope.guide.warehousingNumber;
                }
                $scope.paymentRequest.blNums = $scope.guide.mblNum;
                $scope.paymentRequest.sales = $scope.guide.salesName;
                $scope.paymentRequest.salesId = $scope.guide.salesId;
            }
            for(var i = $scope.guide.guideRates.length -1; i >= 0; i--){
                if($scope.guide.guideRates[i].isHidden
                    || !$scope.guide.guideRates[i].id
                    || !$scope.guide.guideRates[i].venderId
                    || !$scope.guide.guideRates[i].payAmount
                    || !$scope.guide.guideRates[i].payCurrency
                    || !$scope.guide.guideRates[i].payer
                    || $scope.guide.guideRates[i].locked
                    || $scope.guide.guideRates[i].internalControl == 'mustCom'
                    || $scope.guide.guideRates[i].dnNum){
                    $scope.guide.guideRates.splice(i, 1);
                }
                else if($rootScope.HAVEAUTH('FB_VIEW_OTH_RATE')){
                    var usrName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                    if(usrName != $scope.guide.guideRates[i].inputBy){
                        $scope.guide.guideRates.splice(i, 1);
                    }
                }
            }

            var guideRates = angular.copy($scope.guide.guideRates);
            for (var i = 0; i < guideRates.length; i++) {
                guideRates[i].jobNum = $scope.guide.jobNum;
                if(!guideRates[i].locked && guideRates[i].payCurrency && $scope.currencys.indexOf(guideRates[i].payCurrency) === -1){
                    $scope.currencys.push(guideRates[i].payCurrency);
                }
            }
            loadDefultPayer();
            addMorePaymentRates(guideRates);
            for (var i = 0; i < guideRates.length; i++) {
                if(!guideRates[i].locked && guideRates[i].isPay){
                    $scope.updatePaymentObj(guideRates[i].venderId);
                    if($rootScope.account.baseCurrency != "RMB"){
                        $scope.paymentRequest.invoiceType = "withoutInvoice";
                        $scope.paymentRequest.currency = guideRates[i].payCurrency;
                    }
                    else if(guideRates[i].payCurrency == $rootScope.account.baseCurrency){
                        $scope.paymentRequest.invoiceType = "forRmbOnly";
                    }
                    else if (guideRates[i].payCurrency == "USD"){
                        $scope.paymentRequest.invoiceType = "forUsdOnly";
                    }
                    else {
                        $scope.paymentRequest.invoiceType = "withoutInvoice";
                        $scope.paymentRequest.currency = guideRates[i].payCurrency;
                        $scope.paymentRequest.noInvoice = true;
                    }
                    break;
                }
            }
            $scope.paymentTypeChange($scope.paymentRequest.invoiceType);

        })

        $scope.datePickerForDeadLine = {};
        $scope.datePickerForDeadLine.status = {
            opened: false
        };
        $scope.datePickerForDeadLineOpen = function($event) {
            $scope.datePickerForDeadLine.status.opened = true;
        };

        $scope.datePickerForAccountingDate = {};
        $scope.datePickerForAccountingDate.status = {
            opened: false
        };
        $scope.datePickerForAccountingDateOpen = function($event) {
            $scope.datePickerForAccountingDate.status.opened = true;
        };

        var loadDefultPayer = function() {
            $scope.settlementObjs = [];
            var isNew;
            for (var i = $scope.guide.guideRates.length-1; i >=0; i--) {
                if (!$scope.guide.guideRates[i].locked && $scope.guide.guideRates[i].payer) {
                    isNew = true;
                    for (var j = 0; j < $scope.settlementObjs.length; j++) {
                        if ($scope.settlementObjs[j].choosedId == $scope.guide.guideRates[i].venderId) {
                            isNew = false;
                            break;
                        }
                    }
                    if (isNew) {
                        $scope.settlementObjs.push({
                            "Company_name": $scope.guide.guideRates[i].payer,
                            "choosedId": $scope.guide.guideRates[i].venderId
                        });
                    }
                }
            }
        };

        $scope.paymentTypeChange = function(type) {
            updatePaymentRates();
            if(venderCopy.invoiceInfos && venderCopy.invoiceInfos.length > 0){
                updateSettlementObj(venderCopy.invoiceInfos[0]);
            }
        }
        $scope.paymentIsBalanceChange = function(type) {
            updatePaymentRates();
        }

        var updatePaymentRates = function() {
            if (!$scope.paymentRequest.venderId || !$scope.paymentRequest.invoiceType) {
                $scope.choosedRates = [];
                return;
            }
            $scope.choosedRates = [];

            for (var i = 0; i < allChoosedRates.length; i++) {
                if (allChoosedRates[i].payAmount && allChoosedRates[i].venderId == $scope.paymentRequest.venderId) {
                    $scope.choosedRates.push(allChoosedRates[i]);
                }
            }

            if (!$scope.paymentRequest.isBalance) {
                for (var i = $scope.choosedRates.length - 1; i >= 0; i--) {
                    if ($scope.choosedRates[i] && !$scope.choosedRates[i].isPay) {
                        $scope.choosedRates.splice(i, 1);
                    }
                }
            }

            if ($scope.paymentRequest.invoiceType == "forRmbOnly") {
                $scope.paymentRequest.isIssued = false;
                $scope.paymentRequest.currency = $rootScope.account.baseCurrency;
                for (var i = $scope.choosedRates.length - 1; i >= 0; i--) {
                    if ($scope.choosedRates[i].payCurrency != $rootScope.account.baseCurrency) {
                        $scope.choosedRates.splice(i, 1);
                    }
                }
                $scope.paymentRequest.isUsd = false;
            } else if ($scope.paymentRequest.invoiceType == "forUsdOnly") {
                $scope.paymentRequest.isIssued = false;
                $scope.paymentRequest.currency = "USD";
                for (var i = $scope.choosedRates.length - 1; i >= 0; i--) {
                    if ($scope.choosedRates[i].payCurrency != "USD") {
                        $scope.choosedRates.splice(i, 1);
                    }
                }
                $scope.paymentRequest.isUsd = true;
            } else if ($scope.paymentRequest.invoiceType == "withoutInvoice") {
                // $scope.paymentRequest.invoiceNum = "NA";
                $scope.paymentRequest.isIssued = true;
                $scope.paymentRequest.noInvoice = true;
                for (var i = $scope.choosedRates.length - 1; i >= 0; i--) {
                    if ($scope.choosedRates[i].payCurrency != $scope.paymentRequest.currency) {
                        $scope.choosedRates.splice(i, 1);
                    }

                    if ($scope.paymentRequest.currency == $rootScope.account.baseCurrency) {
                        $scope.paymentRequest.isUsd = false;
                    } else {
                        $scope.paymentRequest.isUsd = true;
                    }
                }
            }
            getPaymentAmount($scope.choosedRates);
        }
        var revContent = {};
        var expContent = {};
        var revVatContent = {};
        var revWhtContent = {};
        var expVatContent = {};
        var expWhtContent = {};

        var getPaymentAmount = function(rates) {
            if (!rates || rates.length == 0) {
                $scope.paymentRequest.invoiceAmount = 0;
                return;
            }
            $scope.paymentRequest.invoiceAmount = 0;

            var balance = 0;
            var revAmt = 0;
            var expAmt = 0;
            var expVatTtl = 0;
            var expWhtTtl = 0;
            var revVatTtl = 0;
            var revWhtTtl = 0;
            for (var i = 0; i < rates.length; i++) {
                if (rates[i].isPay) {
                    balance = balance + rates[i].payAmount;
                    expAmt = expAmt + rates[i].payAmount;
                    if(rates[i].vatAmt){
                        expVatTtl = expVatTtl + rates[i].vatAmt;
                        expAmt = expAmt - rates[i].vatAmt;
                    }
                    if(rates[i].incomeTaxAmt){
                        expWhtTtl = expWhtTtl + rates[i].incomeTaxAmt;
                        expAmt = expAmt + rates[i].incomeTaxAmt;
                    }
                } else {
                    balance = balance - rates[i].payAmount;
                    revAmt = revAmt + rates[i].payAmount;
                    if(rates[i].vatAmt){
                        revVatTtl = revVatTtl + rates[i].vatAmt;
                        revAmt = revAmt - rates[i].vatAmt;
                    }
                    if(rates[i].incomeTaxAmt){
                        revWhtTtl = revWhtTtl + rates[i].incomeTaxAmt;
                        revAmt = revAmt + rates[i].incomeTaxAmt;
                    }
                }
            }
            $scope.paymentRequest.invoiceAmount = DataUtils.round(balance, 2);
            revContent.cn = DataUtils.round(revAmt, 2);
            expContent.dn = DataUtils.round(expAmt, 2);
            revVatContent.cn = DataUtils.round(revVatTtl, 2);
            revWhtContent.dn = DataUtils.round(revWhtTtl, 2);
            expVatContent.dn = DataUtils.round(expVatTtl, 2);
            expWhtContent.cn = DataUtils.round(expWhtTtl, 2);
        };


        $scope.dnOnlyChange = function() {
            if($scope.paymentRequest.invoiceType != "withoutInvoice"){
                $scope.paymentRequest.noInvoice = $scope.paymentRequest.dnOnly;
            }
        }
        var addMorePaymentRates = function(rates) {
            for (var i = 0; i < rates.length; i++) {
                if (!rates[i].locked) {
                    if (!rates[i].jobNum) {
                        rates[i].jobNum = $scope.guide.jobNum;
                    };
                    allChoosedRates.push(rates[i]);
                }
            }
            updatePaymentRates();
        };

        $scope.deleteOnePaymentRate = function(rate) {
            var index = allChoosedRates.indexOf(rate);
            if (index > -1) {
                allChoosedRates.splice(index, 1);
            };
            updatePaymentRates();
        }
        var onVencherSuccess = function(result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.messages.submitted'));
            if(!result.dnOnly){
                $rootScope.PREVIEW_DCN(result);
            }
            $uibModalInstance.close(result);
        }

        var onVencherError = function(result) {
            $scope.isSaving = false;
            if (result.status === 404) {
                layer.msg($translate.instant('autopsApp.guide.venderDeletedAlert'));
            }
            else if (result.status === 451) {
                layer.msg($translate.instant('global.messages.creditOverLimit'));
            } else if (result.status === 409) {
                layer.msg($translate.instant('autopsApp.guide.endClosedAlert'));
            } else if (result.status === 403) {
                layer.msg($translate.instant('autopsApp.guide.titleNotSetAlert'));
            } else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        }

        
        var getBillNumStr = function() {
            var result = [], hash = {}, billNumStr = "";
            for (var i = 0, elem; i < $scope.choosedRates.length && (elem = $scope.choosedRates[i].billNum) != null; i++) {
                if (!hash[elem]) {
                    result.push(elem);
                    hash[elem] = true;
                }
            }
            for (var i = 0; i < result.length; i++){
                billNumStr = billNumStr + result[i] + " ";
            }
            return billNumStr;
        }
        $scope.paymentRequestSubmit = function() {
            $scope.isSaving = true;
            for (var i = $scope.choosedRates.length-1; i >=0; i --){
                if (!$scope.choosedRates[i].id){
                    $scope.choosedRates.splice(i, 1);
                }
            }
            if ($scope.choosedRates.length == 0) {
                layer.msg($translate.instant('autopsApp.guide.notRatesForVencher'));
                $scope.isSaving = false;
                return;
            }
            if ($scope.paymentRequest.invoiceAmount < 0) {
                if(!$scope.paymentRequest.dnOnly){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.zeroAlert'));
                    $scope.isSaving = false;
                    return;
                }
                if($rootScope.HAVENOAUTH("NEGATIVE_DN_OK")){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.negativeDnForbidden'));
                    $scope.isSaving = false;
                    return;
                }
            }

            if (!$scope.paymentRequest.invoiceNum && !$scope.paymentRequest.noInvoice) {
                layer.msg($translate.instant('autopsApp.guide.invoiceNumNullAlert'));
                $scope.isSaving = false;
                return;
            }
            if ($scope.paymentRequest.noInvoice && $scope.paymentRequest.invoiceType != "withoutInvoice") {
                // $scope.paymentRequest.invoiceNum = null;
                $scope.paymentRequest.isIssued = false;
            } else {
                $scope.paymentRequest.isIssued = true;
            }
            $scope.paymentRequest.billNum = getBillNumStr();
            $scope.paymentRequest.companyId = $rootScope.account.companyId;
            $scope.paymentRequest.createdTime = new Date();
            $scope.paymentRequest.deadLine = $scope.deadlineDate;
            $scope.paymentRequest.creater = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            $scope.paymentRequest.createdBy = $rootScope.account.id;
            $scope.paymentRequest.isApproved = false;
            $scope.paymentRequest.isPaid = false;
            $scope.paymentRequest.isVoided = false;
            $scope.paymentRequest.economicDate = $scope.guide.etd;

            var accountPaymentDTO = {};
            accountPaymentDTO.guideRates = $scope.choosedRates;
            accountPaymentDTO.accountPayment = $scope.paymentRequest;

            accountPaymentDTO.revContent = revContent;
            accountPaymentDTO.expContent = expContent;
            accountPaymentDTO.revVatContent = revVatContent;
            accountPaymentDTO.revWhtContent = revWhtContent;
            accountPaymentDTO.expVatContent = expVatContent;
            accountPaymentDTO.expWhtContent = expWhtContent;

            accountPaymentDTO.accountingDate = $scope.accountingDate;
            GuideRates.newPayment({
                guideId: $scope.guide.id
            }, accountPaymentDTO, onVencherSuccess, $rootScope.ALERT_ERROR) ;
        }

        $scope.viewRates = function() {
            if (!$scope.paymentRequest.venderId || !$scope.paymentRequest.invoiceType) {
                layer.msg($translate.instant('autopsApp.guide.invoiceTypeVoidAlert'));
                return;
            }
            $scope.showRatesList = !$scope.showRatesList;
        };

        var venderCopy = {};
        $scope.updatePaymentObj = function(vId) {
            $scope.paymentRequest.venderId = vId;
            OpsVender.getInvoiceVenderDTO({id: vId}, function(result) {
                venderCopy = result;
                $scope.paymentRequest.dnOnly = (!result.paymentTerm || result.paymentTerm == "DP" || result.paymentTerm == "beforeETA")?false:true;
                if($scope.paymentRequest.invoiceType != "withoutInvoice"){
                    $scope.paymentRequest.noInvoice = $scope.paymentRequest.dnOnly;
                }
                $scope.paymentRequest.invoiceTitle = result.companyName;
                $scope.paymentRequest.remarks = null;
                if(result.invoiceInfos && result.invoiceInfos.length > 0){
                    updateSettlementObj(result.invoiceInfos[0]);
                }
            }, function(result){
                $scope.paymentRequest.venderId = null;
                layer.msg($translate.instant('autopsApp.guide.venderDeletedAlert'));
            });
            Guide.getRevDeadLine({opsVenderId: vId}, $scope.guide, function(result) {
                $scope.deadlineDate = result.revDeadLine;
            })
            $scope.choosedRates = [];
            updatePaymentRates();
        };

        var updateSettlementObj = function(invoiceInfo) {
            if(invoiceInfo.invoiceTitle){
                $scope.paymentRequest.remarks = invoiceInfo.invoiceTitle + "\n";
            }
            if($scope.paymentRequest.invoiceType == 'forRmbOnly'){
                if(invoiceInfo.bankInfo){
                    $scope.paymentRequest.remarks += invoiceInfo.bankInfo;
                }
            }
            else {
                if(invoiceInfo.bankInfoUsd){
                    $scope.paymentRequest.remarks += invoiceInfo.bankInfoUsd;
                }
            }
            if($scope.paymentRequest.remarks){
                $scope.paymentRequest.remarks += "\n";
            }
        };

        $scope.changeBankInfo = function() {
            if(!venderCopy || !venderCopy.invoiceInfos || venderCopy.invoiceInfos.length == 0){
                layer.msg($translate.instant('global.messages.resultNull'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/invoice-info/invoice-info-dialog.html',
                controller: 'InvoiceInfoDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return venderCopy.invoiceInfos;
                    }]
               }
           }).result.then(function(result) {
                updateSettlementObj(result);
           }, function() {

           });
        };



    }
})();
